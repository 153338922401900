import React from 'react';
import { ApplyFiltersButton, Box, ClearFiltersButton } from '../components';

export type ClearApplyFiltersButtonsProps = {
  onClearFilters: () => void;
};

const ClearApplyFiltersButtons: React.FC<ClearApplyFiltersButtonsProps> = ({ onClearFilters }) => {
  return (
    <Box alignItems="center" display="flex">
      <ClearFiltersButton onClick={onClearFilters} />
      <ApplyFiltersButton />
    </Box>
  );
};

export default ClearApplyFiltersButtons;
