import { Modify } from './utility';

export type Pagination = {
  pageNumber: number;
  pageSize: number;
};

export type Sort = {
  [key: string]: SortDirection;
};

export enum SortDirection {
  ASCENDING = 0,
  DESCENDING = 1,
  UNSPECIFIED = -1,
}

export type Sorting = { sortKey: string; sortDirection: SortDirection };

export type TableCellIconType = 'schedule' | 'timelapse' | 'accessAlarm' | 'error' | 'check' | 'explore' | 'close';

export type ColumnIcon = {
  iconName: TableCellIconType;
  iconColor: string;
};

export type CustomCell = {
  cellName: string;
  cellStyle: React.CSSProperties;
};

export type ColumnType = 'text' | 'icon' | 'svg' | 'checkbox' | 'customStyleCell' | undefined;

export type Column = {
  id: string;
  valueKey: string;
  label: string;
  minWidth: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  sortKey: string;
  sort?: SortDirection;
  mode?: string | string[];
  type?: ColumnType;
  colSpan?: number;
  tooltip?: boolean;
  tooltipLength?: number;
  style?: React.CSSProperties;
};

export type TypedColumn<T> = Modify<
  Column,
  {
    valueKey: keyof T;
  }
>;

export type PaginatedResult<T> = {
  items: T[];
  pageSize: number;
  pageNumber: number;
  totalSearchResultsCount: number;
};
