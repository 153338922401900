import React from 'react';
import { TableCellProps } from '@mui/material';

import { InfoOutlined } from 'icons';
import { StyledContentSpan, StyledFlexContainer, StyledTableCell, StyledTooltip } from './SimslTableCell.styles';

type SimslTableCellProps = TableCellProps & {
  dense?: boolean;
  className?: string;
  children: React.ReactNode;
  columnId?: number;
  gridName?: string;
  disabled?: boolean | null;
  tooltipInfo?: string;
};

const SimslTableCell: React.FC<SimslTableCellProps> = ({
  align,
  className,
  dense = false,
  columnId,
  gridName = 'grid',
  children,
  disabled = false,
  tooltipInfo,
  ...props
}) => {
  return (
    <StyledTableCell
      {...props}
      align={align}
      data-testid={columnId !== undefined ? `${gridName}-column-${columnId + 1}` : undefined}
      dense={dense}
    >
      {tooltipInfo ? (
        <StyledFlexContainer>
          <StyledContentSpan>{children}</StyledContentSpan>
          <StyledTooltip title={tooltipInfo}>
            <InfoOutlined fontSize="small" />
          </StyledTooltip>
        </StyledFlexContainer>
      ) : (
        <StyledContentSpan>{children}</StyledContentSpan>
      )}
    </StyledTableCell>
  );
};

export default React.memo(SimslTableCell);
