import React, { useMemo, useState } from 'react';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useMountEffect } from 'utilities/hooks';
import { useLocalStorage, useFontSize } from 'utilities/localStorage';
import Routing from './Routing';
import { createSteamshipTheme, ThemeProvider, StyledEngineProvider, CssBaseline } from './components';
import { AuthProvider } from './utilities/auth';
import { ConfigProvider, getConfig } from './utilities/config';
import { SettingsContext } from './utilities/config/settingsContext';

const reactPlugin = new ReactPlugin();

getConfig()
  .then(config => {
    if (config.ApplicationInsights && config.ApplicationInsights.ConnectionString) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: config.ApplicationInsights.ConnectionString,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
        },
      });
      appInsights.loadAppInsights();
    }
  })
  .catch(() => {});

const App = () => {
  const { cleanLocalStorage } = useLocalStorage();

  useMountEffect(() => {
    cleanLocalStorage();
  });

  const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
    reactPlugin.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { componentStack: errorInfo.componentStack },
    });
    return <h1>Sorry, something went wrong.</h1>;
  };

  return (
    <AppInsightsErrorBoundary appInsights={reactPlugin} onError={handleError}>
      <AllTheProviders>
        <Routing />
      </AllTheProviders>
    </AppInsightsErrorBoundary>
  );
};

export type AllTheProvidersProps = {
  children: React.ReactNode;
};

const AllTheProviders: React.FC<AllTheProvidersProps> = ({ children }) => {
  const { getStorageItem } = useLocalStorage();
  const [newValue, setNewValue] = useState(Number(getStorageItem('fontSize')) || 0);

  const recurrency = (value: number) => {
    setNewValue(value);
    setSettingContext({
      settings: value,
      setSettings: (val: number) => recurrency(val),
    });
  };

  const [settingsContext, setSettingContext] = useState({
    settings: newValue,
    setSettings: (value: number) => {
      setNewValue(value);
      setSettingContext({
        settings: value,
        setSettings: (val: number) => recurrency(val),
      });
    },
  });

  const value = useMemo(() => ({ settingsContext }), [settingsContext]);
  const { getRecalculatedFontSize: getFontSize } = useFontSize(newValue);
  const theme = createSteamshipTheme(getFontSize);

  return (
    <StyledEngineProvider injectFirst>
      <SettingsContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <ConfigProvider>
              <AuthProvider>{children}</AuthProvider>
            </ConfigProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </SettingsContext.Provider>
    </StyledEngineProvider>
  );
};

export default App;
