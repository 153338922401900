import { SimslRoute, SimslRouteWithUrl } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  memberGroups: 'memberGroups',
  memberGroupDetails: 'memberGroupDetails',
  newMemberGroup: 'newMemberGroup',
  editMemberGroup: 'editMemberGroup',
  uwReports: 'uwReports',
  claimsReports: 'claimsReports',
};

export default abstract class RoutePath {
  static MemberGroups: SimslRoute = {
    name: 'Member Groups',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.memberGroups,
  };

  static NewMemberGroup: SimslRoute = {
    name: 'New',
    path: `new`,
    helpSection: helpSectionIdentifiers.newMemberGroup,
  };

  static EditMemberGroup: SimslRouteWithUrl = {
    name: 'Edit',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.MemberGroupsModule}/${id}`,
    helpSection: helpSectionIdentifiers.editMemberGroup,
  };

  static MemberGroupDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.MemberGroupsModule}/${id}`,
    helpSection: helpSectionIdentifiers.memberGroupDetails,
  };

  static UWReports: SimslRoute = {
    name: 'UWReports',
    path: `uwReports`,
    helpSection: helpSectionIdentifiers.uwReports,
  };

  static ClaimsReports: SimslRoute = {
    name: 'ClaimsReports',
    path: `claimsReports`,
    helpSection: helpSectionIdentifiers.claimsReports,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: 'error',
  };
}
