import { useRef, useCallback, useEffect } from 'react';
import { CancelTokenSource } from 'axios';

const useCancelToken = () => {
  const canceler = useRef<CancelTokenSource | null>(null);

  const setCancelTokenSource = useCallback((source: CancelTokenSource) => {
    canceler.current = source;
  }, []);

  useEffect(() => {
    return () => {
      if (canceler.current) {
        canceler.current.cancel();
      }
    };
  }, []);

  return {
    setCancelTokenSource,
    canceler,
  };
};

export default useCancelToken;
