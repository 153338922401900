import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const usePromiseNavigate = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      navigate(url);
    }
  }, [url, navigate]);

  const navigateUrl = useCallback(
    (redirectUrl: string) => {
      setUrl(redirectUrl);
    },
    [setUrl],
  );

  return navigateUrl;
};

export default usePromiseNavigate;
