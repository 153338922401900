import axios, { Canceler } from 'axios';
import { AsyncStatus } from 'common';
import { useSnackbar } from 'components';
import { useCallback, useState } from 'react';
import { DEFAULT_FILTERS } from 'tasks/const';
import { TaskFilters, TaskItem, TaskSearchRequest, TaskSearchResponse, TaskSearchState } from 'tasks/model';
import TasksService from 'tasks/services/tasks-service';

const transformResponse = (data: TaskItem[]) => {
  const result = data.map(x => ({
    ...x,
    id: x.taskItemId,
  }));

  return result;
};

const useTaskSearch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchResults, setSearchResults] = useState<TaskSearchResponse>();
  const [status, setStatus] = useState<AsyncStatus>('idle');

  const fetchTasks = useCallback(
    (query: TaskSearchState): Canceler => {
      setStatus('fetching');
      const { filters, sort, ...rest } = query;
      const { assignedByUser, ...filtersToPass } = filters || DEFAULT_FILTERS;

      const requestFilters = {
        ...filtersToPass,
        assignedByUserIdFilter: (assignedByUser?.id as number) || null,
      } as TaskFilters;

      const requestData: TaskSearchRequest = {
        ...rest,
        filters: requestFilters,
        ...(sort && { [sort.sortKey]: sort.sortDirection }),
      };

      const [request, cancel] = TasksService.getTasks({ ...requestData, pageNumber: query.pageNumber + 1 });

      request
        .then(response => {
          const Tasks = transformResponse(response.items);
          setSearchResults({ ...response, items: Tasks });
          setStatus('resolved');
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            enqueueSnackbar(e.Message || 'Error occurred while retrieving Task list.', 'error');
            setStatus('rejected');
          }
        });

      return cancel;
    },
    [enqueueSnackbar],
  );

  return {
    tasks: searchResults?.items || [],
    fetchTasks,
    totalResults: searchResults?.totalSearchResultsCount || 0,
    isLoading: status === 'idle' || status === 'fetching',
  };
};

export default useTaskSearch;
