import { styled, Button } from 'components';

export const StyledTaskButton = styled(Button)(({ theme }) => ({
  marginTop: '-5px',
  minWidth: '40px',
  width: '40px',
  marginRight: theme.spacing(2),
  '& > svg': {
    transform: 'scale(1.2)',
  },
}));

export const StyledTaskCounter = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '3px',
  right: '1px',
  color: 'white',
  backgroundColor: theme.palette.error.light,
  border: '2px solid white',
  fontSize: '0.6rem',
  width: '18px',
  height: '18px',
  borderRadius: '9px',
  paddingTop: '1.5px',
  [theme.breakpoints.down('sm')]: {
    top: '0',
    right: '2px',
  },
}));
