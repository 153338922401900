import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SimslRoute } from 'common/model';
import { Breadcrumbs as BreadcrumbsContainer, Container, Link, Typography } from 'components';
import { RoutePath as ModulesRoutes } from 'RoutePath';

export type BreadcrumbsProps = {
  className?: string;
  moduleName: string;
  mainPath: string | null;
  activeRoute?: SimslRoute | null;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className, moduleName, mainPath, activeRoute }) => {
  const path = useMemo(() => {
    if (!activeRoute) {
      return null;
    }

    if (activeRoute.path === ModulesRoutes.MainPath) {
      return mainPath ?? ModulesRoutes.Home;
    }

    return `${mainPath}/${activeRoute.path}`;
  }, [activeRoute, mainPath]);

  return (
    <Container maxWidth={false}>
      <BreadcrumbsContainer className={className || ''}>
        <Link component={RouterLink} to="/" underline="hover">
          <Typography align="center" color="textSecondary" variant="caption">
            Home
          </Typography>
        </Link>
        <Typography align="center" color="textSecondary" variant="caption">
          {moduleName}
        </Typography>
        {path && activeRoute && activeRoute.name && (
          <Link component={RouterLink} to={path} underline="hover">
            <Typography align="center" color="textSecondary" variant="caption">
              {activeRoute.name}
            </Typography>
          </Link>
        )}
      </BreadcrumbsContainer>
    </Container>
  );
};

export default Breadcrumbs;
