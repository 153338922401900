import React, { useContext, useState } from 'react';

import { ExitToAppOutlinedIcon, FormatSizeOutlined, KeyboardArrowDownIcon } from 'icons';
import { Popover, Typography, List, ListItemText } from 'components';
import { useLocalStorage } from 'utilities/localStorage';
import { SettingsContext } from 'utilities/config/settingsContext';
import { useAuth } from '../auth';
import {
  StyledAvatar,
  StyledKeyboardArrowDownIcon,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
  StyledUserInfo,
  StyledUserName,
} from './UserInfoExpandable.styles';

const UserInfoExpandable: React.FC = () => {
  const { account, logout } = useAuth();
  const smallSize = 0;
  const mediumSize = 125;
  const largeSize = 145;
  const settingsContext = useContext(SettingsContext);
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const [userFontSize, setUserFontSize] = useState(Number(getStorageItem('fontSize')));

  const data = [
    { label: 'Small', value: smallSize },
    { label: 'Medium', value: mediumSize },
    { label: 'Large', value: largeSize },
  ];

  const [open, setOpen] = React.useState(false);

  const setFontSize = (value: number) => {
    setStorageItem('fontSize', JSON.stringify(value));
    settingsContext.settingsContext.setSettings(value);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'simple-popover' : undefined;

  const initials = account?.name
    ?.split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase();

  return (
    <>
      <StyledUserInfo onClick={handleClick}>
        <StyledAvatar>
          <Typography color="inherit" noWrap variant="body2">
            {initials}
          </Typography>
        </StyledAvatar>
        <StyledUserName color="inherit" noWrap variant="body2">
          {account?.name}
        </StyledUserName>
        <KeyboardArrowDownIcon />
      </StyledUserInfo>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={popoverId}
        onClose={handleClose}
        open={isOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav">
          <StyledListItem onClick={logout}>
            <StyledListItemIcon>
              <ExitToAppOutlinedIcon />
            </StyledListItemIcon>
            <ListItemText primary="Log Out" />
          </StyledListItem>
          <StyledListItem onClick={() => setOpen(!open)}>
            <StyledListItemIcon>
              <FormatSizeOutlined />
            </StyledListItemIcon>
            <ListItemText primary="Font Size" />
            <StyledKeyboardArrowDownIcon />
          </StyledListItem>
          {open &&
            data.map(item => (
              <StyledListItem
                key={item.label}
                onClick={() => {
                  setFontSize(item.value);
                  setUserFontSize(item.value);
                }}
              >
                <StyledListItemText
                  isSelected={item.value === userFontSize}
                  primary={item.label}
                  primaryTypographyProps={{ fontSize: '0.8rem', fontWeight: 'medium', textAlign: 'center' }}
                />
              </StyledListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

export default UserInfoExpandable;
