import { FormControl, MenuItem, Theme } from '@mui/material';

import { styled } from 'components';

export const StyledMenuItem = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('xl')]: {
    minHeight: '2.25rem',
  },
}));

export const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  maxWidth: '10rem',
}));
