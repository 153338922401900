import React from 'react';
import { useTheme } from '@mui/material/styles';
import { StyledMasonry } from './styles';

type MasonryBreakpoint = { default: number; [key: number]: number };

type MasonryGridProps = {
  children: React.ReactNode;
  breakpoints?: MasonryBreakpoint;
  className?: string;
};

const MasonryGrid: React.FC<MasonryGridProps> = ({ children, breakpoints, className = '' }) => {
  const theme = useTheme();

  const breakpointCols: MasonryBreakpoint = {
    default: 1,
    [theme.breakpoints.values.xl]: 1,
    [theme.breakpoints.values.lg]: 1,
    [theme.breakpoints.values.md]: 1,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
    ...breakpoints,
  };

  return (
    <StyledMasonry breakpointCols={breakpointCols} className={className}>
      {children}
    </StyledMasonry>
  );
};

export default MasonryGrid;
