import { useCallback } from 'react';

import { useSnackbar as useNotistack } from 'notistack';
import { StyledAlert } from './snackbar.styles';

export type VariantType = 'success' | 'info' | 'warning' | 'error' | undefined;

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  const enqueue = useCallback(
    (message: string, variant: VariantType, persist?: Boolean) => {
      enqueueSnackbar('', {
        persist: !!persist,
        content: key => (
          <StyledAlert onClick={() => closeSnackbar(key)} severity={variant} variant="outlined">
            {message || 'Sorry, an unexpected error has ocurred.'}
          </StyledAlert>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return {
    enqueueSnackbar: enqueue,
  };
};

export default useSnackbar;
