import React from 'react';
import { FormHelperText, InputProps } from '@mui/material';
import isNil from 'lodash.isnil';

import { useOnMouseDown } from 'utilities/hooks';
import { StyledDescriptionTypography, StyledInput, StyledRootBox } from './SimslInput.styles';

export type SimslInputProps = {
  label?: string;
  maxLength?: number;
  customInputWidth?: string;
  customDescriptionWidth?: number;
  errorMessage?: string;
  labelClassName?: string;
  id?: string;
  dataTestId?: string;
} & InputProps;

const SimslInput: React.FC<SimslInputProps> = ({
  className,
  labelClassName,
  customDescriptionWidth = 120,
  customInputWidth = '100%',
  label,
  maxLength,
  inputProps,
  errorMessage,
  dataTestId,
  id,
  ...rest
}) => {
  const onMouseDown = useOnMouseDown();
  return (
    <>
      <StyledRootBox>
        {!isNil(label) && (
          <StyledDescriptionTypography
            align="left"
            className={labelClassName}
            color="textSecondary"
            customDescriptionWidth={customDescriptionWidth}
            display="block"
            id={id}
            variant="caption"
          >
            {label}
          </StyledDescriptionTypography>
        )}

        <StyledInput
          {...rest}
          className={className}
          customInputWidth={customInputWidth}
          inputProps={{
            ...inputProps,
            maxLength: `${maxLength}`,
            'aria-labelledby': label ? id : undefined,
            'data-testid': dataTestId ? `${dataTestId}-input` : undefined,
          }}
          onMouseDown={rest.onMouseDown ?? onMouseDown}
        />
      </StyledRootBox>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

export default SimslInput;
