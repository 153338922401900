import { Box, DialogActions } from '@mui/material';

import { ActionButton } from 'components/Button';
import GenericDialog, { GenericDialogProps } from './GenericDialog';

type InfoDialogProps = GenericDialogProps & {
  message: string;
  okButtonTitle?: string;
  onClose: () => void;
};

const InfoDialog: React.FC<InfoDialogProps> = ({ open, title, message, okButtonTitle = 'Close', onClose, ...props }) => (
  <GenericDialog
    {...props}
    actions={
      <DialogActions>
        <ActionButton actionType="cancel" onClick={onClose}>
          {okButtonTitle}
        </ActionButton>
      </DialogActions>
    }
    onClose={onClose}
    open={open}
    title={title}
  >
    <Box whiteSpace="break-spaces">{message}</Box>
  </GenericDialog>
);

export default InfoDialog;
