import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button, { ButtonProps } from './Button';

export type ButtonWithTooltipProps = ButtonProps & {
  tooltipMessage: NonNullable<React.ReactNode>;
  dataTestId?: string;
};

const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({ tooltipMessage, dataTestId, children, ...props }) => (
  <Tooltip data-testid={dataTestId ? `${dataTestId}-tooltip` : undefined} title={tooltipMessage}>
    <span>
      <Button {...props} color="primary" dataTestId={dataTestId}>
        {children}
      </Button>
    </span>
  </Tooltip>
);

export default ButtonWithTooltip;
