import React from 'react';
import { Box, Tooltip, Typography } from 'components';
import { InfoOutlined } from 'icons';

type PropertyEmptyValueProps = {
  dataTestId?: string;
  tooltipInfo?: string;
  children?: React.ReactNode;
};

const PropertyEmptyValue: React.FC<PropertyEmptyValueProps> = ({ children, dataTestId, tooltipInfo }) => (
  <Box alignItems="center" display="flex">
    <Typography data-testid={dataTestId}>{children}</Typography>
    {tooltipInfo && (
      <Box display="flex" marginLeft={1}>
        <Tooltip title={tooltipInfo}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Box>
    )}
  </Box>
);

export default PropertyEmptyValue;
