/* eslint-disable scanjs-rules/call_setInterval */
import React, { useEffect, useState } from 'react';
import { NotificationsIcon } from 'icons';
import { TaskItem } from 'tasks/model';
import { DEFAULT_FILTERS } from 'tasks/const';
import { useTasks } from 'tasks/hooks';
import { TaskDrawer } from '../TaskDrawer';
import { TaskModal } from '../TaskModal';
import { TaskAlert } from '../TaskAlert';
import { StyledTaskButton, StyledTaskCounter } from './TaskIcon.styles';

type TaskIconProps = {};

const TaskIcon: React.FC<TaskIconProps> = () => {
  const { fetchTasks, tasks } = useTasks({ ...DEFAULT_FILTERS, statusIdFilter: 1 });
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [alertBarOpen, setAlertBarOpen] = useState<boolean>(false);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [currentTasks, setCurrentTasks] = useState<TaskItem[]>([]);
  const [newTasks, setNewTasks] = useState<TaskItem[]>();
  const [selectedTask, setSelectedTask] = useState<TaskItem>();
  const [intervalStarted, setIntervalStarted] = useState<boolean>(false);

  const toggleSideBarOpen = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const handleAlertBarOpen = () => {
    setAlertBarOpen(true);
  };

  const handleSideBarClose = () => {
    setSideBarOpen(false);
  };

  const handleAlertBarClose = () => {
    setAlertBarOpen(false);
  };

  const handleOpenTaskModal = (task: TaskItem) => {
    setOpenTaskModal(true);
    setSelectedTask(task);
  };

  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
  };

  useEffect(() => {
    fetchTasks();

    const interval = setInterval(() => {
      fetchTasks();
      setIntervalStarted(true);
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchTasks]);

  useEffect(() => {
    if (!(JSON.stringify(tasks) === JSON.stringify(currentTasks))) {
      setNewTasks(
        newTasks || intervalStarted
          ? tasks.filter(task => !currentTasks.some(currentTask => task.taskItemId === currentTask.taskItemId))
          : [],
      );
      setCurrentTasks(tasks);
    }
  }, [tasks, currentTasks, newTasks]);

  useEffect(() => {
    if (newTasks && newTasks.length > 0) {
      handleAlertBarOpen();
    }
  }, [newTasks]);

  return (
    <>
      <StyledTaskButton onClick={toggleSideBarOpen}>
        <NotificationsIcon />
        {currentTasks.length > 0 && <StyledTaskCounter>{currentTasks.length}</StyledTaskCounter>}
      </StyledTaskButton>
      <TaskDrawer
        handleClose={handleSideBarClose}
        handleOpenTaskModal={handleOpenTaskModal}
        openTasks={currentTasks}
        sideBarOpen={sideBarOpen}
      />
      <TaskAlert handleClose={handleAlertBarClose} handleOpenTaskModal={handleOpenTaskModal} sideBarOpen={alertBarOpen} tasks={newTasks} />
      {selectedTask && <TaskModal handleCloseTaskModal={handleCloseTaskModal} openTaskModal={openTaskModal} task={selectedTask} />}
    </>
  );
};

export default TaskIcon;
