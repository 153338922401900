import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from 'components';
import { TaskItem } from 'tasks/model';
import { StyledBox, StyledApplicationReference, StyledTaskTitle } from './TaskCardSlim.styles';

type TaskCardSlimProps = {
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  task: TaskItem;
};

const TaskCardSlim: React.FC<TaskCardSlimProps> = ({ handleClose, handleOpenTaskModal, task }) => {
  return (
    <StyledBox>
      <StyledTaskTitle color="primary" onClick={() => handleOpenTaskModal(task)} variant="h6">
        {task.title}
      </StyledTaskTitle>
      <StyledApplicationReference>{task.application.title}</StyledApplicationReference>
      <Typography variant="subtitle2">
        <Link component={RouterLink} onClick={handleClose} to={task.viewUrl}>
          Link
        </Link>
      </Typography>
    </StyledBox>
  );
};

export default TaskCardSlim;
