import axios, { Canceler } from 'axios';
import queryString from 'utilities/misc/QueryStringHelper';
import { TaskSearchRequest, TaskSearchResponse } from '../model';

const TasksService = {
  getTasks: (query: TaskSearchRequest): [Promise<TaskSearchResponse>, Canceler] => {
    const { filters, ...defaultFilters } = query;
    const defaultFiltersQueryString = queryString({ ...defaultFilters });
    const filtersQueryString = filters ? queryString(filters) : null;

    const url = filtersQueryString
      ? `/v2/tasks?${defaultFiltersQueryString}&${filtersQueryString}`
      : `/v2/tasks?${defaultFiltersQueryString}`;

    const cancel = axios.CancelToken.source();

    const getRequest = axios
      .get<TaskSearchResponse>(url, {
        cancelToken: cancel.token,
        headers: {
          skipRedirection: true,
        },
      })
      .then(response => response?.data);
    return [getRequest, cancel.cancel];
  },
};

export default TasksService;
