import { useContext, useMemo } from 'react';

import { LoggedInUser } from 'common';
import { UserContext } from 'utilities/user';

const useUser = () => {
  const { userDetails, isDataFetched } = useContext(UserContext);

  if (!isDataFetched) {
    throw new Error('useUser must be used within UserContext.Provider.');
  }

  const user = useMemo<LoggedInUser | null>(() => userDetails, [userDetails]);
  return user;
};

export default useUser;
