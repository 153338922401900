import React from 'react';
import { Switch, SwitchProps } from '@mui/material';

export type SimslSwitchProps = SwitchProps;

const SimslSwitch: React.FC<SimslSwitchProps> = props => {
  return <Switch {...props} color="primary" size="small" />;
};

export default SimslSwitch;
