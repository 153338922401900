import { Column, Pagination } from 'common/model';
import { TaskFilters, TaskSearchRequest } from 'tasks/model';

export const DEFAULT_COLUMNS: Column[] = [
  {
    id: 'taskItemId',
    valueKey: 'title',
    label: 'Title',
    minWidth: 127,
    align: 'left',
    sortKey: 'titleOrdering',
  },
  {
    id: 'application.applicationId',
    valueKey: 'application.title',
    label: 'Application',
    minWidth: 127,
    align: 'left',
    sortKey: 'appIdOrdering',
  },
  {
    id: 'taskItemStatus.statusId',
    valueKey: 'taskItemStatus.statusTitle',
    label: 'Status',
    minWidth: 127,
    align: 'left',
    sortKey: 'statusIdOrdering',
  },
  {
    id: 'taskType.taskTypeId',
    valueKey: 'taskType.type',
    label: 'Type',
    minWidth: 127,
    align: 'left',
    sortKey: 'taskTypeIdOrdering',
  },
  {
    id: 'assignedByUser.id',
    valueKey: 'assignedByUser.name',
    label: 'Assigned By',
    minWidth: 127,
    align: 'left',
    sortKey: 'assignedByUserIdOrdering',
  },
  {
    id: 'taskCreatedUI',
    valueKey: 'taskCreatedUI',
    label: 'Created',
    minWidth: 127,
    align: 'left',
    sortKey: 'taskCreatedOrdering',
  },
  {
    id: 'dueDateUI',
    valueKey: 'dueDateUI',
    label: 'Due',
    minWidth: 127,
    align: 'left',
    sortKey: 'DueDateOrdering',
  },
];

export const DEFAULT_PAGINATION: Pagination = {
  pageNumber: 0,
  pageSize: 30,
};

export const DEFAULT_QUERY: TaskSearchRequest = {
  pageSize: 30,
  pageNumber: 1,
  filters: null,
};

export const DEFAULT_FILTERS: TaskFilters = {
  titleFilter: '',
  taskTypeIdFilter: null,
  appIdFilter: null,
  statusIdFilter: null,
  assignedByUserIdFilter: null,
  assignedToUserIdFilter: null,
  assignedByUser: null,
  taskCreatedFromFilter: '',
  taskCreatedToFilter: '',
  dueDateFromFilter: '',
  dueDateToFilter: '',
};

export const taskTypes = {
  approval: 1,
  review: 2,
  declinature: 3,
  assignment: 4,
};

export const GRID_NAME = 'taskGrid';
