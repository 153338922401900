import React from 'react';
import { CustomContentProps, useSnackbar } from 'notistack';
import { NotificationDialog } from 'components/Dialog';

interface DialogSnackBarProps extends CustomContentProps {
  title: string;
}

const DialogSnackBar = React.forwardRef<HTMLDivElement, DialogSnackBarProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <div ref={ref}>
      <span>
        <NotificationDialog
          message={props.message}
          onClose={() => {
            closeSnackbar();
          }}
          open
          title={props.title}
        />
      </span>
    </div>
  );
});

export default DialogSnackBar;
