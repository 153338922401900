import React from 'react';
import { StyledGrid } from './styles';

type SimslFiltersContainerProps = {
  children: React.ReactNode;
};

const SimslFiltersContainer: React.FC<SimslFiltersContainerProps> = ({ children }) => {
  return <StyledGrid container>{children}</StyledGrid>;
};

export default SimslFiltersContainer;
