import React from 'react';
import { Typography } from '..';
import { ClearIcon } from '../../icons';
import Button from './Button';

type ClearFiltersButtonProps = {
  onClick: () => void;
};

const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({ onClick }) => {
  return (
    <Button color="primary" dataTestId="clear-filters" onClick={onClick} size="small" startIcon={<ClearIcon />} type="reset" variant="text">
      <Typography align="left" color="primary" display="block" variant="caption">
        CLEAR FILTERS
      </Typography>
    </Button>
  );
};

export default ClearFiltersButton;
