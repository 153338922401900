import React from 'react';
import Box from '@mui/material/Box';

import { CustomCell } from 'common/model';

type CustomTableCellProps = {
  customCell: CustomCell;
};

const CustomTableCell: React.FC<CustomTableCellProps> = ({ customCell }) =>
  customCell ? <Box style={customCell.cellStyle}>{customCell.cellName}</Box> : null;

export default CustomTableCell;
