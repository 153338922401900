import { Typography } from '@mui/material';
import { styled, Grid } from 'components';

export const StyledRootGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: Number(theme.typography.fontWeightBold),
  letterSpacing: '0.08rem',
  textTransform: 'uppercase',
}));
