import { SimslRoute, SimslRouteWithUrl } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  clauses: 'clauses',
  clauseDetails: 'clauseDetails',
};

export default abstract class RoutePath {
  static Clauses: SimslRoute = {
    name: 'Clauses',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.clauses,
  };

  static ClauseDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.CertificateClausesModule}/${id}`,
    helpSection: helpSectionIdentifiers.clauseDetails,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
