import React from 'react';

import { ClearIcon, DeleteOutlinedIcon, DownloadIcon, EditIcon, SaveOutlined } from 'icons';
import { Button } from 'components';
import { ButtonProps } from './Button';
import ButtonWithTooltip from './ButtonWithTooltip';

type ActionButtonProps = ButtonProps & {
  actionType: 'add' | 'edit' | 'cancel' | 'save' | 'delete' | 'download' | 'close' | 'reports' | 'runReports';
  tooltipMessage?: string;
  customDeleteTitle?: string;
  dataTestId?: string;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  actionType,
  tooltipMessage = '',
  customDeleteTitle = 'Delete',
  children,
  ...props
}) => {
  switch (actionType) {
    case 'add':
      return (
        <ButtonWithTooltip
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <EditIcon />}
          tooltipMessage={tooltipMessage}
          type="button"
          variant="outlined"
        >
          Add
        </ButtonWithTooltip>
      );
    case 'edit':
      return (
        <ButtonWithTooltip
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <EditIcon />}
          tooltipMessage={tooltipMessage}
          type="button"
          variant="outlined"
        >
          Edit
        </ButtonWithTooltip>
      );
    case 'delete':
      return (
        <ButtonWithTooltip
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <DeleteOutlinedIcon />}
          tooltipMessage={tooltipMessage}
          type="button"
          variant="outlined"
        >
          {customDeleteTitle}
        </ButtonWithTooltip>
      );
    case 'cancel':
      return (
        <Button
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <ClearIcon />}
          type="button"
          variant="outlined"
        >
          {children || 'Cancel'}
        </Button>
      );
    case 'save':
      return (
        <Button
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <SaveOutlined />}
          variant="contained"
        >
          {children || 'Save'}
        </Button>
      );
    case 'download':
      return (
        <Button
          {...props}
          color="primary"
          startIcon={props.startIcon === null ? null : props.startIcon || <DownloadIcon />}
          variant="contained"
        >
          Download
        </Button>
      );
    case 'close':
      return (
        <Button {...props} color="primary" variant="outlined">
          CLOSE
        </Button>
      );
    case 'reports':
      return (
        <Button {...props} color="primary" variant="outlined">
          Reports
        </Button>
      );
    case 'runReports':
      return (
        <ButtonWithTooltip {...props} color="primary" tooltipMessage={tooltipMessage} type="button" variant="contained">
          Run Reports
        </ButtonWithTooltip>
      );
    default:
      return null;
  }
};

export default ActionButton;
