import { useCallback } from 'react';

import { CONFIRM_LEAVE_WITHOUT_SAVING_MESSAGE } from 'common/const';
import useConfirm from './useConfirm.hook';

const useConfirmLeaveWithoutSave = () => {
  const { showConfirmation } = useConfirm();

  const handleShowConfirmation = useCallback(() => showConfirmation(CONFIRM_LEAVE_WITHOUT_SAVING_MESSAGE), [showConfirmation]);

  return {
    showConfirmation: handleShowConfirmation,
  };
};

export default useConfirmLeaveWithoutSave;
