import { Theme } from '@mui/material';

// https://stackoverflow.com/q/1855884
export const hexToLuminance = (color: string) => {
  const hex = color.replace(/#/, '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return [0.299 * r, 0.587 * g, 0.114 * b].reduce((aa, bb) => aa + bb) / 255;
};

export const isBestTextColorLight = (backgroundColor: string) => {
  return hexToLuminance(backgroundColor) < 0.5;
};

export const findBestTextColor = (backgroundColor: string, theme: Theme) => {
  return hexToLuminance(backgroundColor || theme.palette.background.paper) < 0.5
    ? theme.palette.background.paper
    : theme.palette.text.primary;
};
