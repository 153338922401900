import React from 'react';

import { Typography } from 'components';

type TooltipTitleProps = {
  tooltipText: string;
};

const TooltipTitle: React.FC<TooltipTitleProps> = ({ tooltipText }) => {
  return (
    <div>
      <Typography component="p" variant="caption">
        {tooltipText}
      </Typography>
    </div>
  );
};

export default TooltipTitle;
