import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Autocomplete, TextField, AutocompleteProps } from '@mui/material';
import isNil from 'lodash.isnil';
import { britishFormatToBigNumber } from 'utilities/misc/NumberHelper';
import { textWithoutCommas } from 'utilities/misc/StringHelper';
import { getCommaSeparatedValue } from 'components/helpers/ComponentHelper';

export type SimslNumberAutocompleteOption = {
  value: string;
  label: string;
};

type SimslNumberAutocompleteProps = Omit<AutocompleteProps<string, false, false, true>, 'options' | 'renderInput' | 'ref'> & {
  predefinedOptions: SimslNumberAutocompleteOption[];
  label: string;
  onChange: (value: number | string | null) => void;
  isDecimal?: boolean;
  max?: number;
  min?: number;
  error?: boolean;
  helperText?: React.ReactNode;
  dataTestId?: string;
  commaSeparator?: boolean;
  value?: string | null;
  placeholder?: string | undefined;
};

const SimslNumberAutocomplete: React.FC<SimslNumberAutocompleteProps> = React.forwardRef(
  (
    {
      label,
      predefinedOptions,
      dataTestId,
      isDecimal = false,
      min = 0,
      max = 99999999,
      error,
      helperText,
      onChange,
      commaSeparator = false,
      value,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>(Number.isNaN(Number(value)) ? value || '' : getCommaSeparatedValue(value) || '');
    const [cursorPositionChanged, setCursorPositionChanged] = useState<boolean>(false);
    const [deletePressed, setDeletePressed] = useState<boolean>(false);
    const options = predefinedOptions.map(option => option.value);
    const getFormattedValue = useCallback(
      (pureValue: string | number | undefined | null) => {
        return commaSeparator ? getCommaSeparatedValue(pureValue) : pureValue;
      },
      [commaSeparator],
    );

    const inputRefCommaSeparated = useRef<HTMLInputElement>(null);
    const cursorPos = useRef<number | null>(inputValue?.toString().length ?? null);

    useEffect(() => {
      if (!commaSeparator) return;
      if (inputRefCommaSeparated?.current === null) return;
      if ((ref as React.RefObject<HTMLInputElement>)?.current) return;

      inputRefCommaSeparated.current.setSelectionRange(cursorPos.current, cursorPos.current);
      setCursorPositionChanged(false);
    }, [inputValue, commaSeparator, ref, cursorPositionChanged]);

    const handleOptionChange = useCallback(
      (_: React.SyntheticEvent<Element, Event>, newValue: string | number | null) => {
        const option = predefinedOptions.find(p => p.value === newValue);
        setInputValue(option?.label || '');
        onChange(option?.value || null);
      },
      [onChange, predefinedOptions],
    );

    const handleOnKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (commaSeparator) setDeletePressed(false);

        if (commaSeparator && event.key === 'Delete') {
          setDeletePressed(true);
        }

        if (
          event.key === 'Backspace' ||
          event.key === 'Tab' ||
          event.key === 'Enter' ||
          event.key === 'Delete' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowRight' ||
          event.key === 'ArrowDown' ||
          event.key === 'End' ||
          event.key === 'Home'
        ) {
          return;
        }

        if (event.key >= '0' && event.key <= '9') {
          return;
        }

        if (event.key === '.' && isDecimal) {
          return;
        }

        event.preventDefault();
      },
      [commaSeparator, isDecimal],
    );

    const handleInputChange = useCallback(
      (_: React.SyntheticEvent<Element, Event>, newValue: string) => {
        if (newValue === '-' || newValue === '') {
          setInputValue(newValue);
          onChange(null);
          return;
        }

        const option = predefinedOptions.find(p => p.label === newValue);
        const optionValue = option?.value || commaSeparator ? textWithoutCommas(newValue) : newValue;
        const numberValue = Number(optionValue);
        const optionByValue = predefinedOptions.find(p => p.value === newValue);

        if (Number.isNaN(numberValue) && !optionByValue) {
          setInputValue('');
          onChange(null);
        } else {
          if (numberValue < min || numberValue > max) {
            return;
          }

          if (optionByValue) {
            setInputValue(optionByValue.label);
            onChange(optionByValue.value);

            if (commaSeparator) {
              cursorPos.current = optionByValue.label.length;
            }

            return;
          }

          const changedValue = commaSeparator
            ? getFormattedValue(britishFormatToBigNumber(optionValue).toString()) || ''
            : optionValue.toString() || '';

          setInputValue(changedValue?.toString());
          onChange(changedValue);

          if (commaSeparator) {
            let lengthDifference =
              (getFormattedValue(newValue)?.toString().length ?? 0) - (getFormattedValue(value)?.toString().length ?? 0);

            if (lengthDifference > 0) {
              lengthDifference -= 1;
            } else if (lengthDifference < 0 || deletePressed) {
              lengthDifference += 1;
            }

            cursorPos.current =
              Math.max(lengthDifference < 0 ? 1 : 0, Number(inputRefCommaSeparated.current?.selectionStart ?? 0) + lengthDifference) ??
              null;

            setCursorPositionChanged(true);
          }
        }
      },
      [commaSeparator, deletePressed, getFormattedValue, max, min, onChange, predefinedOptions, value],
    );

    return (
      <Stack spacing={2}>
        <Autocomplete
          {...props}
          filterOptions={o => o}
          freeSolo
          getOptionLabel={option => {
            const predefinedOption = predefinedOptions.find(p => p.value === option);
            if (!isNil(predefinedOption)) {
              return predefinedOption.label;
            }

            return option?.toString() || '';
          }}
          inputValue={inputValue}
          isOptionEqualToValue={(option, v) => option === v}
          onChange={handleOptionChange}
          onInputChange={handleInputChange}
          options={options}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                sx: { fontSize: '0.75rem' },
              }}
              //  eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ ...params.inputProps, 'data-testid': dataTestId ? `${dataTestId}-autocomplete` : undefined }}
              inputRef={commaSeparator ? inputRefCommaSeparated : ref}
              onKeyDown={handleOnKeyDown}
              placeholder={placeholder}
              variant="standard"
            />
          )}
        />
      </Stack>
    );
  },
);

export default SimslNumberAutocomplete;
