import React from 'react';
import { Helmet } from 'react-helmet';
import { useUserMenuOptionPermissions } from 'utilities/hooks';
import { menuOptions } from 'common/const';

import WorkbenchModuleCard from './WorkbenchModuleCard';
import { RoutePath } from '../RoutePath';
import BusinessesRoutes from '../businesses/Routes';
import BusinessContactsRoutes from '../businessContacts/Routes';
import VesselSurveyRoutes from '../surveys/Routes';
import ObjectsRoutes from '../objectMaintenance/routes';
import { StyledRootBox } from './HomePage.styles';

const HomePage: React.FC = () => {
  const submissionAccess = useUserMenuOptionPermissions(menuOptions.SubmissionReadOnly);
  const brokerAccess = useUserMenuOptionPermissions(menuOptions.BrokerReadOnly);
  const statementAccess = useUserMenuOptionPermissions(menuOptions.StatementReadOnly);
  const memberGroupAccess = useUserMenuOptionPermissions(menuOptions.MemberGroupReadOnly);
  const objectMaintenanceAccess = useUserMenuOptionPermissions(menuOptions.ObjectMaintenanceReadOnly);
  const vesselSurveyAccess = useUserMenuOptionPermissions(menuOptions.VesselSurveyReadOnly);

  return (
    <StyledRootBox>
      <Helmet>
        <title>Workbench</title>
      </Helmet>
      <WorkbenchModuleCard
        description="Businesses from Gold-Vision CRM system"
        imagePath="/images/modules/businesses.jpg"
        link={`${RoutePath.BusinessesModule}/${BusinessesRoutes.ActiveBusinesses.path}`}
        name="Businesses"
      />
      <WorkbenchModuleCard
        description="Business Contacts from Gold-Vision CRM system"
        imagePath="/images/modules/business_contacts.jpg"
        link={`${RoutePath.BusinessContactsModule}/${BusinessContactsRoutes.ActiveBusinessContacts.path}`}
        name="Business Contacts"
      />
      {vesselSurveyAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Vessel Condition Surveys and Management Reviews"
          imagePath="/images/modules/vessel_survey.jpg"
          link={`${RoutePath.SurveysModule}/${VesselSurveyRoutes.OpenSurveys.path}`}
          name="Vessel Surveys"
        />
      )}
      {objectMaintenanceAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Vessel, Crew and Fleet Maintenance"
          imagePath="/images/modules/vessels.jpg"
          link={`${RoutePath.ObjectsModule}/${ObjectsRoutes.Vessels.path}`}
          name="Object Maintenance"
        />
      )}
      {memberGroupAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Member Group Maintenance"
          imagePath="/images/modules/member_groups.jpg"
          link={RoutePath.MemberGroupsModule}
          name="Member Groups"
        />
      )}
      {/* <WorkbenchModuleCard
        description="Certificate Clause Maintenance"
        imagePath="/images/modules/certificate_clauses.jpg"
        link={RoutePath.CertificateClausesModule}
        name="Certificate Clauses"
      /> */}
      {submissionAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Submission Maintenance"
          imagePath="/images/modules/submissions.jpg"
          link={RoutePath.SubmissionsModule}
          name="Submissions"
        />
      )}
      {brokerAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Broker Maintenance"
          imagePath="/images/modules/broker_maintenance.jpg"
          link={RoutePath.BrokersModule}
          name="Brokers"
        />
      )}
      {statementAccess.isAllowed && (
        <WorkbenchModuleCard
          description="Statements"
          imagePath="/images/modules/statements.jpg"
          link={RoutePath.StatementsModule}
          name="Statements"
        />
      )}
      <WorkbenchModuleCard description="Task Management" imagePath="/images/modules/tasks.jpg" link={RoutePath.TasksModule} name="Tasks" />
    </StyledRootBox>
  );
};

export default HomePage;
