import { Canceler } from 'axios';
import { SxProps, Theme } from '@mui/material';
import { UserThin } from 'common/model';
import { Box, Button } from 'components';
import { AutocompleteAsync, AutocompleteOption } from 'generics/Autocomplete';
import React, { useCallback, useState } from 'react';
import { useAuth } from '../../../utilities/auth';
import { StyledRootBox } from './styles';

export type UserAutocompleteAsyncProps = {
  className?: string;
  error?: boolean;
  getUsers: () => [Promise<UserThin[]>, Canceler];
  helperText?: React.ReactNode;
  onChange: (value: UserThin | null) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  showAssignMeButton?: boolean;
  textFieldClass?: string;
  rootCustomClass?: string;
  value?: UserThin | null;
  sx?: SxProps<Theme>;
};

const UserAutocompleteAsync: React.FC<UserAutocompleteAsyncProps> = ({
  error,
  getUsers,
  helperText,
  onChange,
  onMouseDown,
  showAssignMeButton,
  textFieldClass,
  rootCustomClass,
  className,
  value,
  sx,
}) => {
  const { account } = useAuth();
  const [meUser, setMeUser] = useState<UserThin | null>(null);

  const handleDataFetched = useCallback(
    (users: UserThin[]) => {
      const user = users.find(u => u.email?.localeCompare(account?.username || '', 'en', { sensitivity: 'base' }) === 0);
      if (user) {
        setMeUser(user);
      }
    },
    [account?.username],
  );

  const getOptionSelected = useCallback((selection: UserThin, candidate: UserThin) => {
    return selection.id === candidate.id || selection.name === candidate.name;
  }, []);

  return (
    <StyledRootBox className={rootCustomClass} sx={sx}>
      <AutocompleteAsync
        apiRequest={getUsers}
        className={className}
        error={error}
        errorMessage="Error occurred during users fetch"
        fetchOnInit
        getOptionLabel={(option: UserThin) => option.name}
        helperText={helperText}
        isOptionEqualToValue={getOptionSelected}
        onChange={onChange}
        onDataFetched={handleDataFetched}
        onMouseDown={onMouseDown}
        renderOption={(renderOptionParams, option) => <AutocompleteOption {...renderOptionParams} key={option.id} value={option.name} />}
        textFieldClass={textFieldClass}
        value={value}
      />
      <Box>
        {showAssignMeButton && meUser && (
          <Button
            color="primary"
            onClick={() => {
              onChange(meUser);
            }}
            size="small"
            type="button"
          >
            Assign me
          </Button>
        )}
      </Box>
    </StyledRootBox>
  );
};

export default UserAutocompleteAsync;
