import { Canceler } from 'axios';
import { useSnackbar } from 'components';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import SimslAutocomplete, { SimslAutocompleteProps } from '../Autocomplete';

const MINIMUM_CHARS_TO_SHOW_OPTIONS = 3;

export type SimslAutocompleteFilterAsyncProps<T> = Omit<SimslAutocompleteProps<T>, 'isLoading' | 'data'> & {
  minFilterLength?: number;
  apiRequest: (filter: string) => [Promise<T[]>, Canceler];
  errorMessage?: string;
  inputRef?: React.Ref<HTMLTextAreaElement>;
};

function AutocompleteFilterAsync<T>(props: SimslAutocompleteFilterAsyncProps<T>) {
  const {
    apiRequest,
    errorMessage,
    value,
    minFilterLength = MINIMUM_CHARS_TO_SHOW_OPTIONS,
    onChange = () => {},
    inputRef,
    highlightedOptions = [],
    ...rest
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<T | null | undefined>(value);
  const [suggestedOptions, setSuggestedOptions] = useState<T[] | null>(null);

  const isLoading = isDropdownOpen && !suggestedOptions;

  useEffect(() => {
    let unmounted = false;

    if (!isLoading) {
      return undefined;
    }

    (async () => {
      const [response] = apiRequest(filter);
      response
        .then((data: T[]) => {
          if (!unmounted) {
            setSuggestedOptions(data);
          }
        })
        .catch(e => {
          setSuggestedOptions([]);
          const defaultMessage = errorMessage || 'Error occurred during autocomplete data fetch';
          enqueueSnackbar(e.Message || defaultMessage, 'error');
        });
    })();

    return () => {
      unmounted = true;
    };
  }, [isLoading, filter, apiRequest, enqueueSnackbar, errorMessage, suggestedOptions]);

  const handleDropdownClose = () => {
    setDropdownOpen(false);
    setSuggestedOptions(null);
  };

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const providedValue = event.target.value;
      const open = providedValue.length >= minFilterLength;
      setDropdownOpen(open);

      if (providedValue.length < filter.length) {
        setSuggestedOptions(null);
      }

      setFilter(providedValue);
    },
    [minFilterLength, filter.length],
  );

  return (
    <SimslAutocomplete
      {...rest}
      data={suggestedOptions || []}
      highlightedOptions={highlightedOptions}
      inputRef={inputRef}
      isLoading={isLoading}
      onChange={(option: T | null) => {
        setSelectedOption(option);
        onChange(option);
      }}
      onClose={handleDropdownClose}
      onInputChanged={handleInputChange}
      open={isDropdownOpen}
      value={selectedOption}
    />
  );
}

export default AutocompleteFilterAsync;
