import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Box } from '..';
import { StyledDatePicker } from './SimslDatePicker.styles';

export type SimslDatePickerProps<TDate> = Omit<DatePickerProps<TDate>, 'renderInput'> & {
  error?: boolean;
  autoOk?: boolean;
  name?: string;
  textFieldBaseMinWidth?: string;
  placeholder?: string;
  helperText?: string;
  id?: string;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  dataTestId?: string;
  pickerSx?: SxProps<Theme>;
};

function SimslDatePicker<TDate>(props: SimslDatePickerProps<TDate>) {
  const {
    className,
    error = false,
    maxDate,
    placeholder = 'Pick Date...',
    autoOk,
    minDate,
    name,
    helperText,
    onMouseDown,
    id,
    textFieldBaseMinWidth = '100px',
    dataTestId,
    sx,
    pickerSx,
    ...rest
  } = props;

  const [isErrorActive, setIsErrorActive] = useState<boolean>(false);
  const [errorReason, setErrorReason] = useState<string | null>(null);

  const errorMessage = React.useMemo(() => {
    if (errorReason === 'minDate') return 'Date should not be before minimal date.';
    if (errorReason === 'maxDate') return 'Date should not be after maximal date.';
    if (errorReason === 'invalidDate') return 'Invalid Date Format';
    return null;
  }, [errorReason]);

  if (dataTestId?.endsWith('date')) {
    // eslint-disable-next-line no-console
    console.warn("Date picker already has 'date-picker' suffix.\n Please remove 'date' from dataTestId prop to avoid duplication.");
  }

  return (
    <Box className={className} sx={sx}>
      <StyledDatePicker
        {...rest}
        disableHighlightToday
        format="dd/MM/yyyy"
        isErrorActive={isErrorActive || error}
        maxDate={maxDate || undefined}
        minDate={minDate || undefined}
        onError={(reason: string | null) => {
          setIsErrorActive(Boolean(reason));
          setErrorReason(reason);
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            error: error || isErrorActive,
            helperText: helperText || errorMessage,
            id: name || '',
            inputProps: {
              placeholder,
              'data-testid': dataTestId ? `${dataTestId}-date-picker` : undefined,
              'aria-errormessage': `${name}-helper-text`,
            },
            name,
            onMouseDown,
          },
        }}
        sx={pickerSx}
        textFieldBaseMinWidth={textFieldBaseMinWidth}
      />
    </Box>
  );
}

export default SimslDatePicker;
