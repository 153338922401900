/* eslint-disable scanjs-rules/identifier_sessionStorage */
import { useCallback, useReducer } from 'react';
import { getDefaultSorting } from 'utilities/misc/TableHelper';
import { useLocalStorage } from 'utilities/localStorage';
import { SortDirection, Sorting } from 'common/model';
import { TaskFilters, TaskSearchState } from 'tasks/model';
import { DEFAULT_COLUMNS } from '../../../../const';
import { getInitialTaskState, getTaskGridStorageKey } from '../../helpers';
import taskSearchReducer, { ClauseSearchAction } from '../../taskSearch.reducer';

type TaskSearchAction = {
  changePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPageNumber: number) => void;
  changePageSize: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeFilters: (currentFilters: TaskFilters | null) => void;
  changeSort: (sorting: Sorting) => void;
  reset: () => void;
};

const useTaskSearchAction = (): [TaskSearchState, TaskSearchAction] => {
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const storageKey = getTaskGridStorageKey();
  const initialState = getInitialTaskState(getStorageItem);
  const [state, dispatch] = useReducer(
    (reducerState: TaskSearchState, action: ClauseSearchAction) => taskSearchReducer(reducerState, action, getStorageItem),
    initialState,
  );

  const reset = useCallback(() => {
    dispatch({ type: 'RESET', payload: null });
  }, [dispatch]);

  const changeSort = useCallback(
    (sorting: Sorting) => {
      const updatedSorting = sorting.sortDirection === SortDirection.UNSPECIFIED ? getDefaultSorting(DEFAULT_COLUMNS) : sorting;

      sessionStorage.setItem(`${storageKey}Sorting`, JSON.stringify(updatedSorting));
      dispatch({ type: 'CHANGE_SORT', payload: updatedSorting });
    },
    [dispatch, storageKey],
  );

  const changePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPageNumber: number) => {
      dispatch({ type: 'CHANGE_PAGE', payload: newPageNumber });
    },
    [dispatch],
  );

  const changeFilters = useCallback(
    (filters: TaskFilters | null) => {
      if (filters) {
        sessionStorage.setItem(`${storageKey}Filters`, JSON.stringify(filters));
      } else {
        sessionStorage.removeItem(`${storageKey}Filters`);
      }

      dispatch({ type: 'CHANGE_FILTERS', payload: filters });
    },
    [dispatch, storageKey],
  );

  const changePageSize = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStorageItem('gridPageSize', JSON.stringify(event.target.value));
      dispatch({ type: 'CHANGE_PAGE_SIZE', payload: Number(event.target.value) });
    },
    [dispatch, setStorageItem],
  );

  return [state, { changeSort, changePage, changeFilters, changePageSize, reset }];
};

export default useTaskSearchAction;
