import Alert from '@mui/material/Alert';

import { styled } from 'components';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: '#4B4C51',
  color: theme.palette.primary.contrastText,
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
}));
