import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import isNil from 'lodash.isnil';

import { useSnackbar } from './components';

type Error = {
  response: {
    data: {
      StatusCode: number;
      Message: string;
    };
    status: number;
    statusText: string;
    config: {
      headers: { skipRedirection?: boolean };
    };
  };
};

const useModuleErrorInterceptor = (errorUrl: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const databaseUnavailableInterceptor = useCallback(
    (error: Error) => {
      if (error && error.response && isNil(error.response.config.headers.skipRedirection)) {
        switch (error.response.status) {
          case 401:
            enqueueSnackbar(error.response.data?.Message || 'User is not authorized.', 'error', true);
            navigate(errorUrl);
            break;
          case 504:
          case 500:
            enqueueSnackbar(error.response.data?.Message || 'Workbench services are temporarily unavailable.', 'error', true);
            navigate(errorUrl);
            break;
          default:
            break;
        }
      }
      return Promise.reject(error.response ? error.response.data || error.response : error);
    },
    [enqueueSnackbar, errorUrl, navigate],
  );

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(r => r, databaseUnavailableInterceptor);
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [databaseUnavailableInterceptor]);
};

export default useModuleErrorInterceptor;
