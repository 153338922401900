import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'components';
import { ContainerProps } from '@mui/material';
import { StyledContainer, StyledContentBox } from './styles';

type SimslModuleProps = ContainerProps & {
  title: string;
  metaTitle?: string;
};

const SimslModule: React.FC<SimslModuleProps> = ({ title, metaTitle, maxWidth = false, children, ...props }) => {
  return (
    <StyledContainer {...props} maxWidth={maxWidth}>
      <Helmet>
        <title>{`Workbench - ${metaTitle || title}`}</title>
      </Helmet>
      <Typography color="primary" component="h1" noWrap variant="h4">
        {title}
      </Typography>
      <StyledContentBox>{children}</StyledContentBox>
    </StyledContainer>
  );
};

export default SimslModule;
