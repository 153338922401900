import React from 'react';
import { GridSize } from '@mui/material';

import { Grid, Tooltip, Typography } from 'components';
import { textSpacesToDashFormat } from 'utilities/misc/StringHelper';
import { StyledInfoOutlined, StyledInfoTooltipGrid, StyledLabelGrid, StyledRootGrid } from './Property.styles';

type PropertyProps = {
  children: React.ReactNode;
  infoTooltip?: React.ReactNode;
  label: string;
  labelGridSize?: GridSize;
  childrenGridSize?: GridSize;
  labelGridSizeSm?: GridSize;
  childrenGridSizeSm?: GridSize;
  labelGridSizeMd?: GridSize;
  childrenGridSizeMd?: GridSize;
  labelGridSizeLg?: GridSize;
  childrenGridSizeLg?: GridSize;
  labelGridSizeXl?: GridSize;
  childrenGridSizeXl?: GridSize;
  required?: boolean;
  className?: string;
  propertyContainerSize?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize; xl?: GridSize };
};

const Property: React.FC<PropertyProps> = ({
  children,
  className,
  childrenGridSize = 7,
  labelGridSize = 5,
  labelGridSizeSm,
  childrenGridSizeSm,
  labelGridSizeMd,
  childrenGridSizeMd,
  labelGridSizeLg,
  childrenGridSizeLg,
  labelGridSizeXl,
  childrenGridSizeXl,
  infoTooltip,
  label,
  required = false,
  propertyContainerSize,
}) => {
  return (
    <StyledRootGrid {...propertyContainerSize} className={className} container item spacing={0}>
      <Grid
        alignItems="center"
        container
        item
        lg={labelGridSizeLg}
        md={labelGridSizeMd}
        sm={labelGridSizeSm}
        xl={labelGridSizeXl}
        xs={labelGridSize}
      >
        <StyledLabelGrid item>
          <Typography color="textSecondary" variant="caption">
            {label}
            {required && '*'}
          </Typography>
        </StyledLabelGrid>
        {infoTooltip && (
          <StyledInfoTooltipGrid item>
            <Tooltip title={infoTooltip}>
              <StyledInfoOutlined color="primary" data-testid={`${textSpacesToDashFormat(label)}-tooltip`} />
            </Tooltip>
          </StyledInfoTooltipGrid>
        )}
      </Grid>
      <Grid item lg={childrenGridSizeLg} md={childrenGridSizeMd} sm={childrenGridSizeSm} xl={childrenGridSizeXl} xs={childrenGridSize}>
        {children}
      </Grid>
    </StyledRootGrid>
  );
};

export default Property;
