import { SelectOption } from 'components/Select';

export const coverDetailsLabels = {
  coverId: 'Cover Id',
  coverDescription: 'Cover Description',
  coverInceptionDate: 'Inception Date',
  coverExpiryDate: 'Expiry Date',
  typeOfBusiness: 'Type of Business',
  policyGroup: 'Policy Group',
  subCoverTypeGroup: 'Sub Cover Type Group',
  subCoverTypeSuffix: 'Sub Cover Type Suffix',
  subCoverType: 'Sub Cover Type Description',
  oilLimitOfLiabilityInUSD: 'Oil Limit of Liability in USD',
  otherLimitOfLiabilityCurrency: 'Other Limit of Liability Currency',
  otherLimitOfLiability: 'Other Limit of Liability',
  callableIndicator: 'Callable',
  poolableIndicator: 'Poolable',
  quotaSharePercentage: 'Quota Share',
  leadIndicator: 'Lead (Quota Share)',
  brokerLumpSumOrPercentageIndicator: 'Broker Lump Sum or Percentage',
  brokerLumpSum: 'Broker Lump Sum (Cover)',
  brokerPercentage: 'Broker Percentage',
  usdBrokerage: 'Brokerage (USD)',
  coverCurrency: 'Cover Currency',
  currentInsurer: 'Current Insurer',
  club: 'SSM Club',
  submissionCoverStatus: 'Status',
  insurableObjectType: 'Insurable Object Type',
  premiumAmount: 'Premium Amount',
  nTUWhereTo: 'NTU Where To',
  nTUDeclinedReason: 'NTU / Declined Reason',
  nTUDeclinedReasonText: 'No Order Details',
  retainedPremiumAmount: 'Retained Premium (Cover)',
  bdiIndicator: 'Both Days Incl.',
};

export const premiumTypes: SelectOption[] = [
  { id: 'F', name: 'Fixed' },
  { id: 'M', name: 'Mutual' },
];

export const brokerageTypes = [
  {
    id: 'P',
    name: 'Percentage',
  },
  {
    id: 'L',
    name: 'Lump Sum',
  },
  {
    id: 'N',
    name: 'None',
  },
];

export const NO_COVERS_MESSAGE = 'No Covers have been added';

export const COVER_CREWS_GRID_NAME = 'CoverCrews';
export const COVER_FLEETS_GRID_NAME = 'CoverFleets';
export const COVER_VESSELS_GRID_NAME = 'CoverVessels';
export const COVER_YACHTS_GRID_NAME = 'CoverYachts';

export const COVER_CURRENCY_WARNING_MESSAGE =
  'There are Deductibles with the same Currency assigned to this Cover. Do you wish to apply changed currency to all of them?';

export const QUOTA_SHARE_PERCENTAGE_TOOLTIP = 'Indicates Quota Share Business and the Percentage';
export const BROKER_LUMP_SUM_OR_PERCENTAGE_INDICATOR_TOOLTIP =
  'Indicates whether Brokerage is a Lump Sum, Percentage or whether there is No Brokerage, if Lump Sum Brokerage Amount should be recorded on at least one Attachment';
export const USD_BROKERAGE_TOOLTIP = 'The US Dollar equivalent amount of Brokerage for Attachments linked to this Cover';
export const SUBMISSION_COVER_STATUS_TOOLTIP = 'The Status of the Cover';
export const BROKER_LUMP_SUM_TOOLTOP = 'The Lump Sum Brokerage in Cover Currency for all Attachments linked to this Cover';
export const VALUE_CANNOT_BE_CHANGED_IN_CURRENT_STATUS_TOOLTIP = 'This value cannot be changed in current Status.';
export const VALUE_CANNOT_BE_CHANGE_WHEN_ATTACHMENT_EXISTS =
  'This value cannot be changed as at least one Attachment is linked to this Cover.';
