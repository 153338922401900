import { styled, Box, Container } from 'components';

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(2)}`,
  [theme.breakpoints.down('xl')]: {
    padding: `0 ${theme.spacing(2)}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(0)}`,
  },
}));

export const StyledContentBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    margin: `${theme.spacing(2)} 0`,
  },
}));
