import { styled, Box } from 'components';

export const StyledContainerBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'timeout' && prop !== 'isCollapsed' && prop !== 'minHeightCollapsed' && prop !== 'minHeightExpanded',
})<{ timeout?: number; isCollapsed: boolean; minHeightCollapsed?: string; minHeightExpanded?: string }>(
  ({ timeout, isCollapsed, minHeightCollapsed, minHeightExpanded }) => ({
    position: 'relative',
    transitionDuration: `${timeout}ms`,
    transition: `height ${timeout}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    ...(isCollapsed && {
      minHeight: minHeightCollapsed,
    }),
    ...(!isCollapsed && {
      minHeight: minHeightExpanded,
    }),
  }),
);
