import React from 'react';
import { Select, SelectProps } from '@mui/material';

import { useOnMouseDown } from 'utilities/hooks';
import { StyledFormControl, StyledMenuItem } from './SimslSelectBoolean.styles';

type SimslSelectBooleanProps = Omit<SelectProps, 'variant'> & {
  trueLabel: string;
  falseLabel: string;
  emptyLabel?: string;
  dataTestId?: string;
};

const SimslSelectBoolean: React.FC<SimslSelectBooleanProps> = ({ trueLabel, falseLabel, emptyLabel, sx, dataTestId, ...rest }) => {
  const onMouseDown = useOnMouseDown();

  return (
    <StyledFormControl variant="standard">
      <Select
        {...rest}
        displayEmpty
        inputProps={{
          ...rest.inputProps,
          'data-testid': dataTestId ? `${dataTestId}-select-boolean` : undefined,
        }}
        onMouseDown={rest.onMouseDown ?? onMouseDown}
        sx={{ fontSize: theme => theme.typography.caption.fontSize, fontFamily: theme => theme.typography.fontFamily, ...sx }}
        value={rest.value === null ? '' : Number(rest.value)}
        variant="standard"
      >
        <StyledMenuItem data-testid={dataTestId ? `${dataTestId}-empty-value` : undefined} value="">
          {emptyLabel}
        </StyledMenuItem>
        <StyledMenuItem data-testid={dataTestId ? `${dataTestId}-true-value` : undefined} value={1}>
          {trueLabel}
        </StyledMenuItem>
        <StyledMenuItem data-testid={dataTestId ? `${dataTestId}-false-value` : undefined} value={0}>
          {falseLabel}
        </StyledMenuItem>
      </Select>
    </StyledFormControl>
  );
};

export default SimslSelectBoolean;
