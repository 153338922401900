import axios, { CancelTokenSource } from 'axios';

const HelpService = {
  getHelpUrl: (helpSection: string): [Promise<{ section: string; url: string }>, CancelTokenSource] => {
    const url = `/v2/help/${helpSection}`;

    const cancel = axios.CancelToken.source();

    const getRequest = axios
      .get<{ section: string; url: string }>(url, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);
    return [getRequest, cancel];
  },
};

export default HelpService;
