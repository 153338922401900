import { Drawer } from '@mui/material';

import { styled } from 'components/Theme';

export const StyledDrawer = styled(Drawer, { shouldForwardProp: prop => prop !== 'width' })<{ width: string }>(({ theme, width }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  },

  '& .MuiDrawer-paper': {
    overflowY: 'auto',
    width,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  width: 'fit-content',
}));
