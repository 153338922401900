/* eslint-disable scanjs-rules/identifier_sessionStorage */
import React, { useEffect, useCallback, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Column } from 'common/model';
import { useMediaQuery, useTheme, Box, SimslTable } from 'components';
import { SimslTableTableProps } from 'components/Table/SimslTable';
import { StyledTablePagination } from './GenericSearchGrid.styles';

export type GenericSearchGridProps<T> = Omit<SimslTableTableProps<T>, 'columns' | 'dense' | 'rowSelectedId'> & {
  defaultColumns: Column[];
  denseGrid: boolean;
  selectedRowId?: number;
  page: number;
  pageSize: number;
  gridName?: string;
  onPageChanged: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onPageSizeChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  count: number;
  className?: string;
  paginationClass?: string;
};

function GenericSearchGrid<T>(props: GenericSearchGridProps<T>) {
  const {
    defaultColumns,
    denseGrid,
    useRowsSecondaryColor,
    loading,
    data,
    onSortChanged,
    page,
    pageSize,
    onPageChanged,
    onTooltipRequest,
    activeSorting,
    onPageSizeChanged,
    onRowClicked,
    count,
    idKeyName = 'id',
    selectedRowId,
    paginationClass,
    className,
    ...rest
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const url = useResolvedPath('').pathname;
  const navigate = useNavigate();
  const tableRef = useRef();

  const scrollTableToTop = useCallback(() => {
    window.scrollTo(0, 0);
    const table = tableRef.current as unknown as HTMLElement;
    if (table && table.scrollTop > 0) {
      table.scrollTop = 0;
    }
  }, [tableRef]);

  useEffect(() => {
    scrollTableToTop();
  }, [data, scrollTableToTop]);

  const handleRowClicked = useCallback(
    (rowItem: T) => {
      if (onRowClicked) {
        onRowClicked(rowItem);
      } else {
        navigate(`${url}/${rowItem[idKeyName as keyof T]}`);
      }
    },
    [navigate, url, onRowClicked, idKeyName],
  );

  return (
    <Box className={className}>
      <SimslTable
        {...rest}
        activeSorting={activeSorting}
        columns={defaultColumns}
        data={data}
        dense={denseGrid}
        idKeyName={idKeyName}
        innerRef={tableRef}
        loading={loading}
        onRowClicked={handleRowClicked}
        onSortChanged={onSortChanged}
        onTooltipRequest={onTooltipRequest}
        rowSelectedId={selectedRowId}
        useRowsSecondaryColor={useRowsSecondaryColor}
      />
      <StyledTablePagination
        className={paginationClass}
        component="div"
        count={count}
        data-testid="pagination-menu"
        labelRowsPerPage={`${matches ? 'Per' : 'Items per'} page:`}
        onPageChange={onPageChanged}
        onRowsPerPageChange={onPageSizeChanged}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[10, 30, 90]}
      />
    </Box>
  );
}

export default GenericSearchGrid;
