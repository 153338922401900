import React from 'react';
import { InputProps } from '@mui/material';

import { useOnMouseDown } from 'utilities/hooks';
import SimslInput from '../Input/SimslInput';

const asteriskPattern = /[^0-9*]/gm;
const onlyNumberPattern = /[^0-9]/gm;

export type SimslNumberInputProps = {
  asterisk: boolean;
  label?: string;
  maxLength?: number;
  customInputWidth?: string;
  customDescriptionWidth?: number;
  name?: string;
  onInputChange?: (value: string) => void;
  labelClassName?: string;
  dataTestId?: string;
} & InputProps;

const SimslNumberInput: React.FC<SimslNumberInputProps> = ({
  asterisk,
  customDescriptionWidth = 120,
  customInputWidth = '100%',
  maxLength,
  inputProps,
  onInputChange,
  name,
  ...rest
}) => {
  const onMouseDown = useOnMouseDown();

  return (
    <SimslInput
      {...rest}
      customDescriptionWidth={customDescriptionWidth}
      customInputWidth={customInputWidth}
      maxLength={maxLength}
      name={name}
      onChange={e => {
        const value = e.target.value.replace(asterisk ? asteriskPattern : onlyNumberPattern, '');
        if (onInputChange) {
          onInputChange(value);
        }
        return value;
      }}
      onMouseDown={rest.onMouseDown ?? onMouseDown}
    />
  );
};

export default SimslNumberInput;
