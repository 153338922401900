import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RoutePath } from 'RoutePath';
import { v4 as uuidv4 } from 'uuid';
import { SettingsIcon } from 'icons';
import { UserProvider } from 'utilities/user';
import { useTasks } from 'tasks/hooks';
import { DEFAULT_FILTERS } from 'tasks/const';
import { TaskFilters, TaskItem } from 'tasks/model';
import { TaskCard } from './components/TaskCard';
import { StyledNavAppBar, StyledOptionsTab, StyledTab, StyledTabPanel, StyledTabs, StyledTasksLink } from './TaskList.styles';
import { TaskNotificationPreferences } from './components/TaskNotificationPreferences';

type TaskListProps = {
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  openTasks: TaskItem[];
};

const TaskList: React.FC<TaskListProps> = ({ handleClose, handleOpenTaskModal, openTasks }) => {
  const [selectedTab, setTab] = useState(0);
  const { fetchTasks, tasks } = useTasks({ ...DEFAULT_FILTERS, statusIdFilter: 2 });
  const [closedTaskFilters, setClosedTaskFilters] = useState<TaskFilters>(DEFAULT_FILTERS);

  const handleTabChange = useCallback((_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  }, []);

  const handleClosedTabChange = () => {
    setClosedTaskFilters({ ...closedTaskFilters, statusIdFilter: 2 });

    fetchTasks();
  };

  return (
    <>
      <StyledNavAppBar color="transparent" elevation={0} position="static">
        <StyledTabs
          indicatorColor="primary"
          onChange={handleTabChange}
          scrollButtons="auto"
          textColor="primary"
          value={selectedTab}
          variant="scrollable"
        >
          <StyledTab key={0} iconPosition="end" isSelected={selectedTab === 0} label="Active" value={0} />
          <StyledTab
            key={1}
            iconPosition="end"
            isSelected={selectedTab === 1}
            label="Completed"
            onClick={handleClosedTabChange}
            value={1}
          />
          <StyledOptionsTab key={2} icon={<SettingsIcon />} iconPosition="end" isSelected={selectedTab === 0} value={2} />
        </StyledTabs>
        <StyledTabPanel activeTabIndex={selectedTab} index={0}>
          {openTasks.map((task, index) => (
            <TaskCard key={uuidv4()} handleClose={handleClose} handleOpenTaskModal={handleOpenTaskModal} task={task} />
          ))}
        </StyledTabPanel>
        <StyledTabPanel activeTabIndex={selectedTab} index={1}>
          {tasks.map((task, index) => (
            <TaskCard key={uuidv4()} handleClose={handleClose} handleOpenTaskModal={handleOpenTaskModal} task={task} />
          ))}
        </StyledTabPanel>
        <StyledTabPanel activeTabIndex={selectedTab} index={2}>
          <UserProvider>
            <TaskNotificationPreferences />
          </UserProvider>
        </StyledTabPanel>
      </StyledNavAppBar>
      {(selectedTab === 0 || selectedTab === 1) && (
        <StyledTasksLink component={RouterLink} onClick={handleClose} to={RoutePath.TasksModule} underline="hover">
          Click to display as grid
        </StyledTasksLink>
      )}
    </>
  );
};

export default TaskList;
