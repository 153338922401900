import { useEffect, useState } from 'react';
import { DEFAULT_COLUMNS } from '../../../../const';

const useTaskColumns = () => {
  const [columns, setColumns] = useState(() => DEFAULT_COLUMNS);

  useEffect(() => {
    setColumns(DEFAULT_COLUMNS);
  }, []);

  return columns;
};

export default useTaskColumns;
