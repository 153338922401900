import React from 'react';
import { TableRowProps } from '@mui/material';

import TableRow from './TableRow';
import TableCell from './TableCell';

export type EmptyTableRowProps = TableRowProps & {
  message?: string;
  colSpan?: number;
};

const EmptyTableRow: React.FC<EmptyTableRowProps> = ({ message = 'No data', colSpan = 1 }) => (
  <TableRow>
    <TableCell align="center" colSpan={colSpan}>
      {message}
    </TableCell>
  </TableRow>
);

export default EmptyTableRow;
