import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Divider, Toolbar, Link, useTheme, SimslHelp } from 'components';
import { useFontSize } from 'utilities/localStorage';
import { TaskIcon } from 'tasks';
import LogoBlue from './logo-blue.svg';
import LogoSand from './logo-sand.svg';
import UserInfoExpandable from './UserInfoExpandable';
import { StyledAppBar, StyledLogo, StyledLogoContainer } from './Header.styles';

type HeaderProps = {
  helpSectionIdentifier?: string;
};

const Header: React.FC<HeaderProps> = ({ helpSectionIdentifier }) => {
  const { getRecalculatedFontSize } = useFontSize();
  const theme = useTheme();

  return (
    <StyledAppBar color="transparent" elevation={0} position="absolute">
      <Toolbar>
        <Link component={RouterLink} reloadDocument sx={{ flexGrow: 1 }} to="/" underline="hover">
          <StyledLogoContainer component="h2" noWrap variant="h6">
            {getRecalculatedFontSize && (
              <StyledLogo
                alt="Steamship Mutual"
                defaultHeight={getRecalculatedFontSize(45)}
                src={theme.palette.mode === 'light' ? LogoBlue : LogoSand}
              />
            )}
          </StyledLogoContainer>
        </Link>
        <SimslHelp sectionIdentifier={helpSectionIdentifier} />
        <TaskIcon />
        <Divider flexItem orientation="vertical" />
        <UserInfoExpandable />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
