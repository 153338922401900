import React from 'react';

import { Box, Tooltip, Typography } from 'components';
import { date2BritishString } from 'utilities/misc/DateTimeHelper';
import { InfoOutlined } from 'icons';
import PropertyEmptyValue from './PropertyEmptyValue';

type PropertyDateValueProps = {
  value: string | null;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  tooltipInfo?: string;
};

const PropertyDateValue: React.FC<PropertyDateValueProps> = ({ value, className, disabled = false, dataTestId, tooltipInfo }) => {
  if (!value) {
    return <PropertyEmptyValue dataTestId={dataTestId ? `${dataTestId}-date-value` : undefined} />;
  }

  if (dataTestId?.endsWith('date')) {
    // eslint-disable-next-line no-console
    console.warn("PropertyDateValue already has 'date-value' suffix.\n Please remove 'date' from dataTestId prop to avoid duplication.");
  }

  return (
    <Box alignItems="center" display="flex">
      <Typography
        color="textPrimary"
        data-testid={dataTestId ? `${dataTestId}-date-value` : undefined}
        sx={{ color: disabled ? theme => theme.palette.text.disabled : '' }}
        variant="caption"
      >
        {date2BritishString(new Date(value))}
      </Typography>
      {tooltipInfo && (
        <Box display="flex" marginLeft={1}>
          <Tooltip title={tooltipInfo}>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default PropertyDateValue;
