import React from 'react';
import DialogActions from '@mui/material/DialogActions';

import { Button } from 'components';

type DialogActionButtonsProps = {
  onSubmit: () => void;
  handleCancel: () => void;
  isSubmitDisabled?: boolean;
  isSubmitting?: boolean;
  customSubmitButtonText?: string;
  customCancelButtonText?: string;
};

const DialogActionButtons: React.FC<DialogActionButtonsProps> = ({
  handleCancel,
  onSubmit,
  isSubmitDisabled = false,
  isSubmitting = false,
  customCancelButtonText = 'cancel',
  customSubmitButtonText = 'ok',
  ...props
}) => {
  return (
    <DialogActions {...props}>
      <Button color="primary" disabled={isSubmitting} onClick={handleCancel} variant="outlined">
        {customCancelButtonText}
      </Button>
      <Button autoFocus color="primary" disabled={isSubmitDisabled} isLoading={isSubmitting} onClick={onSubmit} variant="contained">
        {customSubmitButtonText}
      </Button>
    </DialogActions>
  );
};

export default DialogActionButtons;
