/* eslint-disable scanjs-rules/identifier_localStorage */
import { useCallback } from 'react';
import { isAfter, add } from 'date-fns';

const useLocalStorage = () => {
  const getStorageItem = useCallback((key: string) => {
    const localStorageItem = localStorage.getItem(key);
    if (localStorageItem) {
      return JSON.parse(localStorageItem)?.value;
    }
    return null;
  }, []);

  const setStorageItem = useCallback((key: string, value: string, expiryDays = 180) => {
    const expiryDate = add(new Date(), { days: expiryDays });
    const localStorageValue = { value, expiryDate };
    localStorage.setItem(key, JSON.stringify(localStorageValue));
  }, []);

  const cleanLocalStorage = useCallback(() => {
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.forEach(item => {
      const localStorageValue = localStorage.getItem(item);
      if (!localStorageValue) return;
      const value = JSON.parse(localStorageValue);
      if (isAfter(new Date(), new Date(value?.expiryDate))) {
        localStorage.removeItem(item);
      }
    });
  }, []);

  return { cleanLocalStorage, setStorageItem, getStorageItem };
};
export default useLocalStorage;
