import React from 'react';
import { SxProps, Theme } from '@mui/material';

import { Grid } from '..';
import SectionDivider from './SectionDivider';
import SectionTitle from './SectionTitle';
import { StyledGrid } from './Section.styles';

export type SectionProps = {
  endingDivider?: boolean;
  title?: string;
  withBorder?: boolean;
  children?: React.ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
};

const Section: React.FC<SectionProps> = ({ children, endingDivider, title, sx, className, withBorder = false }) => {
  return (
    <StyledGrid className={className} container item sx={sx} withBorder={withBorder}>
      {title && <SectionTitle title={title} />}
      <Grid container item>
        {children}
      </Grid>
      {endingDivider && <SectionDivider />}
    </StyledGrid>
  );
};

export default Section;
