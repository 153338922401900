import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toDate } from 'utilities/misc/DateTimeHelper';
import { Box, SimslInput, SimslLabeledDatePicker, SimslSelectAsync } from 'components';
import { AutocompleteAsync, AutocompleteOption } from 'generics/Autocomplete';
import { DictionariesService } from 'common/services';
import { MemberUser } from 'memberGroups/model';
import { MIN_RECEIVED_DATE_FILTER_VALUE } from 'common/const';
import { StyledLabelTypography } from './FilterInputs.styles';

const FilterInputs: React.FC = () => {
  const { register, control } = useFormContext();

  return (
    <>
      <SimslInput
        customInputWidth="10rem"
        dataTestId="task-title-filter"
        id="task-title-search"
        inputProps={{
          ...register('titleFilter'),
        }}
        label="Title"
        maxLength={24}
        placeholder="Type Title..."
      />
      <Box alignItems="baseline" display="flex">
        <StyledLabelTypography color="textSecondary" variant="caption">
          Assigned By
        </StyledLabelTypography>
        <Controller
          control={control}
          name="assignedByUser"
          render={({ field: { ref, ...props } }) => (
            <AutocompleteAsync
              {...props}
              apiRequest={DictionariesService.getAllUsers}
              dataTestId="assigned-by-filter"
              getOptionLabel={(option: MemberUser) => option?.name || ''}
              inputRef={ref}
              isOptionEqualToValue={(option, value) => option?.id === value?.id || option?.name === value?.name}
              renderOption={(renderOptionParams, option) => (
                <AutocompleteOption {...renderOptionParams} key={option.id} value={option.name} />
              )}
              sx={{ maxWidth: '10rem' }}
            />
          )}
        />
      </Box>
      <Controller
        control={control}
        name="appIdFilter"
        render={({ field: { ref, ...props } }) => (
          <SimslSelectAsync
            {...props}
            apiRequest={DictionariesService.getTaskApplications}
            customInputWidth="10rem"
            dataTestId="task-application-filter"
            fetchOnInit
            inputRef={ref}
            label="Application"
            mapCallback={application => ({ id: application.applicationId, name: application.title })}
            placeholder="Select Application"
            withBlankOption
          />
        )}
      />
      <Controller
        control={control}
        name="statusIdFilter"
        render={({ field: { ref, ...props } }) => (
          <SimslSelectAsync
            {...props}
            apiRequest={DictionariesService.getTaskItemStatuses}
            customInputWidth="10rem"
            dataTestId="task-status-filter"
            fetchOnInit
            inputRef={ref}
            label="Task Status"
            mapCallback={status => ({ id: status.statusId, name: status.statusTitle })}
            placeholder="Select Status"
            withBlankOption
          />
        )}
      />
      <Controller
        control={control}
        name="taskTypeIdFilter"
        render={({ field: { ref, ...props } }) => (
          <SimslSelectAsync
            {...props}
            apiRequest={DictionariesService.getTaskTypes}
            customInputWidth="10rem"
            dataTestId="task-type-filter"
            fetchOnInit
            inputRef={ref}
            label="Task Type"
            mapCallback={type => ({ id: type.taskTypeId, name: type.type })}
            placeholder="Select Type"
            withBlankOption
          />
        )}
      />
      <Controller
        control={control}
        name="taskCreatedFromFilter"
        render={({ field: { ref, onChange, value, ...props } }) => (
          <SimslLabeledDatePicker
            {...props}
            autoOk
            dataTestId="created-from-filter"
            inputRef={ref}
            label="Created From"
            labelWidth={120}
            maxDate={new Date()}
            minDate={new Date(MIN_RECEIVED_DATE_FILTER_VALUE)}
            onChange={onChange}
            placeholder="Pick Date..."
            sx={{ maxWidth: '10rem', flexGrow: 2 }}
            textFieldBaseMinWidth="10rem"
            value={toDate(value)}
          />
        )}
      />
      <Controller
        control={control}
        name="taskCreatedToFilter"
        render={({ field: { ref, onChange, value, ...props } }) => (
          <SimslLabeledDatePicker
            {...props}
            autoOk
            dataTestId="created-to-filter"
            inputRef={ref}
            label="Created To"
            labelWidth={120}
            maxDate={new Date()}
            minDate={new Date(MIN_RECEIVED_DATE_FILTER_VALUE)}
            onChange={onChange}
            placeholder="Pick Date..."
            sx={{ maxWidth: '10rem', flexGrow: 2 }}
            textFieldBaseMinWidth="10rem"
            value={toDate(value)}
          />
        )}
      />

      <Controller
        control={control}
        name="dueDateFromFilter"
        render={({ field: { ref, onChange, value, ...props } }) => (
          <SimslLabeledDatePicker
            {...props}
            autoOk
            dataTestId="due-from-filter"
            inputRef={ref}
            label="Due From"
            labelWidth={120}
            minDate={new Date(MIN_RECEIVED_DATE_FILTER_VALUE)}
            onChange={onChange}
            placeholder="Pick Date..."
            sx={{ maxWidth: '10rem', flexGrow: 2 }}
            textFieldBaseMinWidth="10rem"
            value={toDate(value)}
          />
        )}
      />
      <Controller
        control={control}
        name="dueDateToFilter"
        render={({ field: { ref, onChange, value, ...props } }) => (
          <SimslLabeledDatePicker
            {...props}
            autoOk
            dataTestId="due-to-filter"
            inputRef={ref}
            label="Due To"
            labelWidth={120}
            minDate={new Date(MIN_RECEIVED_DATE_FILTER_VALUE)}
            onChange={onChange}
            placeholder="Pick Date..."
            sx={{ maxWidth: '10rem', flexGrow: 2 }}
            textFieldBaseMinWidth="10rem"
            value={toDate(value)}
          />
        )}
      />
    </>
  );
};

export default FilterInputs;
