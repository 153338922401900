import { SimslRoute } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  tasks: 'tasks',
};

export default abstract class Routes {
  static Tasks: SimslRoute = {
    name: 'Tasks',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.tasks,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
