import React from 'react';
import { StyledContainerBox } from './styles';

type SimslCollapseProps = {
  minHeightExpanded?: string;
  minHeightCollapsed?: string;
  isCollapsed: boolean;
  timeout?: number;
  children?: React.ReactNode;
};

const SimslCollapse: React.FC<SimslCollapseProps> = ({
  minHeightExpanded = 'auto',
  minHeightCollapsed = 'auto',
  isCollapsed,
  timeout = 300,
  children,
}) => {
  return (
    <StyledContainerBox
      isCollapsed={isCollapsed}
      minHeightCollapsed={minHeightCollapsed}
      minHeightExpanded={minHeightExpanded}
      timeout={timeout}
    >
      {children}
    </StyledContainerBox>
  );
};

export default SimslCollapse;
