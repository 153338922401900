import React from 'react';

import { Typography } from 'components';
import { StyledGrid } from './Title.styles';

type TitleProps = {
  title: string;
  dataTestId?: string;
};

const Title: React.FC<TitleProps> = ({ title, dataTestId }) => {
  return (
    <StyledGrid item xs={12}>
      <Typography color="primary" data-testid={dataTestId ? `${dataTestId}-title` : undefined} variant="h6">
        {title}
      </Typography>
    </StyledGrid>
  );
};

export default Title;
