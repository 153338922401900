import React from 'react';

import { Box, Tooltip } from 'components';
import { ErrorIcon } from 'icons';
import { DELETED_VALUE_MESSAGE } from 'common/const';
import { StyledTypography } from './PropertyDeletedValue.styles';

type PropertyDeletedValueProps = {
  value?: string;
  tooltipInfo: string;
  dataTestId?: string;
};

const PropertyDeletedValue: React.FC<PropertyDeletedValueProps> = ({ value = DELETED_VALUE_MESSAGE, tooltipInfo, dataTestId }) => {
  return (
    <Box alignItems="center" display="flex">
      <StyledTypography color="error" data-testid={dataTestId ? `${dataTestId}-value` : undefined} variant="caption">
        {value}
      </StyledTypography>
      <Box display="flex" flex={2} marginLeft={1}>
        <Tooltip title={tooltipInfo}>
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PropertyDeletedValue;
