import { styled } from 'components';

type StyleProps = {
  maxHeight: string;
};

export const StyledDiv = styled('div')<StyleProps>(({ maxHeight }) => ({
  maxHeight,
  overflowY: 'auto',
}));
