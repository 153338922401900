import React from 'react';

import { Box } from 'components';
import { ActionButton } from 'components/Button';

export type FormActionButtonsProps = {
  onCancel: () => void;
  onSave?: () => void;
  isSubmitting: boolean;
  isDisabled?: boolean;
};

const FormActionButtons: React.FC<FormActionButtonsProps> = ({ onCancel, isDisabled, isSubmitting, onSave }) => {
  return (
    <Box>
      <ActionButton
        actionType="save"
        aria-busy={isSubmitting}
        disabled={isDisabled}
        isLoading={isSubmitting}
        name="loading-button"
        onClick={onSave}
        sx={{ marginRight: theme => theme.spacing(1) }}
        type="submit"
      />
      <ActionButton actionType="cancel" disabled={isSubmitting} onClick={onCancel} />
    </Box>
  );
};

export default FormActionButtons;
