import React from 'react';
import { DialogProps, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Typography from '../Typography';

export type NotificationDialogProps = DialogProps & {
  onClose: () => void;
  dataTestId?: string;
  title?: string;
  message: string | React.ReactNode;
};

const NotificationDialog: React.FC<NotificationDialogProps> = ({ message, onClose, open, title, dataTestId }) => (
  <Dialog open={open}>
    <DialogTitle>
      <Typography color="primary" component="span" variant="h5">
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography color="textPrimary" component="span" style={{ whiteSpace: 'pre-line' }} variant="body1">
        {message}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose} variant="outlined">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default NotificationDialog;
