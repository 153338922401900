import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';

import { Spinner } from 'components';
import { RoutePath } from 'RoutePath';
import { Config, getConfig } from './config.service';

const ConfigContext: React.Context<Config | undefined> = React.createContext<Config | undefined>(undefined);

type ConfigProviderProps = {
  children: React.ReactNode;
};

const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState(null as unknown as Config);

  const initializeConfig = useCallback(async () => {
    getConfig()
      .then(setConfig)
      .catch(() => {
        return window.location.replace(RoutePath.Error);
      });
  }, [setConfig]);

  useEffect(() => {
    initializeConfig();
  }, [initializeConfig]);

  return config ? <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider> : <Spinner overlay />;
};

const useConfig = () => {
  const configContext = useContext(ConfigContext);
  if (configContext === undefined) {
    throw new Error('useConfig must be used within ConfigProvider');
  }

  const config = useMemo<Config>(() => configContext, [configContext]);
  return config;
};

export { useConfig, ConfigProvider };
