import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch } from 'components';
import { useUser } from 'utilities/hooks';
import { usePreferences, usePreferencesUpdate } from './hooks';
import { TaskPreference } from './model';
import { StyledTabHeader } from './TaskNotificationPreferences.styles';

const TaskNotificationPreferences: React.FC = () => {
  const user = useUser();
  const { fetchPreferences, preferences, isLoading } = usePreferences(user?.userCode ?? 0);
  const { patchPreferences } = usePreferencesUpdate();
  const [userPreference, setUserPreference] = useState<TaskPreference>({ preferenceId: 0, userId: 0, email: false, teams: false });

  useEffect(() => {
    if (user?.userCode !== undefined) {
      fetchPreferences();
    }
  }, [user]);

  useEffect(() => {
    if (preferences !== undefined) {
      setUserPreference(preferences);
    }
  }, [preferences]);

  const handleToggleEmail = () => {
    setUserPreference({ ...userPreference, email: !userPreference.email });
    patchPreferences(userPreference.userId, { ...userPreference, email: !userPreference.email }).then(() => {
      if (user?.userCode !== undefined) {
        fetchPreferences();
      }
    });
  };

  const handleToggleTeams = () => {
    setUserPreference({ ...userPreference, teams: !userPreference.teams });
    patchPreferences(userPreference.userId, { ...userPreference, teams: !userPreference.teams }).then(() => {
      if (user?.userCode !== undefined) {
        fetchPreferences();
      }
    });
  };

  return (
    <>
      <StyledTabHeader variant="h5">Notification Settings</StyledTabHeader>
      {!isLoading && (
        <FormGroup>
          <FormControlLabel checked={userPreference.teams} control={<Switch />} label="Teams Messages" onClick={handleToggleTeams} />
          <FormControlLabel checked={userPreference.email} control={<Switch />} label="Email" name="email" onClick={handleToggleEmail} />
        </FormGroup>
      )}
    </>
  );
};

export default TaskNotificationPreferences;
