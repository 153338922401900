import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderRadius: 0,
  },
  '& textarea': {
    fontSize: theme.typography.caption.fontSize,
  },
}));
