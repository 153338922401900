import { useCallback, useState } from 'react';

import { AsyncStatus } from 'common';
import { HelpService } from 'common/services';
import { useCancelToken } from 'utilities/hooks';
import { useSnackbar } from 'components';

const useHelp = (sectionIdentifier: string = '') => {
  const { setCancelTokenSource } = useCancelToken();
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const { enqueueSnackbar } = useSnackbar();

  const handleHelpClicked = useCallback(() => {
    setStatus('fetching');
    const [request, cancel] = HelpService.getHelpUrl(sectionIdentifier);
    setCancelTokenSource(cancel);

    request
      .then((response: { section: string; url: string }) => {
        window.open(response.url, '_blank');
        setStatus('resolved');
      })
      .catch(e => {
        if (e.StatusCode === 404) {
          enqueueSnackbar('Help for this section is not available.', 'error');
        } else {
          enqueueSnackbar(e.Message || 'There was a problem during help retrieval. Please try again.', 'error');
        }
        setStatus('rejected');
      });
  }, [setCancelTokenSource, enqueueSnackbar, sectionIdentifier]);

  return {
    onHelpClicked: handleHelpClicked,
    isLoading: status === 'fetching',
  };
};

export default useHelp;
