import { SimslRoute } from 'common/model';

const helpSectionIdentifiers = {
  activeBusinesses: 'activeBusinesses',
  activeBusinessDetails: 'activeBusinessDetails',
  newBusiness: 'newBusiness',
  dormantBusinesses: 'dormantBusinesses',
  dormantBusinessDetails: 'dormantBusinessesDetails',
  editBusinessDetails: 'editBusinessDetails',
};

export default abstract class Routes {
  static ActiveBusinesses: SimslRoute = {
    name: 'Active',
    path: `active`,
    helpSection: helpSectionIdentifiers.activeBusinesses,
  };

  static ActiveBusinessDetails: SimslRoute = {
    name: 'Active',
    path: `:id`,
    helpSection: helpSectionIdentifiers.activeBusinessDetails,
  };

  static EditBusiness: SimslRoute = {
    name: 'Edit',
    path: `:id`,
    helpSection: helpSectionIdentifiers.editBusinessDetails,
  };

  static NewBusiness: SimslRoute = {
    name: 'New',
    path: `new`,
    helpSection: helpSectionIdentifiers.newBusiness,
  };

  static DormantBusinesses: SimslRoute = {
    name: 'Dormant',
    path: `dormant`,
    helpSection: helpSectionIdentifiers.dormantBusinesses,
  };

  static DormantBusinessDetails: SimslRoute = {
    name: 'Dormant',
    path: `:id`,
    helpSection: helpSectionIdentifiers.dormantBusinessDetails,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
