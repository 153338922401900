import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridButtons } from 'components/Button';
import { SimslFiltersContainer } from 'components';
import { TaskFilters } from 'tasks/model';
import { DEFAULT_FILTERS } from '../../../const';
import { taskFiltersValidationSchema } from '../validation.schema';
import FilterInputs from './FilterInputs';

type SearchFiltersProps = {
  selectedFilters: TaskFilters | null;
  onSubmit: (data: TaskFilters) => void;
  clearFilters: () => void;
};

const SearchFilters: React.FC<SearchFiltersProps> = ({ selectedFilters, onSubmit, clearFilters }) => {
  const methods = useForm<TaskFilters>({
    defaultValues: {
      ...selectedFilters,
    },
    resolver: yupResolver(taskFiltersValidationSchema),
    mode: 'onChange',
  });
  const { reset, handleSubmit } = methods;

  const handleClearFilters = useCallback(() => {
    clearFilters();
    reset({ ...DEFAULT_FILTERS });
  }, [reset, clearFilters]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimslFiltersContainer>
          <FilterInputs />
        </SimslFiltersContainer>
        <GridButtons onClearFilters={handleClearFilters} />
      </form>
    </FormProvider>
  );
};

export default SearchFilters;
