import React from 'react';
import HelpOutline from '@mui/icons-material/HelpOutline';

import useHelp from './useHelp.hook';
import Box from '../Box';
import CircularProgress from '../CircularProgress';

type SimslHelpProps = {
  sectionIdentifier?: string;
};

const SimslHelp: React.FC<SimslHelpProps> = ({ sectionIdentifier }) => {
  const { isLoading, onHelpClicked } = useHelp(sectionIdentifier);

  if (!sectionIdentifier) {
    return null;
  }

  return (
    <Box marginRight={2} onClick={onHelpClicked}>
      {isLoading ? <CircularProgress size={20} /> : <HelpOutline cursor="pointer" data-testid="help-icon" />}
    </Box>
  );
};

export default React.memo(SimslHelp);
