import { styled, AppBar, Typography } from 'components';

export const StyledLogo = styled('img')<{ defaultHeight: number }>(props => ({
  objectFit: 'contain',
  height: `${props.defaultHeight}px`,
  [props.theme.breakpoints.down('sm')]: {
    height: '26px',
  },
}));

export const StyledLogoContainer = styled(Typography)<{ component?: string }>(({ theme }) => ({
  color: 'inherit',
  display: 'flex',
  minWidth: '185px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    minWidth: '105px',
  },
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
