import React from 'react';
import { Typography } from '..';
import { CheckIcon } from '../../icons';
import Button from './Button';

type ApplyFiltersButtonProps = {
  onClick?: () => void;
};

const ApplyFiltersButton: React.FC<ApplyFiltersButtonProps> = ({ onClick }) => {
  return (
    <Button
      color="primary"
      dataTestId="apply-filters"
      onClick={onClick}
      size="small"
      startIcon={<CheckIcon />}
      type="submit"
      variant="outlined"
    >
      <Typography align="left" color="primary" display="block" variant="caption">
        APPLY FILTERS
      </Typography>
    </Button>
  );
};

export default ApplyFiltersButton;
