import React, { MouseEventHandler } from 'react';

type OnMouseDownProps = {
  children: React.ReactNode;
  onMouseDown: MouseEventHandler<HTMLDivElement> | undefined;
};

export const OnMouseDownContext: React.Context<MouseEventHandler<HTMLDivElement> | undefined> = React.createContext<
  MouseEventHandler<HTMLDivElement> | undefined
>(undefined);

export const OnMouseDownProvider: React.FC<OnMouseDownProps> = ({ onMouseDown, children }) => {
  return <OnMouseDownContext.Provider value={onMouseDown}>{children} </OnMouseDownContext.Provider>;
};
