import { styled, AppBar, Tab, Tabs } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledTab = styled(Tab, { shouldForwardProp: prop => prop !== 'isSelected' })<{
  component: any;
  to: string;
  isSelected?: boolean;
}>(({ theme, isSelected }) => ({
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'none',
  ...(isSelected && {
    '& .MuiTypography-root': {
      fontWeight: Number(theme.typography.fontWeightBold),
    },
  }),
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: theme.typography.h6.fontSize,
    textTransform: 'none',
  },
  '& .Mui-selected': {
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: Number(theme.typography.fontWeightBold),
    },
  },
}));
