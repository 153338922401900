import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { AutocompleteRenderOptionState, Popper, PopperProps, SxProps, Theme } from '@mui/material';
import { styled, Autocomplete, CircularProgress, TextField } from 'components';
import { useOnMouseDown } from 'utilities/hooks';

const AutoSizePopperComponent = (props: PopperProps) => {
  return <Popper {...props} placement="bottom-start" style={{ minWidth: '12.5rem', maxWidth: 'fit-content' }} />;
};

export const StyledAutocomplete = styled(Autocomplete<any>, {
  shouldForwardProp: prop => prop !== 'highlightColor' && prop !== 'isHighlighted',
})<{
  highlightColor?: string | null;
  isHighlighted: boolean;
}>(({ theme, highlightColor, isHighlighted }) => ({
  '& input': {
    fontSize: theme.typography.caption.fontSize,
    paddingRight: '1.5rem',
    ...(isHighlighted && {
      color: highlightColor || theme.palette.text.disabled,
    }),
  },
}));

export type SimslAutocompleteProps<T> = {
  autoFocus?: boolean;
  autoWidth?: boolean;
  textFieldClass?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  className?: string;
  data: T[];
  value?: T | null;
  isLoading: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onChange?: (selectedOption: T | null) => void;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onInputChanged?: (event: ChangeEvent<HTMLInputElement>) => void;
  highlightedOptions?: T[];
  highlightColor?: string;
  dataTestId?: string;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
  sx?: SxProps<Theme>;
};

function SimslAutocomplete<T>(props: SimslAutocompleteProps<T>) {
  const {
    autoWidth = false,
    textFieldClass,
    error,
    helperText,
    isLoading,
    open,
    value,
    onClose,
    onOpen,
    getOptionLabel,
    isOptionEqualToValue,
    onInputChanged,
    onChange = () => {},
    autoFocus = false,
    placeholder = 'Search',
    data = [],
    highlightColor = null,
    highlightedOptions = [],
    inputRef,
    dataTestId,
    className,
    ...rest
  } = props;
  const onMouseDown = useOnMouseDown();

  const isOptionHighlighted = useCallback(
    (option: T | null | undefined) => (option ? highlightedOptions.some(x => isOptionEqualToValue(option, x)) : false),
    [highlightedOptions, isOptionEqualToValue],
  );

  const isHighlighted = useMemo<boolean>(() => isOptionHighlighted(value), [value, isOptionHighlighted]);

  return (
    <StyledAutocomplete
      {...rest}
      className={className}
      forcePopupIcon={false}
      getOptionLabel={getOptionLabel}
      highlightColor={highlightColor}
      isHighlighted={isHighlighted}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={isLoading}
      onChange={(_: React.ChangeEvent<{}>, option: T | null) => {
        onChange(option);
      }}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      options={[...highlightedOptions, ...data]}
      PopperComponent={autoWidth ? AutoSizePopperComponent : undefined}
      renderInput={params => (
        <TextField
          {...params}
          className={textFieldClass}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            autoFocus,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
            onChange: onInputChanged,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ ...params.inputProps, 'data-testid': dataTestId ? `${dataTestId}-autocomplete` : undefined }}
          inputRef={inputRef}
          onMouseDown={rest.onMouseDown ?? onMouseDown}
          placeholder={placeholder}
          variant="standard"
        />
      )}
      value={data ? value : undefined}
    />
  );
}

export default SimslAutocomplete;
