import React, { useEffect } from 'react';
import { Route, RouteProps, Routes, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { Spinner } from 'components';
import { BACK_REDIRECT_KEY } from './AuthProvider';
import { useAxiosInterceptors } from './useInterceptor.hook';
import { useAuth } from './useAuth';

const ProtectedRoute: React.FC<RouteProps> = ({ element, ...rest }) => {
  const { login } = useAuth();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      const pathname = location?.pathname;
      if (pathname) {
        // eslint-disable-next-line scanjs-rules/identifier_sessionStorage
        sessionStorage.setItem(BACK_REDIRECT_KEY, pathname);
      }
      login();
    }
  }, [isAuthenticated, login, location?.pathname]);

  useAxiosInterceptors();
  return (
    <Routes>
      <Route {...rest} element={isAuthenticated ? element : <Spinner />} />
    </Routes>
  );
};

export default ProtectedRoute;
