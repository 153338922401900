import { styled, Box, Typography } from '@mui/material';

export const StyledRootBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
}));

export const StyledDescriptionTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'labelWidth',
})<{ labelWidth: number | string }>(({ theme, labelWidth }) => ({
  flex: '1 0 auto',
  marginRight: theme.spacing(2),
  maxWidth: labelWidth,
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    minWidth: 70,
  },
}));
