import { SimslRoute } from 'common/model';

const helpSectionIdentifiers = {
  openSurveys: 'openSurveys',
  openSurveyDetails: 'openSurveyDetails',
  closedSurveys: 'closedSurveys',
  closedSurveyDetails: 'closedSurveyDetails',
  newSurvey: 'newSurvey',
  editOpenSurvey: 'editOpenSurvey',
};
export default abstract class Routes {
  static OpenSurveys: SimslRoute = {
    name: 'Open',
    path: `open`,
    helpSection: helpSectionIdentifiers.openSurveys,
  };

  static OpenSurveyDetails: SimslRoute = {
    name: 'Open',
    path: `:id`,
    helpSection: helpSectionIdentifiers.openSurveyDetails,
  };

  static EditOpenSurvey: SimslRoute = {
    name: 'Open',
    path: `:id`,
    helpSection: helpSectionIdentifiers.editOpenSurvey,
  };

  static ClosedSurveys: SimslRoute = {
    name: 'Closed',
    path: `closed`,
    helpSection: helpSectionIdentifiers.closedSurveys,
  };

  static ClosedSurveyDetails: SimslRoute = {
    name: 'Closed',
    path: `:id`,
    helpSection: helpSectionIdentifiers.closedSurveyDetails,
  };

  static NewSurvey: SimslRoute = {
    name: 'New',
    path: `new`,
    helpSection: helpSectionIdentifiers.newSurvey,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
