import React from 'react';
import { Link } from 'components';
import { TaskItem } from 'tasks/model';
import { taskTypes } from 'tasks/const';
import { Link as RouterLink } from 'react-router-dom';
import {
  StyledBox,
  StyledApplicationReference,
  StyledDate,
  StyledTaskTitle,
  StyledTaskIcon,
  StyledDescription,
  StyledLinkContainer,
  StyledCheckCircleOutlineIcon,
  StyledDoNotDisturbAltIcon,
  StyledAssignmentIcon,
} from './TaskCard.styles';

type TaskCardProps = {
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  task: TaskItem;
};

const TaskCard: React.FC<TaskCardProps> = ({ handleClose, handleOpenTaskModal, task }) => {
  return (
    <StyledBox>
      <StyledTaskTitle color="primary" onClick={() => handleOpenTaskModal(task)} variant="h6">
        {task.title}
      </StyledTaskTitle>
      <StyledApplicationReference>{task.application.title}</StyledApplicationReference>
      <StyledTaskIcon>
        {task.taskType.taskTypeId === taskTypes.approval && <StyledCheckCircleOutlineIcon />}
        {task.taskType.taskTypeId === taskTypes.declinature && <StyledDoNotDisturbAltIcon />}
        {task.taskType.taskTypeId === taskTypes.assignment && <StyledAssignmentIcon />}
      </StyledTaskIcon>
      <StyledDescription variant="caption">{task.description.split('\n')[0]}</StyledDescription>
      <StyledLinkContainer variant="subtitle2">
        <Link component={RouterLink} onClick={handleClose} to={task.viewUrl}>
          Link
        </Link>
      </StyledLinkContainer>
      {task.dueDateUI && <StyledDate>Due By: {task.dueDateUI}</StyledDate>}
    </StyledBox>
  );
};

export default TaskCard;
