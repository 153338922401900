import React from 'react';
import { StyledAppBar, StyledTab, StyledTabs } from './TabsNavigation.styles';

export type TabItem = {
  idx: number;
  title: string;
};

type TabsNavigationProps = {
  onTabChange: (_: React.ChangeEvent<{}>, newValue: number) => void;
  activeTabIndex: number;
  tabs: TabItem[];
  disabled?: boolean;
  className?: string;
};

const TabsNavigation: React.FC<TabsNavigationProps> = ({ tabs, onTabChange, activeTabIndex, className, disabled = false }) => {
  return (
    <StyledAppBar className={className} color="transparent" elevation={0} position="static">
      <StyledTabs
        indicatorColor="primary"
        onChange={onTabChange}
        scrollButtons="auto"
        textColor="primary"
        value={activeTabIndex}
        variant="scrollable"
      >
        {tabs.map(({ title }) => (
          <StyledTab key={title} disabled={disabled} label={title} />
        ))}
      </StyledTabs>
    </StyledAppBar>
  );
};

export default TabsNavigation;
