import React, { ReactElement } from 'react';
import { SimslRoute } from 'common/model';
import { Container } from 'components';
import MuiXLicense from 'components/MuiXLicense/MuiXLicense';
import DialogSnackBar from 'components/Snackbar/DialogSnackBar';
import Header from './Header';
import Footer from './Footer';
import { StyledBreadcrumbs, StyledContent, StyledRoot, StyledSnackbarProvider } from './Layout.styles';

// https://notistack.com/features/customization
declare module 'notistack' {
  interface VariantOverrides {
    waf403Error: { title?: string };
  }
}

export type LayoutProps = {
  children: ReactElement;
  moduleName: string | null;
  mainPath: string | null;
  activeRoute?: SimslRoute | null;
};

const Layout: React.FC<LayoutProps> = ({ children, moduleName, mainPath, activeRoute }) => {
  return (
    <StyledRoot>
      <MuiXLicense />
      <StyledSnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        Components={{
          waf403Error: DialogSnackBar,
        }}
        maxSnack={3}
      >
        <Header helpSectionIdentifier={activeRoute?.helpSection} />
        <StyledContent>
          {moduleName && <StyledBreadcrumbs activeRoute={activeRoute} mainPath={mainPath} moduleName={moduleName} />}
          <Container maxWidth={false}>{children}</Container>
          <Footer />
        </StyledContent>
      </StyledSnackbarProvider>
    </StyledRoot>
  );
};

export default Layout;
