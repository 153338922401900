import React from 'react';
import { LoadingButton as MuiButton, LoadingButtonProps } from '@mui/lab';

export type ButtonProps = {
  isLoading?: boolean;
  dataTestId?: string;
} & LoadingButtonProps;

const Button: React.FC<ButtonProps> = ({ disabled = false, isLoading = false, dataTestId, children, ...rest }) => {
  return (
    <MuiButton
      {...rest}
      className={rest.className}
      data-testid={dataTestId ? `${dataTestId}-button` : undefined}
      disabled={disabled}
      loading={isLoading}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
