import { SimslRoute, SimslRouteWithUrl } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  submissions: 'submissions',
  submissionDetails: 'submissionDetails',
  newSubmission: 'newSubmission',
};

export default abstract class RoutePath {
  static Submissions: SimslRoute = {
    name: 'Submissions',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.submissions,
  };

  static SubmissionDetails: SimslRouteWithUrl = {
    name: 'Submission Details',
    path: `:submissionNumber/:submissionVersionId`,
    url: (parameter: string | undefined) => `${ModulesRoutes.SubmissionsModule}/${parameter}`,
    helpSection: helpSectionIdentifiers.submissionDetails,
  };

  static SubmissionDetailsWithoutVersion: SimslRouteWithUrl = {
    name: 'Submission Details',
    path: `:submissionNumber`,
    url: (parameter: string | undefined) => `${ModulesRoutes.SubmissionsModule}/${parameter}`,
    helpSection: helpSectionIdentifiers.submissionDetails,
  };

  static NewSubmission: SimslRoute = {
    name: 'New Submission',
    path: `new`,
    helpSection: helpSectionIdentifiers.newSubmission,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
