const customScroll = {
  '&::-webkit-scrollbar': {
    width: '0.75rem'
  },
  '&::-webkit-scrollbar-track': {
    background: '#E0E0E0',
    borderRadius: '5px',
    margin: '0.5rem 0'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#A3A3A3',
    borderRadius: '20px',
    border: '3px solid transparent',
    backgroundClip: 'content-box'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#717171',
    border: '3px solid transparent',
    backgroundClip: 'content-box'
  }
};

export default customScroll;
