export type AzureConfig = {
  clientId: string;
  authority: string;
  validateAuthority: boolean;
  redirectUri: string;
  silentRedirectUri: string;
  postLogoutRedirectUri: string;
  navigateToLoginRequestUrl: boolean;
  graphScopes: string[];
  apiScopes: string[];
};

export type Config = {
  AzureAD: AzureConfig;
  ApplicationInsights: {
    ConnectionString: string;
  };
};

export const getConfig = async (): Promise<Config> => {
  return fetch(`${process.env.PUBLIC_URL}/app.config.json`).then(reponse => reponse.json());
};
