export abstract class ModuleNames {
  static BusinessesModule = 'Businesses';

  static BusinessContactsModule = 'Business Contacts';

  static SurveysModule = 'Surveys';

  static ObjectsModule = 'Object Maintenance';

  static MemberGroupsModule = 'Member Groups';

  static CertificateClausesModule = 'Certificate Clauses';

  static SubmissionsModule = 'Submissions';

  static BrokersModule = 'Brokers';

  static TasksModule = 'Tasks';

  static StatementsModule = 'Statements';
}

export default ModuleNames;
