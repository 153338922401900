import React from 'react';
import { DividerProps as MUIDividerProps } from '@mui/material/Divider';

import { StyledMuiDivider } from './styles';

export type DividerProps = MUIDividerProps & {
  thickness?: number;
  orientation?: string;
};

const Divider: React.FC<DividerProps> = ({ orientation, thickness = 1, ...props }) => {
  return <StyledMuiDivider {...props} orientation={orientation} thickness={thickness} />;
};

export default Divider;
