import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import ErrorPage from 'ErrorPage';
import NotFound from 'NotFound';
import AppProtectedRoutes from 'AppProtectedRoutes';
import { RoutePath } from './RoutePath';
import ProtectedRoute from './utilities/auth/ProtectedRoute';
import { LoginPage, LogoutPage } from './utilities/auth';

const ConfiguredRoutes: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<NotFound />} path={RoutePath.NotFound} />
        <Route element={<LogoutPage />} path={RoutePath.Logout} />
        <Route element={<ErrorPage />} path={RoutePath.Error} />
        <Route element={<LoginPage />} path={RoutePath.Login} />
        <Route element={<ProtectedRoute element={<AppProtectedRoutes />} path="*" />} path="*" />
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default ConfiguredRoutes;
