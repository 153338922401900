/* eslint-disable scanjs-rules/identifier_sessionStorage */
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'components';
import GenericSearchGrid from 'generics/GenericSearchGrid';
import { useNavigate } from 'react-router-dom';
import { OnMouseDownProvider } from 'utilities/onMouseDown/onMouseDownProvider';
import { ConfirmContextProvider } from 'utilities/hooks/useConfirm.hook';
import { TaskItem } from 'tasks/model';
import useTaskSearch from './hooks/useTaskSearch';
import useTaskColumns from './hooks/useTaskColumns';
import SearchFilters from './components';
import useTaskSearchAction from './hooks/useTaskSearchAction';
import { getTaskGridUrl } from './helpers';

type TaskGridProps = {
  handleOpenTaskModal: (task: TaskItem) => void;
};

const TaskGrid: React.FC<TaskGridProps> = ({ handleOpenTaskModal }) => {
  const columns = useTaskColumns();
  const { tasks, fetchTasks, isLoading, totalResults } = useTaskSearch();
  const [state, { changePage, changeSort, changeFilters, changePageSize }] = useTaskSearchAction();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const getTooltip = useCallback((): Promise<string> => {
    const message = '';

    return Promise.resolve(message);
  }, []);

  useEffect(() => {
    const cancel = fetchTasks({
      ...state,
    });

    return () => {
      cancel();
    };
  }, [state, fetchTasks]);

  const handleClose = useCallback(() => {
    const url = getTaskGridUrl();
    navigate(url);
    setIsSidePanelOpen(false);
  }, [navigate]);

  return (
    <Box margin="1rem 0">
      <ConfirmContextProvider>
        <OnMouseDownProvider onMouseDown={isSidePanelOpen ? handleClose : undefined}>
          <SearchFilters
            clearFilters={() => {
              changeFilters(null);
            }}
            onSubmit={changeFilters}
            selectedFilters={state.filters}
          />
        </OnMouseDownProvider>
        <GenericSearchGrid
          activeSorting={state.sort}
          count={totalResults}
          data={tasks}
          defaultColumns={columns}
          denseGrid
          loading={isLoading}
          onPageChanged={changePage}
          onPageSizeChanged={changePageSize}
          onRowClicked={handleOpenTaskModal}
          onSortChanged={changeSort}
          onTooltipRequest={getTooltip}
          page={state.pageNumber}
          pageSize={state.pageSize}
        />
      </ConfirmContextProvider>
    </Box>
  );
};

export default TaskGrid;
