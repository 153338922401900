import { styled, Box, Link } from 'components';

export const StyledRootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

export const StyledActionsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    marginRight: theme.spacing(0.5),
  },
}));

export const StyledBackLink = styled(Link)<{ component?: string }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: theme.spacing(1),
    transform: 'rotateZ(180deg)',
  },
}));
