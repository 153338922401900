import { styled, Grid } from 'components';
import { InfoOutlined } from 'icons';

export const StyledRootGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
  alignItems: 'baseline',
  marginBottom: '0.25rem',
}));

export const StyledLabelGrid = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const StyledInfoTooltipGrid = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
}));

export const StyledInfoOutlined = styled(InfoOutlined)(({ theme }) => ({
  fontSize: 'small',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.caption.fontSize,
  },
}));
