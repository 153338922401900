import { styled } from 'components';
import SimslAutocomplete from '../Autocomplete';

export const StyledSimslAutocomplete = styled(SimslAutocomplete<any | null>)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
