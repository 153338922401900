import { GridAggregationFunction } from '@mui/x-data-grid-premium';

const GridAggregationLastValue: GridAggregationFunction<string, string | null> = {
  apply: params => {
    return params.values[params.values.length - 1];
  },
  label: '',
  columnTypes: ['number'],
};

export default GridAggregationLastValue;
