import { styled } from 'components';
import TableRow from './TableRow';
import TableSortLabel from './TableSortLabel';

export const StyledTableSortLabel = styled(TableSortLabel, {
  shouldForwardProp: prop => prop !== 'isSortingActive',
})<{ isSortingActive?: boolean }>(({ theme, isSortingActive }) => ({
  '&.MuiTableSortLabel-root.Mui-active': {
    color: theme.palette.text.secondary,
  },
  ...(!isSortingActive && {
    cursor: 'auto',
  }),
}));

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'dense' && prop !== 'tableFontSize' && prop !== 'tableFontSizeUnit',
})<{ dense?: boolean; tableFontSize?: number; tableFontSizeUnit?: string }>(({ theme, dense, tableFontSize, tableFontSizeUnit }) => ({
  '& > th': {
    fontWeight: Number(theme.typography.fontWeightBold),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(dense ? 1 : 2)} 0`,
    height: '100%',
    ...(dense && {
      fontSize: `${tableFontSize}${tableFontSizeUnit}`,
    }),
    '& > div': {
      padding: `0 ${theme.spacing(dense ? 1 : 2)}`,
    },
    '&:not(:first-of-type)': {
      '& > *': {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    },
    ...(dense && {
      '& > *': {
        display: 'block',
      },
    }),
    '&:empty': {
      display: 'none',
    },
  },
}));
