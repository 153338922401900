import { SortDirection, Sorting, Column } from 'common/model';

export function mapSortDirection(sortDirection: SortDirection): 'asc' | 'desc' | undefined {
  switch (sortDirection) {
    case SortDirection.ASCENDING:
      return 'asc';
    case SortDirection.DESCENDING:
      return 'desc';
    default:
      return undefined;
  }
}

export function getDefaultSorting(columns: Column[]): Sorting {
  const defaultColumn = columns[0];
  const { sortKey, sort } = columns.find(column => column.sort !== undefined) || defaultColumn;

  return { sortKey, sortDirection: sort ?? SortDirection.UNSPECIFIED };
}
