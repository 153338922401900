import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import ClearApplyFiltersButtons from 'generics/ClearApplyFiltersButtons';
import { AddIcon } from 'icons';
import { Box } from 'components';
import ButtonWithTooltip, { ButtonWithTooltipProps } from './ButtonWithTooltip';

const gridButtonsStyles: SxProps<Theme> = {
  justifyContent: 'flex-end',
  gap: '0.5rem',
  marginTop: 2,
  marginBottom: 2,
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  alignItems: { xs: 'flex-end', sm: 'initial' },
  flexDirection: { xs: 'column', sm: 'row' },
};

type GridButtonsProps = {
  className?: string;
  sx?: SxProps<Theme>;
  onClearFilters: () => void;
  withActionButton?: boolean;
  actionButtonProps?: ButtonWithTooltipProps;
  actionButtonTitle?: string;
  dataTestId?: string;
};

const GridButtons: React.FC<GridButtonsProps> = ({
  onClearFilters,
  className,
  withActionButton = false,
  actionButtonTitle = '',
  actionButtonProps = {
    tooltipMessage: '',
  },
  sx = [],
  dataTestId,
}) => (
  <Box className={className} sx={gridButtonsStyles}>
    <ClearApplyFiltersButtons onClearFilters={onClearFilters} />
    {withActionButton && (
      <ButtonWithTooltip
        color="primary"
        dataTestId={dataTestId}
        startIcon={<AddIcon />}
        sx={{ ml: { xs: 0, sm: 5 } }}
        variant="contained"
        {...actionButtonProps}
      >
        {actionButtonTitle}
      </ButtonWithTooltip>
    )}
  </Box>
);

export default GridButtons;
