import React from 'react';
import { MsalProvider } from '@azure/msal-react';

import { useConfig } from 'utilities/config';
import { createMsalApp } from './auth-utils';

export const BACK_REDIRECT_KEY = 'return_url';

export type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const config = useConfig();
  const msalApp = createMsalApp(config.AzureAD);

  const accounts = msalApp.getAllAccounts();
  if (accounts.length > 0) {
    msalApp.setActiveAccount(accounts[0]);
  }

  return <MsalProvider instance={msalApp}>{children}</MsalProvider>;
};
