import { TextField, Chip } from '@mui/material';
import { styled } from 'components';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    fontSize: theme.typography.caption.fontSize,
  },
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== 'isColorLight',
})<{ isColorLight: boolean }>(({ theme, isColorLight }) => {
  return {
    margin: `${theme.spacing(0.25)} ${theme.spacing(0.5)} ${theme.spacing(0.25)} 0`,
    overflow: 'hidden',
    '& > span': {
      color: isColorLight ? theme.palette.background.paper : theme.palette.text.primary,
    },
    '& > svg': {
      color: isColorLight ? theme.palette.background.paper : theme.palette.text.primary,
    },
  };
});
