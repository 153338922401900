import React from 'react';
import sanitize, { IOptions } from 'sanitize-html';
import { StyledDiv } from './styles';

type SimslSvgViewerProps = {
  svgImage: string;
  maxHeight?: string;
};

const SimslSvgViewer: React.FC<SimslSvgViewerProps> = ({ maxHeight = 'inherit', svgImage }) => {
  const sanitizationOptions: IOptions = { allowedTags: ['svg', 'path'], allowedAttributes: false };
  const sanitizedContent = sanitize(svgImage, sanitizationOptions);

  return (
    <StyledDiv
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizedContent,
      }}
      maxHeight={maxHeight}
    />
  );
};

export default SimslSvgViewer;
