import { Select, SelectProps, FormControl, MenuItem, InputLabel, ListItemIcon, Theme } from '@mui/material';
import { styled } from 'components';

type StyleProps = {
  customMenuItemsHeight: string;
};

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop: any) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }: { theme: Theme; disabled?: boolean }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: '2.25rem',
    ...(disabled && {
      color: theme.palette.text.disabled,
    }),
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }: { theme: Theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const StyledBaseSelect = styled(Select, {
  shouldForwardProp: prop => prop !== 'isPlaceholderVisible' && prop !== 'isUnavailableOption',
})<{ disabled?: boolean; isPlaceholderVisible?: boolean; isUnavailableOption?: boolean }>(
  ({
    theme,
    disabled,
    isPlaceholderVisible,
    isUnavailableOption,
  }: {
    theme: Theme;
    disabled?: boolean;
    isPlaceholderVisible?: boolean;
    isUnavailableOption?: boolean;
  }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
    ...((disabled || isUnavailableOption) && {
      color: theme.palette.text.disabled,
    }),
    ...(!isPlaceholderVisible && {
      color: 'rgba(0, 0, 0, 0.35)',
    }),
  }),
);

export const StyledSelect = styled(({ className, customMenuItemsHeight, ...props }: SelectProps & StyleProps) => (
  <StyledBaseSelect {...props} MenuProps={{ PaperProps: { className } }} />
))(({ customMenuItemsHeight }: { customMenuItemsHeight: string }) => ({
  height: customMenuItemsHeight,
}));

export const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export const StyledInputLabel = styled(InputLabel)(() => ({
  top: '-0.5rem',
}));
