import React, { useCallback } from 'react';
import ArrowIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { StyledActionsBox, StyledRootBox, StyledBackLink } from './SidePanelHeader.styles';

export type SidePanelHeaderProps = {
  children: React.ReactNode;
  onCloseClicked?: () => void;
  className?: string;
};

const SidePanelHeader: React.FC<SidePanelHeaderProps> = ({ className, onCloseClicked, children }) => {
  const onCloseLinkClicked = useCallback(() => {
    if (onCloseClicked) {
      onCloseClicked();
    }
  }, [onCloseClicked]);

  return (
    <StyledRootBox>
      <StyledActionsBox>{children}</StyledActionsBox>
      <StyledBackLink
        color="textSecondary"
        component="button"
        data-testid="close-button"
        onClick={onCloseLinkClicked}
        type="button"
        underline="hover"
        variant="body2"
      >
        <ArrowIcon fontSize="small" />
        Close
      </StyledBackLink>
    </StyledRootBox>
  );
};

export default SidePanelHeader;
