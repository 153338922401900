import { TextFieldProps } from '@mui/material';
import React from 'react';
import { StyledTextField } from './SimslTextArea.styles';

type SimslTextAreaProps = TextFieldProps & {
  blockEnterKey?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  inputRef?: React.Ref<any>;
  name?: string;
  placeholder?: string;
  rows?: string | number;
  type?: React.InputHTMLAttributes<unknown>['type'];
  defaultValue?: string;
  dataTestId?: string;
};

const SimslTextArea: React.FC<SimslTextAreaProps> = ({
  blockEnterKey = false,
  error,
  helperText,
  inputRef,
  name,
  placeholder,
  rows,
  type,
  defaultValue,
  dataTestId,
  ...props
}) => {
  return (
    <StyledTextField
      // eslint-disable-next-line
      {...props}
      defaultValue={defaultValue}
      error={error}
      FormHelperTextProps={{
        sx: { margin: 0 },
      }}
      fullWidth
      helperText={helperText}
      inputProps={{
        ...props.inputProps,
        'data-testid': dataTestId ? `${dataTestId}-text-area` : undefined,
        onKeyDown: e => {
          if (blockEnterKey && e.key === 'Enter') {
            e.preventDefault();
          }
        },
      }}
      inputRef={inputRef}
      multiline
      name={name}
      placeholder={placeholder}
      rows={rows}
      type={type}
      variant="outlined"
    />
  );
};

export default SimslTextArea;
