import React from 'react';
import { FormControlLabel, Typography } from '@mui/material';

import { SimslCheckbox, SimslDisabledCheckbox } from 'components';

type FormControlLabelProps = 'end' | 'start' | 'top' | 'bottom' | undefined;

interface SimslLabeledCheckboxProps {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  inputRef?: ((instance: any) => void) | React.RefObject<any> | null | undefined;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
  label: string;
  labelPlacement?: FormControlLabelProps;
  name?: string;
}

const SimslLabeledCheckbox: React.FC<SimslLabeledCheckboxProps> = ({
  className,
  checked,
  disabled,
  inputRef,
  label,
  labelPlacement,
  name,
  onChange,
}) => {
  return (
    <FormControlLabel
      className={className}
      control={
        disabled ? (
          <SimslDisabledCheckbox checked={checked || false} inputRef={inputRef} name={name} />
        ) : (
          <SimslCheckbox checked={checked || false} inputRef={inputRef} name={name} onChange={onChange} />
        )
      }
      disabled={disabled}
      label={
        <Typography color="textSecondary" display="block" variant="caption">
          {label}
        </Typography>
      }
      labelPlacement={labelPlacement}
    />
  );
};

export default SimslLabeledCheckbox;
