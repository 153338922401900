import axios, { Canceler } from 'axios';
import { LoggedInUser } from '../model';

const InfoService = {
  getLoggedUserInfo: (): [Promise<LoggedInUser>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<LoggedInUser>('/v2/users/me', {
        cancelToken: cancel.token,
      })
      .then(response => {
        return response.data;
      });
    return [getRequest, cancel.cancel];
  },
};

export default InfoService;
