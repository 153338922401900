export abstract class RoutePath {
  static MainPath = '/*';

  static Home = '/';

  static Login = '/login';

  static Logout = '/logout';

  static NotFound = '/404';

  static Error = '/error';

  static BusinessesModule = '/businesses';

  static BusinessContactsModule = '/contacts';

  static SurveysModule = '/surveys';

  static ObjectsModule = '/objects';

  static MemberGroupsModule = '/memberGroups';

  static CertificateClausesModule = '/clauses';

  static SubmissionsModule = '/submissions';

  static BrokersModule = '/brokers';

  static TasksModule = '/tasks';

  static StatementsModule = '/statements';
}

export default RoutePath;
