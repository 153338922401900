import ReactDOM from 'react-dom/client';
import { format } from 'date-fns';
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  // API requires local timestamps (non-Zulu, non-UTC)
  return format(this, "yyyy-MM-dd'T'HH:mm:ss");
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
