import { styled, Box } from '../components';

export const StyledRootBox = styled(Box)(({ theme }) => ({
  alignItems: 'stretch',
  display: 'flex',
  justifyContent: 'left',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: 1500,
  textAlign: 'center',
  margin: theme.spacing(10, 'auto'),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
}));
