import { TextFieldProps } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { StyledTextField } from './SimslTextField.styles';

type SimslTextFieldProps = TextFieldProps & {
  error?: boolean;
  helperText?: React.ReactNode;
  inputRef?: React.Ref<any>;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  dataTestId?: string;
};

const SimslTextField: React.FC<SimslTextFieldProps> = ({
  error,
  helperText,
  inputRef,
  name,
  onChange,
  placeholder,
  value,
  dataTestId,
  ...props
}) => {
  return (
    <StyledTextField
      {...props}
      error={error}
      fullWidth
      helperText={helperText}
      id={name}
      inputProps={{
        ...props.inputProps,
        'aria-errormessage': `${name}-helper-text`,
        'data-testid': dataTestId ? `${dataTestId}-text-field` : undefined,
      }}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      variant="standard"
    />
  );
};

export default SimslTextField;
