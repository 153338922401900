import React from 'react';
import { Link, Stack } from '@mui/material';

import { Box, Container, Typography } from 'components';
import ErrorImg from 'components/ErrorPanel/error-img.svg';
import { RoutePath } from 'RoutePath';
import { StyledErrorImage } from 'NotFound.styles';

const NotFound: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ marginTop: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Stack alignItems="center" direction="row" flex={1} flexWrap="wrap" justifyContent="center">
        <StyledErrorImage alt="error" src={ErrorImg} />
        <Box>
          <Typography color="textSecondary" component="h1" variant="h4">
            404
          </Typography>
          <Typography color="textSecondary" variant="h5">
            Page not found
          </Typography>
          <Typography color="textSecondary" sx={{ marginTop: 1 }} variant="body2">
            We&apos;re sorry, the page you requested could not be found.
            <br /> Please go back to the{' '}
            <Link href={RoutePath.Home} underline="hover">
              homepage
            </Link>
            .
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default NotFound;
