import { Sort } from 'common';
import { format } from 'date-fns';

type ValueTypes = { [key: string]: number | Date | null | string | boolean | undefined | Sort };

const removeEmptyValues = (obj: ValueTypes) =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== '' && value !== null && value !== undefined)
      .map(([key, value]) => {
        if (value instanceof Date) {
          return [key, format(value, "yyyy-MM-dd'T'HH:mm:ss")];
        }

        return [key, String(value)];
      })
  );

const queryString = (query: ValueTypes) => {
  const params = new URLSearchParams(removeEmptyValues(query));

  return params.toString();
};

export default queryString;
