import { SimslRoute } from 'common/model';

const helpSectionIdentifiers = {
  activeBusinessContacts: 'activeBusinessContacts',
  newBusinessContact: 'newBusinessContacts',
  dormantBusinessContacts: 'dormantBusinessContacts',
  editBusinessContact: 'editBusinessContact',
  activeBusinessContactDetails: 'activeBusinessContactDetails',
  dormantBusinessContactDetails: 'dormantBusinessContactDetails',
};
export default abstract class Routes {
  static ActiveBusinessContacts: SimslRoute = {
    name: 'Active',
    path: `active`,
    helpSection: helpSectionIdentifiers.activeBusinessContacts,
  };

  static ActiveBusinessContactDetails: SimslRoute = {
    name: 'Active',
    path: `:id`,
    helpSection: helpSectionIdentifiers.activeBusinessContactDetails,
  };

  static EditBusinessContact: SimslRoute = {
    name: 'Active',
    path: `:id`,
    helpSection: helpSectionIdentifiers.editBusinessContact,
  };

  static NewBusinessContact: SimslRoute = {
    name: 'New',
    path: `new`,
    helpSection: helpSectionIdentifiers.newBusinessContact,
  };

  static DormantBusinessContacts: SimslRoute = {
    name: 'Dormant',
    path: `dormant`,
    helpSection: helpSectionIdentifiers.dormantBusinessContacts,
  };

  static DormantBusinessContactDetails: SimslRoute = {
    name: 'Dormant',
    path: `:id`,
    helpSection: helpSectionIdentifiers.dormantBusinessContactDetails,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
