import { CheckStatus, ObjectType } from 'submissions/components/SubmissionsHeader/model';
import axios, { Canceler, CancelTokenSource } from 'axios';
import { YACHT_CODE } from 'common/const';

import {
  CoreAppsUserThin,
  Location,
  Currency,
  Country,
  VesselType,
  OtherClub,
  ClassSociety,
  ImoMissingReason,
  VesselTypeGroup,
  SubmissionStatus,
  TradingArea,
  Broker,
  NoOrderReason,
  SurveyPaymentMethod,
  YachtType,
  BusinessType,
  PolicyGroup,
  SubCoverTypeGroup,
  SubCoverTypeSuffix,
  NonStandardRiskType,
  DictionaryData,
  SsmClub,
  SubmissionDocumentType,
  BrokerageType,
} from 'common/model';
import { objectTypes } from 'submissions/components/SubmissionsHeader/const';
import { SubmissionApprovalResult } from 'submissions/components/AuthorisationTab/model';
import { brokerageTypes } from 'submissions/components/CoversTab/const';
import { SubmissionStatusName, submissionSpecialStatuses } from 'submissions/model';
import { Application, TaskItemStatus, TaskType } from 'tasks/model';

const DictionariesService = {
  getLPDHandlers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();

    const getRequest = axios
      .get<CoreAppsUserThin[]>('/v2/users?type=lpd-handler', {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getUnderwriters: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();

    const getRequest = axios
      .get<CoreAppsUserThin[]>('/v2/users?type=underwriter', {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getCountries: (shouldFilterAvailable: boolean = false): [Promise<Country[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<Country[]>('/v2/countries', {
        cancelToken: cancel.token,
      })
      .then(response => {
        if (shouldFilterAvailable) {
          return response.data.filter(item => item.available);
        }
        return response.data;
      });
    return [getRequest, cancel];
  },
  getLocations: (name: string, shouldFilterAvailable: boolean = false): [Promise<Location[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<Location[]>(`/v2/places?nameFilter=${name}`, {
        cancelToken: cancel.token,
      })
      .then(response => {
        if (shouldFilterAvailable) {
          return response.data.filter(item => item.available);
        }
        return response.data;
      });
    return [getRequest, cancel.cancel];
  },
  getAvailableCurrencies: (): [Promise<Currency[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<Currency[]>('/v2/currencies', {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.available));
    return [getRequest, cancel.cancel];
  },
  getUnderwritingManagers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CoreAppsUserThin[]>(`/v2/users?type=underwriting-manager`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getSubmissionAuthorisers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CoreAppsUserThin[]>(`/v2/users?type=submission-authoriser`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getSubmissionHeadAuthorisers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CoreAppsUserThin[]>(`/v2/users?type=submission-head-authoriser`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getSmuaeAuthorisers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CoreAppsUserThin[]>(`/v2/users?type=submission-smuae-authoriser`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getAllUsers: (): [Promise<CoreAppsUserThin[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CoreAppsUserThin[]>(`/v2/users`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
  getAvailableVesselTypes: (): [Promise<VesselType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: VesselType[] }>(`/v2/vessels/types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.isAvailable));

    return [getRequest, cancel];
  },
  getAvailableVesselTypeGroups: (): [Promise<VesselTypeGroup[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: VesselTypeGroup[] }>(`/v2/vessels/type-groups`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.isAvailable));

    return [getRequest, cancel];
  },
  getAvailableClubs: (): [Promise<OtherClub[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: OtherClub[] }>(`/v2/other-clubs`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.isAvailable));

    return [getRequest, cancel];
  },
  getAvailableClassSocieties: (): [Promise<ClassSociety[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: ClassSociety[] }>(`/v2/class-societies`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.isAvailable));

    return [getRequest, cancel.cancel];
  },
  getAvailableIMOMissingReasons: (): [Promise<ImoMissingReason[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ groups: ImoMissingReason[] }>(`/v2/imo-missing-reasons`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.groups.filter(item => item.isAvailable));

    return [getRequest, cancel];
  },
  getAvailableVesselTypeByCode: (groupCode: string, skipYachts: boolean = false): [Promise<VesselType[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ vesselTypes: VesselType[] }>(`/v2/vessels/type-groups/${groupCode}/types`, {
        cancelToken: cancel.token,
      })
      .then(response =>
        response.data.vesselTypes.filter(item => (skipYachts ? item.code !== YACHT_CODE && item.isAvailable : item.isAvailable)),
      );

    return [getRequest, cancel.cancel];
  },
  getAvailableSubmissionStatuses: (): [Promise<SubmissionStatus[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubmissionStatus[]>(`/v2/submission-statuses`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.statusAvailable));

    return [getRequest, cancel];
  },
  getSubmissionDocumentTypes: (): [Promise<SubmissionDocumentType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubmissionDocumentType[]>(`/v2/submission-document-types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.submissionDocumentTypeAvailable));

    return [getRequest, cancel];
  },
  getAvailableSubmissionCheckStatuses: (): [Promise<CheckStatus[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<CheckStatus[]>(`/v2/check-statuses`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.checkStatusAvailable));

    return [getRequest, cancel];
  },
  getAvailableSubmissionApprovalResults: (): [Promise<SubmissionApprovalResult[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubmissionApprovalResult[]>(`/v2/approver-results`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.approverResultAvailable));

    return [getRequest, cancel];
  },
  getManuallyApplicableSubmissionStatusesForGivenSubmission: (submissionId: number): [Promise<SubmissionStatus[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubmissionStatus[]>(`/v2/submission-statuses`, {
        cancelToken: cancel.token,
        params: { submissionId, manuallyApplicableOnly: true },
      })
      .then(response => response.data.filter(item => item.statusAvailable));

    return [getRequest, cancel.cancel];
  },
  getAvailableTradingAreas: (): [Promise<TradingArea[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<TradingArea[]>(`/v2/trading-areas`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.available));

    return [getRequest, cancel];
  },
  getAvailableBrokers: (): [Promise<Broker[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const maxIntAcceptedByWAFValue = 2147483646;
    const getRequest = axios
      .get<{ items: Broker[] }>(`/v2/brokers?pageSize=${maxIntAcceptedByWAFValue}`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.brokerAvailable));

    return [getRequest, cancel.cancel];
  },
  getAvailableNoOrderReason: (): [Promise<NoOrderReason[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<NoOrderReason[]>(`/v2/no-order-reasons`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(x => x.noOrderReasonAvailable));

    return [getRequest, cancel];
  },
  getSurveyPaymentMethods: (): [Promise<SurveyPaymentMethod[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: SurveyPaymentMethod[] }>('/v2/survey-payment-methods', {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(x => x.isAvailable));
    return [getRequest, cancel];
  },
  getAvailableYachtTypes: (): [Promise<YachtType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<{ items: YachtType[] }>(`/v2/vessels/yacht-types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.items.filter(item => item.isAvailable));

    return [getRequest, cancel];
  },
  getAvailableTypesOfBusiness: (): [Promise<BusinessType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<BusinessType[]>(`/v2/types-of-business`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.available));

    return [getRequest, cancel];
  },
  getAvailablePolicyGroups: (): [Promise<PolicyGroup[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<PolicyGroup[]>(`/v2/policy-groups`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.available));

    return [getRequest, cancel];
  },
  getAvailableSubCoverGroups: (policyGroupCode: string): [Promise<SubCoverTypeGroup[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubCoverTypeGroup[]>(`/v2/policy-groups/${policyGroupCode}/sub-cover-type-groups`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));

    return [getRequest, cancel.cancel];
  },
  getAvailableSubCoverSuffixes: (policyGroupCode: string, subCoverTypeGroupCode: string): [Promise<SubCoverTypeSuffix[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SubCoverTypeSuffix[]>(`/v2/policy-groups/${policyGroupCode}/sub-cover-type-groups/${subCoverTypeGroupCode}/sub-cover-types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));

    return [getRequest, cancel.cancel];
  },
  getNonStandardRiskTypes: (): [Promise<NonStandardRiskType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<NonStandardRiskType[]>(`/v2/non-standard-risk-types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel];
  },
  getDeductibleCategories: (): [Promise<DictionaryData[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<DictionaryData[]>(`/v2/deductible-categories`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));
    return [getRequest, cancel.cancel];
  },
  getDeductibleConditions: (): [Promise<DictionaryData[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<DictionaryData[]>(`/v2/deductible-conditions`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));
    return [getRequest, cancel.cancel];
  },
  getAvailableAssuredCapacities: (): [Promise<DictionaryData[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<DictionaryData[]>(`/v2/assured-capacities`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));
    return [getRequest, cancel.cancel];
  },
  getAvailableNatureOfAssureds: (): [Promise<DictionaryData[]>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<DictionaryData[]>(`/v2/nature-of-assureds`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));
    return [getRequest, cancel.cancel];
  },
  getAvailableSsmClubs: (): [Promise<SsmClub[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<SsmClub[]>(`/v2/ssm-clubs`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data.filter(item => item.isAvailable));
    return [getRequest, cancel];
  },
  getInsurableObjectTypes: (): [Promise<ObjectType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = Promise.resolve(objectTypes);
    return [getRequest, cancel];
  },
  getBrokerageTypes: (): [Promise<BrokerageType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = Promise.resolve(brokerageTypes);
    return [getRequest, cancel];
  },
  getSubmissionCoverStatuses: (): [Promise<SubmissionStatusName[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = Promise.resolve(submissionSpecialStatuses);
    return [getRequest, cancel];
  },
  getTaskApplications: (): [Promise<Application[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<Application[]>(`/v2/task-applications`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel];
  },
  getTaskItemStatuses: (): [Promise<TaskItemStatus[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<TaskItemStatus[]>(`/v2/task-item-statuses`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel];
  },
  getTaskTypes: (): [Promise<TaskType[]>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<TaskType[]>(`/v2/task-types`, {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel];
  },
};

export default DictionariesService;
