/* eslint-disable scanjs-rules/identifier_sessionStorage */
import { useEffect, useState } from 'react';
import isNil from 'lodash.isnil';
import { useLocalStorage } from 'utilities/localStorage';

const storageKeyPrefix = 'expandable-';

const useExpandableSection = (defaultExpanded: boolean, sessionStorageKey?: string, storageKey?: string) => {
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const [expanded, setExpanded] = useState<boolean>(() => {
    if (sessionStorageKey) {
      const value = sessionStorage.getItem(`${sessionStorageKey}`);
      const isExpanded = value ? JSON.parse(value)?.[`${storageKeyPrefix}${storageKey}`] : null;
      return isNil(isExpanded) ? defaultExpanded : isExpanded;
    }
    if (storageKey && !sessionStorageKey) {
      const value = getStorageItem(`${storageKeyPrefix}${storageKey}`);
      return value ? JSON.parse(value) : defaultExpanded;
    }
    return defaultExpanded;
  });

  useEffect(() => {
    if (sessionStorageKey) {
      const value = sessionStorage.getItem(`${sessionStorageKey}`);
      const parsedValue = value ? JSON.parse(value) : null;
      sessionStorage.setItem(
        `${sessionStorageKey}`,
        JSON.stringify({
          ...parsedValue,
          [`${storageKeyPrefix}${storageKey}`]: expanded,
        }),
      );
    }
    if (storageKey && !sessionStorageKey) {
      setStorageItem(`${storageKeyPrefix}${storageKey}`, JSON.stringify(expanded));
    }
  }, [storageKey, expanded, sessionStorageKey, setStorageItem]);

  return {
    expanded,
    setExpanded,
  };
};

export default useExpandableSection;
