import { Box, FormHelperText, MenuItem, SelectProps, Tooltip } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import { AsyncStatus } from 'common';
import { useSnackbar } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { useCancelToken, useMountEffect, useOnMouseDown } from 'utilities/hooks';
import { SelectOption } from './model';
import { StyledCircularProgress, StyledFormControl, StyledLabelTypography, StyledMenuItem, StyledSelect } from './SimslSelectAsync.styles';

type SimslSelectAsyncProps<T> = {
  onDataFetched?: (data: T[]) => void;
  apiRequest: () => [Promise<T[]>, CancelTokenSource];
  fetchOnInit?: boolean;
  errorMessage?: string;
  helperText?: string;
  value?: string;
  disabled?: boolean;
  withTooltip?: boolean;
  showTooltipLength?: number;
  mapCallback: (item: T) => SelectOption;
  label?: string;
  withBlankOption?: boolean;
  blankOptionLabel?: string;
  unavailableOption?: SelectOption | null;
  renderOption?: (option: SelectOption) => React.ReactNode;
  selectedOptionFinder?: (options: SelectOption[], value: string) => SelectOption;
  placeholder?: string;
  labelClassName?: string;
  customLabelWidth?: string | number;
  customInputWidth?: string | number;
  customMenuItemsHeight?: string;
  customMenuItemsWidth?: string;
  shouldDropdownOpen?: boolean;
  dataTestId?: string;
} & Omit<SelectProps, 'variant'>;

function SimslSelectAsync<T>(props: SimslSelectAsyncProps<T>) {
  const {
    apiRequest,
    mapCallback,
    errorMessage,
    helperText,
    placeholder,
    label,
    withTooltip = false,
    showTooltipLength = 20,
    value = '',
    fetchOnInit = false,
    customLabelWidth = 120,
    customInputWidth = '100%',
    customMenuItemsHeight,
    customMenuItemsWidth = 'inherit',
    labelClassName,
    renderOption = (option: SelectOption, index: number, dataTestId?: string) => (
      <StyledMenuItem
        key={option.id}
        data-testid={dataTestId ? `${dataTestId}-menu-item-${index}` : undefined}
        disabled={isOptionDisabled(option)}
        value={option.id}
      >
        {option.name}
      </StyledMenuItem>
    ),
    selectedOptionFinder = (options: SelectOption[], selectedValue: string) => options.filter(itm => itm.id === selectedValue)[0],
    unavailableOption,
    withBlankOption = false,
    blankOptionLabel = '',
    onDataFetched = () => {},
    shouldDropdownOpen = true,
    onChange,
    dataTestId,
    disabled,
    ...rest
  } = props;
  const onMouseDown = useOnMouseDown();

  const isOptionDisabled = useCallback(
    (option: SelectOption | null | undefined) => (option ? option?.id === unavailableOption?.id : false),
    [unavailableOption],
  );

  const [isDisabled, setIsDisabled] = useState<boolean>(() => unavailableOption?.id === value);

  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [options, setOptions] = useState<SelectOption[]>([]);
  const isLoading = status === 'fetching';
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const { setCancelTokenSource, canceler } = useCancelToken();

  useMountEffect(() => {
    if (fetchOnInit) {
      fetchOptions();
    }
  });

  const fetchOptions = useCallback(() => {
    setStatus('fetching');
    const [request, cancel] = apiRequest();
    setCancelTokenSource(cancel);

    request
      .then(data => {
        const selectOptions = data.map(mapCallback);
        setOptions(unavailableOption ? [{ id: unavailableOption.id, name: unavailableOption.name }, ...selectOptions] : selectOptions);
        setOptions(oldOptions => (withBlankOption ? [{ id: '', name: blankOptionLabel }, ...oldOptions] : oldOptions));
        onDataFetched(data || []);
      })
      .catch(fetchError => {
        if (!axios.isCancel(fetchError)) {
          const defaultMessage = errorMessage || 'Error occurred during select options data fetch.';
          enqueueSnackbar(fetchError.Message || defaultMessage, 'error');
        }
      })
      .finally(() => {
        if (!canceler.current?.token?.reason) setStatus('resolved');
      });
  }, [
    enqueueSnackbar,
    apiRequest,
    blankOptionLabel,
    errorMessage,
    mapCallback,
    onDataFetched,
    withBlankOption,
    unavailableOption,
    setCancelTokenSource,
    canceler,
  ]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setOpenDropdown(shouldDropdownOpen);
    if (!options.length) {
      fetchOptions();
    }
  }, [options.length, fetchOptions, shouldDropdownOpen]);

  const getValue = useCallback(() => {
    if (status === 'idle' || status === 'fetching') {
      return '';
    }

    if (value === null) {
      return '';
    }

    return value;
  }, [value, status]);

  const getRenderValue = useCallback(() => {
    if (value || isLoading) {
      return undefined;
    }

    return () => placeholder || blankOptionLabel;
  }, [value, isLoading, placeholder, blankOptionLabel]);

  const getSelectedOption = useMemo(() => {
    return selectedOptionFinder(options, value);
  }, [value, options, selectedOptionFinder]);

  return (
    <Box alignItems="baseline" display="flex">
      {label && (
        <StyledLabelTypography
          align="left"
          className={labelClassName}
          color="textSecondary"
          customLabelWidth={customLabelWidth}
          display="block"
          variant="caption"
        >
          {label}
        </StyledLabelTypography>
      )}
      <Tooltip
        data-testid={dataTestId ? `${dataTestId}-tooltip` : undefined}
        open={!open && showTooltip && getSelectedOption?.name.length > showTooltipLength}
        title={getSelectedOption?.name || ''}
      >
        <StyledFormControl customInputWidth={customInputWidth} variant="standard">
          {isLoading && <StyledCircularProgress aria-label="loading" color="inherit" size={20} />}
          <StyledSelect
            {...rest}
            className={rest.className}
            disabled={isLoading || disabled}
            displayEmpty
            id={rest.name}
            inputProps={{
              ...rest.inputProps,
              'data-testid': dataTestId ? `${dataTestId}-select` : undefined,
            }}
            isEmptyValue={!value}
            isUnavailableOption={isDisabled}
            MenuProps={{
              PaperProps: {
                style: {
                  height: options.length > 0 ? customMenuItemsHeight : '',
                  width: customMenuItemsWidth,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            }}
            onChange={(e: any, v: any) => {
              if (onChange) onChange(e, v);
              setIsDisabled(e.target.value === unavailableOption?.id);
            }}
            onClose={() => {
              setShowTooltip(false);
              setOpen(false);
              setOpenDropdown(false);
            }}
            onMouseDown={rest.onMouseDown ?? onMouseDown}
            onMouseEnter={() => {
              if (withTooltip) {
                setShowTooltip(true);
              }
            }}
            onMouseLeave={() => setShowTooltip(false)}
            onOpen={handleOpen}
            open={openDropdown}
            placeholder={placeholder}
            renderValue={getRenderValue()}
            SelectDisplayProps={{
              ...rest.SelectDisplayProps,
              // @ts-ignore
              'data-testid': `${dataTestId}-select-menu`,
            }}
            value={getValue()}
            variant="standard"
          >
            {options.length === 0 && !isLoading && <MenuItem disabled>No options</MenuItem>}
            {options.map((option, index) => renderOption(option, index, dataTestId))}
          </StyledSelect>
          {helperText && (
            <FormHelperText data-testid={dataTestId ? `${dataTestId}-helper-text` : undefined} error>
              {helperText}
            </FormHelperText>
          )}
        </StyledFormControl>
      </Tooltip>
    </Box>
  );
}

export default SimslSelectAsync;
