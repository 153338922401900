import React from 'react';
import { StyledOverlayDiv, StyledSpinnerDiv } from './Spinner.styles';

type SpinnerProps = {
  overlay?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({ overlay }) => {
  return (
    <StyledOverlayDiv aria-label="spinner" overlay={overlay}>
      <StyledSpinnerDiv>
        <div />
        <div />
        <div />
        <div />
      </StyledSpinnerDiv>
    </StyledOverlayDiv>
  );
};

export default Spinner;
