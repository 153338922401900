import React from 'react';
import sanitize, { IOptions } from 'sanitize-html';
import { SxProps, Theme } from '@mui/material';
import { StyledDiv } from './styles';

type SimslHtmlViewerProps = {
  allowedHtmlTags: string[] | false | null;
  htmlContent: string;
  maxHeight?: string;
  sx?: SxProps<Theme>;
};

const SimslHtmlViewer: React.FC<SimslHtmlViewerProps> = ({ allowedHtmlTags, sx, htmlContent, maxHeight = 'inherit' }) => {
  const sanitizationOptions: IOptions = {
    allowedTags: allowedHtmlTags ?? undefined,
    allowedAttributes: false,
  };

  const sanitizedContent = sanitize(htmlContent, sanitizationOptions);

  return (
    <StyledDiv
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizedContent,
      }}
      maxHeight={maxHeight}
      sx={sx}
    />
  );
};

export default SimslHtmlViewer;
