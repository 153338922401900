import { SimslRoute, SimslRouteWithUrl } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  vessels: 'vessels',
  vesselDetails: 'vesselDetails',
  newVessel: 'newVessel',
  editVessel: 'editVessel',
  crews: 'crews',
  crewDetails: 'crewDetails',
  newCrew: 'newCrew',
  editCrew: 'editCrew',
  fleets: 'fleets',
  fleetDetails: 'fleetDetails',
  newFleet: 'newFleet',
  editFleet: 'editFleet',
};

export default abstract class RoutePath {
  static Vessels: SimslRoute = {
    name: 'Vessels',
    path: `vessels`,
    helpSection: helpSectionIdentifiers.vessels,
  };

  static VesselDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/vessels/${id}`,
    helpSection: helpSectionIdentifiers.vesselDetails,
  };

  static EditVessel: SimslRouteWithUrl = {
    name: 'Edit',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/vessels/${id}`,
    helpSection: helpSectionIdentifiers.editVessel,
  };

  static AddNewVessels: SimslRoute = {
    name: 'Vessels',
    path: `new`,
    helpSection: helpSectionIdentifiers.newVessel,
  };

  static Crews: SimslRoute = {
    name: 'Crews',
    path: `crews`,
    helpSection: helpSectionIdentifiers.crews,
  };

  static EditCrew: SimslRouteWithUrl = {
    name: 'Edit',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/crews/${id}`,
    helpSection: helpSectionIdentifiers.editCrew,
  };

  static CrewDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/crews/${id}`,
    helpSection: helpSectionIdentifiers.crewDetails,
  };

  static AddNewCrews: SimslRoute = {
    name: 'Crews',
    path: `new`,
    helpSection: helpSectionIdentifiers.newCrew,
  };

  static Fleets: SimslRoute = {
    name: 'Fleets',
    path: `fleets`,
    helpSection: helpSectionIdentifiers.fleets,
  };

  static EditFleet: SimslRouteWithUrl = {
    name: 'Edit',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/fleets/${id}`,
    helpSection: helpSectionIdentifiers.editFleet,
  };

  static FleetDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.ObjectsModule}/fleets/${id}`,
    helpSection: helpSectionIdentifiers.fleetDetails,
  };

  static AddNewFleets: SimslRoute = {
    name: 'Fleets',
    path: `new`,
    helpSection: helpSectionIdentifiers.newFleet,
  };

  static VesselsApiNotAvailable: SimslRoute = {
    name: '',
    path: `vessels/error`,
  };

  static CrewsApiNotAvailable: SimslRoute = {
    name: '',
    path: `crews/error`,
  };

  static FleetsApiNotAvailable: SimslRoute = {
    name: '',
    path: `fleets/error`,
  };

  static ApiNotAvailable: SimslRouteWithUrl = {
    name: 'Error',
    path: `error`,
    url: (pathname: string) => {
      if (pathname.includes(RoutePath.Vessels.path)) {
        return RoutePath.VesselsApiNotAvailable.path;
      }

      if (pathname.includes(RoutePath.Crews.path)) {
        return RoutePath.CrewsApiNotAvailable.path;
      }

      if (pathname.includes(RoutePath.Fleets.path)) {
        return RoutePath.FleetsApiNotAvailable.path;
      }

      return `error`;
    },
  };
}
