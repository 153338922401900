import React from 'react';
import { ActionButton } from 'components/Button';
import { SidePanelHeader } from 'components';

import { StyledBox } from './SidePanelFormHeader.styles';

export type SidePanelFormHeaderProps = {
  children: React.ReactNode;
  onCancel: () => void;
  onClose: () => void;
  onSave: () => void;
  isSubmitting?: boolean;
};

const SidePanelFormHeader: React.FC<SidePanelFormHeaderProps> = ({ onCancel, onClose, isSubmitting = false, onSave, children }) => {
  return (
    <SidePanelHeader onCloseClicked={onClose}>
      <StyledBox>
        {children}
        <ActionButton actionType="save" dataTestId="save" disabled={isSubmitting} onClick={onSave} />
        <ActionButton actionType="cancel" dataTestId="cancel" disabled={isSubmitting} onClick={onCancel} />
      </StyledBox>
    </SidePanelHeader>
  );
};

export default SidePanelFormHeader;
