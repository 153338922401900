import React, { useCallback } from 'react';
import { Box, BoxProps } from '@mui/material';
import { ExpandMoreIcon } from 'icons';
import { useExpandableSection } from './hooks';
import {
  StyledCollapse,
  StyledExpandIcon,
  StyledHeadingBox,
  StyledIconTitleTypography,
  StyledRootBox,
  StyledTitleTypography,
} from './ExpandableSection.styles';

type ExpandableSectionProps = BoxProps & {
  disabled?: boolean;
  title?: string;
  warning?: string;
  onExpanded?: (isExpanded: boolean) => void;
  storageKey?: string;
  unmountOnExit?: boolean;
  className?: string;
  withBorder?: boolean;
  defaultExpanded?: boolean;
  displayIconTitle?: boolean;
  sessionStorageKey?: string;
};

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  disabled,
  title = '',
  warning = '',
  storageKey,
  children,
  unmountOnExit,
  className,
  onExpanded,
  displayIconTitle = false,
  withBorder = true,
  defaultExpanded = true,
  sessionStorageKey,
  ...props
}) => {
  const { expanded, setExpanded } = useExpandableSection(defaultExpanded, sessionStorageKey, storageKey);
  const handleExpandClick = useCallback(() => {
    setExpanded(prev => {
      if (onExpanded) {
        onExpanded(!prev);
      }

      return !prev;
    });
  }, [onExpanded, setExpanded]);

  return (
    <StyledRootBox {...props} className={className} disabled={disabled} withBorder={withBorder}>
      <StyledHeadingBox alignItems="center" display="flex" justifyContent="space-between" onClick={handleExpandClick}>
        <StyledTitleTypography color="primary" variant="caption">
          {title}
          {warning && (
            <>
              <span> - </span>
              <span>{warning}</span>
            </>
          )}
        </StyledTitleTypography>
        <Box alignItems="center" display="flex">
          {displayIconTitle && (
            <StyledIconTitleTypography color="primary" variant="caption">
              {expanded ? 'Show Less' : 'Show More'}
            </StyledIconTitleTypography>
          )}
          <StyledExpandIcon aria-expanded={expanded} aria-label="show more" expanded={expanded}>
            <ExpandMoreIcon />
          </StyledExpandIcon>
        </Box>
      </StyledHeadingBox>
      <StyledCollapse expanded={expanded} in={expanded} timeout="auto" unmountOnExit={unmountOnExit ?? true}>
        {children}
      </StyledCollapse>
    </StyledRootBox>
  );
};

export default ExpandableSection;
