import { styled, Box, Container } from 'components';

export const StyledRootBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
}));

export const StyledMessageContentBox = styled(Box)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  '& > *:not(first-of-type)': {
    marginTop: 15,
  },
}));

export const StyledErrorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(5),
}));

export const StyledImageBox = styled(Box)(() => ({
  flexGrow: 1,
  flexShrink: 1,
  minWidth: '300px',
  maxWidth: '450px',
}));
