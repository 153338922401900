import { ButtonClasses, Theme } from '@mui/material';
/* eslint-disable no-restricted-imports */
import type { OverridesStyleRules } from '@mui/material/styles/overrides';

export const buttonStyles: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton', Omit<Theme, 'components'>>> | undefined = {
  root: ({ ownerState, theme }) => ({
    textTransform: 'uppercase',
    ...(ownerState.size === 'small' && {
      padding: theme.spacing(0.75, 1.75),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.25, 0.75),
      },
    }),
    ...(ownerState.size === 'medium' && {
      padding: theme.spacing(1, 1.75),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 0.75),
      },
    }),
  }),
  startIcon: {
    '& > svg:first-of-type': {
      fontSize: '15px',
    },
  },
};
