import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DialogProps, DialogTitle, DialogContent, Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';

import Typography from '../Typography';
import { StyledDialog } from './GenericDialog.styles';

export type GenericDialogProps = DialogProps & {
  title?: string;
  open: boolean;
  onClose: (result: boolean, reason?: string) => void;
  actions?: React.ReactNode;
  childClassName?: string;
  isBackdropClickDisabled?: boolean;
  isEscapeKeyDownDisabled?: boolean;
  draggable?: boolean;
  dataTestId?: string;
};

const GenericDialog: React.FC<GenericDialogProps> = ({
  children,
  childClassName,
  actions,
  onClose,
  open,
  title,
  classes,
  isBackdropClickDisabled = false,
  isEscapeKeyDownDisabled = false,
  draggable = false,
  dataTestId,
  ...props
}) => {
  const handleClose = useCallback(
    (e: any, reason: string) => {
      if (reason === 'escapeKeyDown' && isEscapeKeyDownDisabled) return;
      if (reason === 'backdropClick' && isBackdropClickDisabled) return;
      onClose(e, reason);
    },
    [onClose, isBackdropClickDisabled, isEscapeKeyDownDisabled],
  );

  return (
    <StyledDialog
      {...props}
      aria-labelledby={draggable ? 'draggable-dialog-title' : 'dialog-title'}
      data-testid={dataTestId ? `${dataTestId}-dialog` : undefined}
      onClose={handleClose}
      open={open}
      PaperComponent={draggable ? DraggablePaperComponent : undefined}
    >
      {title && (
        <DialogTitle
          className="draggable-dialog"
          id={draggable ? 'draggable-dialog-title' : 'dialog-title'}
          sx={{ cursor: draggable ? 'move' : 'inherit' }}
        >
          <Typography color="primary" component="span" variant="h5">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent className={childClassName} dividers>
        <Typography color="textPrimary" component="span" variant="body1">
          {children}
        </Typography>
      </DialogContent>
      {actions}
    </StyledDialog>
  );
};

const DraggablePaperComponent: React.FC<PaperProps> = ({ ...props }) => {
  const draggableDialog = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (draggableDialog?.current === null) return undefined;

    const observerCallback = (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        if (draggableDialog.current) {
          setWidth(draggableDialog.current.offsetWidth);
          setHeight(draggableDialog.current.offsetHeight);
        }
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver.observe(draggableDialog.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: `calc(100vw + 1.9 * ${width}px)`, // 1.9 so we can hide dialog left/right but not completely
        height: `calc(100vh + 1.9 * ${height}px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Draggable bounds="parent" cancel={'[class*="MuiDialogContent-root"]'} handle=".draggable-dialog">
        <Paper ref={draggableDialog} {...props} style={{ margin: 0 }} />
      </Draggable>
    </div>
  );
};

export default GenericDialog;
