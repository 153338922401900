import { TextFieldProps } from '@mui/material';

import React, { ChangeEvent, KeyboardEvent, useCallback, useState, useEffect, useRef, SyntheticEvent } from 'react';
import { useOnMouseDown } from 'utilities/hooks';
import { getCommaSeparatedValue } from 'components/helpers/ComponentHelper';
import { britishNumberFormatSeparator } from 'utilities/misc/NumberHelper';
import { StyledTextField } from './SimslNumberTextField.styles';

type SimslNumberTextFieldProps = TextFieldProps & {
  allowLeadingZero?: boolean;
  commaSeparator?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  inputRef?: React.Ref<any>;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
  min?: number;
  max?: number;
  step?: string;
  value?: number | string | null;
  className?: string;
  isDecimal?: boolean;
  hideArrows?: boolean;
  maxNumberLength?: number;
  dataTestId?: string;
};

const SimslNumberTextField: React.FC<SimslNumberTextFieldProps> = ({
  allowLeadingZero = true,
  commaSeparator = false,
  error,
  helperText,
  inputRef,
  max,
  min,
  step,
  name,
  onChange,
  placeholder,
  value,
  className,
  inputProps,
  maxNumberLength,
  hideArrows = false,
  isDecimal = false,
  dataTestId,
  ...rest
}) => {
  const onMouseDown = useOnMouseDown();

  const getFormattedValue = useCallback(
    (pureValue: string | number | undefined | null) => {
      return commaSeparator ? getCommaSeparatedValue(pureValue) : pureValue;
    },
    [commaSeparator],
  );

  const [cursorPositionChanged, setCursorPositionChanged] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string | number | null | undefined>(getFormattedValue(value));
  const [selection, setSelection] = useState<string>('');

  const inputRefCommaSeparated = useRef<HTMLInputElement>(null);
  const cursorPos = useRef<number | null>(formattedValue?.toString().length ?? null);

  if (!formattedValue && value) {
    // possible when value is pre-populated
    setFormattedValue(value ? getFormattedValue(value) : value);
  }

  useEffect(() => {
    if (!commaSeparator) return;
    if (inputRefCommaSeparated?.current === null) return;
    if ((inputRef as React.RefObject<HTMLInputElement>)?.current) return;

    inputRefCommaSeparated.current?.setSelectionRange(cursorPos.current, cursorPos.current);
    setCursorPositionChanged(false);
  }, [formattedValue, commaSeparator, inputRef, cursorPositionChanged]);

  // Note: this strange verification can be replaced with proper component when it is available: https://github.com/mui-org/material-ui/issues/19154
  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (hideArrows && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
        event.preventDefault();
        return;
      }

      if (commaSeparator && (event.key === 'Delete' || event.key === 'Backspace')) {
        const currentInput = inputRefCommaSeparated.current;
        if (currentInput?.selectionStart === currentInput?.selectionEnd) {
          const deletePressed = event.key === 'Delete';
          const characterBeingDeleted = deletePressed
            ? (formattedValue as string)[currentInput?.selectionStart as number]
            : (formattedValue as string)[(currentInput?.selectionStart as number) - 1];
          if (
            characterBeingDeleted === britishNumberFormatSeparator &&
            currentInput != null &&
            currentInput.selectionStart !== null &&
            currentInput.selectionEnd !== null
          ) {
            currentInput.selectionStart += deletePressed ? 1 : -1;
            currentInput.selectionEnd += deletePressed ? 1 : -1;
          }
        }

        return;
      }

      // allow backspace, delete, 4 arrows, end, home, tab, enter
      if (
        event.key === 'Backspace' ||
        event.key === 'Tab' ||
        event.key === 'Enter' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowRight' ||
        event.key === 'ArrowDown' ||
        event.key === 'End' ||
        event.key === 'Home'
      ) {
        return;
      }

      // allow minus if minimum not defined or negative
      if (event.key === '-' && (min === undefined || min < 0)) {
        return;
      }

      // allow digits
      if (event.key >= '0' && event.key <= '9') {
        if (value && maxNumberLength && value.toString().length >= maxNumberLength) {
          event.preventDefault();
        } else {
          return;
        }
      }

      if (event.key === '.' && isDecimal) {
        return;
      }

      // allow Ctrl+C, Ctrl+V, Ctrl+A, Ctrl+Insert
      if (event.ctrlKey && (event.key === 'c' || event.key === 'v' || event.key === 'a' || event.key === 'Insert')) {
        return;
      }

      // allow Shift+Ins
      if (event.shiftKey && event.key === 'Insert') {
        return;
      }

      event.preventDefault();
    },
    [commaSeparator, value, min, isDecimal, maxNumberLength, hideArrows, formattedValue],
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedValue = e.clipboardData.getData('Text');
      if (pastedValue.startsWith('00')) {
        e.preventDefault();
      }
      if (isDecimal && pastedValue === '.') {
        e.preventDefault();
      }
    },
    [isDecimal],
  );

  const handleChange = useCallback(
    (e: any) => {
      if (onChange) {
        if (!allowLeadingZero && e.target.value.startsWith('0')) {
          const valueWithoutLeadingZero = e.target.value.replace(/^0+/, '');
          onChange({ ...e, target: { valueWithoutLeadingZero } });
          setFormattedValue(valueWithoutLeadingZero);
          return;
        }

        if (e.target.value === '00') {
          onChange({ ...e, target: { value: '0' } });
          return;
        }

        onChange(e);
        setFormattedValue(getFormattedValue(e.target.value));
      }

      if (commaSeparator) {
        const newFormattedValue = getFormattedValue(e.target.value);
        const oldFormattedValueSeparators = (formattedValue as string).split(britishNumberFormatSeparator).length - 1;
        const newFormattedValueSeparators = (newFormattedValue as string).split(britishNumberFormatSeparator).length - 1;
        const separatorsInSelection = Math.max((selection as string).split(britishNumberFormatSeparator).length - 1, 0);
        const addedNewSeparators = newFormattedValueSeparators - oldFormattedValueSeparators;
        const defaultCursorPosition = inputRefCommaSeparated.current?.selectionStart ?? 0;

        const newCursorPosition = defaultCursorPosition + addedNewSeparators + separatorsInSelection;
        cursorPos.current = newCursorPosition;

        setCursorPositionChanged(true);
      }
    },
    [allowLeadingZero, commaSeparator, getFormattedValue, onChange, formattedValue, selection],
  );

  const handleSelect = useCallback(
    (e: SyntheticEvent<HTMLDivElement, Event>) => {
      if (
        commaSeparator &&
        inputRefCommaSeparated.current?.selectionStart !== inputRefCommaSeparated.current?.selectionEnd &&
        formattedValue
      ) {
        const currentSelection = (formattedValue as string).substring(
          inputRefCommaSeparated.current?.selectionStart ?? 0,
          inputRefCommaSeparated.current?.selectionEnd ?? 0,
        );
        setSelection(currentSelection);
      } else {
        setSelection('');
      }
    },
    [commaSeparator, formattedValue],
  );

  return (
    <StyledTextField
      {...rest}
      className={className}
      error={error}
      fullWidth
      helperText={helperText}
      hideArrows={hideArrows}
      id={name}
      inputProps={{
        ...inputProps,
        'aria-errormessage': `${name}-helper-text`,
        className: inputProps?.className,
        'data-testid': dataTestId ? `${dataTestId}-number-text-field` : undefined,
        min,
        max,
        step,
      }}
      inputRef={commaSeparator ? inputRefCommaSeparated : inputRef}
      name={name}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
      onMouseDown={rest.onMouseDown ?? onMouseDown}
      onPaste={handlePaste}
      onSelect={handleSelect}
      placeholder={placeholder}
      type={commaSeparator ? 'string' : 'number'}
      value={formattedValue}
      variant={rest.variant || 'standard'}
    />
  );
};

export default SimslNumberTextField;
