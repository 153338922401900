import React from 'react';
import { Checkbox, CheckboxProps, Tooltip } from '@mui/material';

type SimslDisabledCheckboxProps = {
  checked?: boolean;
  noPadding?: boolean;
  dataTestId?: string;
  infoTooltip?: string;
} & CheckboxProps;

const SimslDisabledCheckbox: React.FC<SimslDisabledCheckboxProps> = ({
  checked = false,
  noPadding = false,
  dataTestId,
  infoTooltip,
  ...rest
}) => {
  const checkbox = (
    <Checkbox
      {...rest}
      checked={checked}
      data-testid={dataTestId ? `${dataTestId}-checkbox-value` : undefined}
      disabled
      size="small"
      sx={{ padding: noPadding ? 0 : theme => theme.spacing(1) }}
    />
  );

  if (infoTooltip) {
    return (
      <Tooltip title={infoTooltip}>
        <div>{checkbox}</div>
      </Tooltip>
    );
  }

  return checkbox;
};

export default SimslDisabledCheckbox;
