import React from 'react';

import { Container, Paper, ErrorPanel } from './components';

const ErrorPage: React.FC = () => (
  <Container maxWidth="lg" sx={{ marginTop: 3 }}>
    <Paper sx={{ padding: 7 }}>
      <ErrorPanel />
    </Paper>
  </Container>
);

export default ErrorPage;
