import { styled, TablePagination } from 'components';

export const StyledTablePagination = styled(TablePagination)<{ component: string }>(({ theme }) => ({
  '& .MuiToolbar-root': {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  '& .MuiTablePagination-select': {
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  '& .MuiTablePagination-actions': {
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
}));
