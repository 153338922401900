import React from 'react';
import { CheckboxProps, Checkbox } from '@mui/material';
import { CheckBoxOutlineBlankOutlined } from 'icons';

export type SimslCheckboxProps = CheckboxProps & {
  dataTestId?: string;
};

const SimslCheckbox: React.FC<SimslCheckboxProps> = ({ dataTestId, ...props }) => {
  return (
    <Checkbox
      {...props}
      color="primary"
      data-testid={dataTestId ? `${dataTestId}-checkbox` : undefined}
      icon={<CheckBoxOutlineBlankOutlined color="primary" />}
      size="small"
      sx={{ padding: 0 }}
    />
  );
};

export default SimslCheckbox;
