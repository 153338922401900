import axios, { Canceler } from 'axios';
import { eTagHttpHeader } from 'common/const';
import { TaskPreference } from '../model';

let eTag: string | null = null;

const TaskPreferencesService = {
  getPreferences: (userId: number): [Promise<TaskPreference>, Canceler] => {
    const url = `/v2/preferences/${userId}`;
    const cancel = axios.CancelToken.source();
    const getRequest = axios
      .get<TaskPreference>(url, {
        cancelToken: cancel.token,
        headers: {
          skipRedirection: true,
        },
      })
      .then(response => {
        eTag = response.headers[eTagHttpHeader.responseHeaderName];
        return response.data;
      });
    return [getRequest, cancel.cancel];
  },
  patchPreferences: (userId: number, preference: TaskPreference): [Promise<string | null>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const request = axios
      .patch(
        `/v2/preferences/${userId}`,
        { ...preference },
        {
          headers: {
            [eTagHttpHeader.requestHeaderName]: eTag,
          },
        },
      )
      .then(response => response.data);
    return [request, cancel.cancel];
  },
  addPreferences: (preferences: TaskPreference): [Promise<number>, Canceler] => {
    const cancel = axios.CancelToken.source();
    const request = axios
      .post(
        '/v2/preferences',
        { ...preferences },
        {
          cancelToken: cancel.token,
        },
      )
      .then(response => {
        return response.data;
      });
    return [request, cancel.cancel];
  },
};

export default TaskPreferencesService;
