import { useCallback, useContext } from 'react';
import { SettingsContext } from 'utilities/config/settingsContext';

const useFontSize = (storageValue?: number) => {
  let currentFontSize = useContext(SettingsContext).settingsContext.settings;

  if (currentFontSize < 0) {
    currentFontSize = storageValue || 0;
  }

  const getRecalculatedFontSize = useCallback(
    (baseValue: number) => {
      return currentFontSize > 0 ? baseValue * (currentFontSize / 100) : baseValue;
    },
    [currentFontSize],
  );

  return { getRecalculatedFontSize };
};

export default useFontSize;
