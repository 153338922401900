/* eslint-disable scanjs-rules/identifier_sessionStorage */
import { getDefaultSorting } from 'utilities/misc/TableHelper';
import { RoutePath as ModulesRoutes } from 'RoutePath';
import { TaskSearchState } from 'tasks/model';
import { DEFAULT_COLUMNS, DEFAULT_FILTERS, GRID_NAME } from '../../../const';

export const getTaskGridStorageKey = () => `${GRID_NAME}_all`;

export const getDefaultFilters = () => {
  return DEFAULT_FILTERS;
};

export const getInitialTaskState = (getStorageItem: (key: string) => string): TaskSearchState => {
  const storageKey = getTaskGridStorageKey();
  const defaultFilters = getDefaultFilters();

  const storedFilters = sessionStorage.getItem(`${storageKey}Filters`);

  const storedSorting = JSON.parse(sessionStorage.getItem(`${storageKey}Sorting`)!);
  const sorting = storedSorting || getDefaultSorting(DEFAULT_COLUMNS);

  return {
    pageNumber: 0,
    pageSize: Number(getStorageItem('gridPageSize')) || 30,
    filters: storedFilters ? JSON.parse(storedFilters) : { ...defaultFilters },
    sort: { ...sorting },
  };
};

export const getTaskGridUrl = () => {
  return ModulesRoutes.TasksModule;
};
