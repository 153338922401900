import { SxProps, Theme } from '@mui/material';

import { useOnMouseDown } from 'utilities/hooks';
import SimslDatePicker, { SimslDatePickerProps } from './SimslDatePicker';
import { StyledRootBox, StyledDescriptionTypography } from './SimslLabeledDatePicker.styles';

export type SimslLabeledDatePickerProps<TDate> = SimslDatePickerProps<TDate> & {
  label: string | null;
  onMouseDown?: () => void;
  labelWidth?: number | string;
  pickerClassName?: string;
  pickerSx?: SxProps<Theme>;
};

function SimslLabeledDatePicker<TDate>(props: SimslLabeledDatePickerProps<TDate>) {
  const { inputRef, label, labelWidth = 'fit-content', name, onChange, value, pickerClassName, sx, pickerSx, ...rest } = props;
  const onMouseDown = useOnMouseDown();

  return (
    <StyledRootBox>
      {label && (
        <StyledDescriptionTypography align="left" color="textSecondary" display="block" labelWidth={labelWidth} variant="caption">
          {label}
        </StyledDescriptionTypography>
      )}

      <SimslDatePicker
        {...rest}
        className={pickerClassName}
        inputRef={inputRef}
        name={name}
        onChange={onChange}
        onMouseDown={rest.onMouseDown ?? onMouseDown}
        pickerSx={{ width: '100%', display: 'inline-flex', ...pickerSx }}
        sx={{ width: '100%', ...sx }}
        value={value}
      />
    </StyledRootBox>
  );
}

export default SimslLabeledDatePicker;
