import { useBlocker } from 'react-router-dom';
import { ConfirmationDialog } from 'components/Dialog';

type SimslConfirmProps = {
  when: boolean;
  message: string;
  cancelButtonName?: string;
  confirmButtonName?: string;
};

const SimslConfirm: React.FC<SimslConfirmProps> = ({ when, message, cancelButtonName, confirmButtonName }) => {
  const blocker = useBlocker(when);

  const handleClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }
  };

  return blocker.state === 'blocked' ? (
    <ConfirmationDialog cancelButtonName={cancelButtonName} confirmButtonName={confirmButtonName} onClose={handleClose} open>
      {message}
    </ConfirmationDialog>
  ) : null;
};

export default SimslConfirm;
