import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { textSpacesToDashFormat } from 'utilities/misc/StringHelper';
import { bindMenu, bindTrigger, Menu, MenuItem, PopupState, Tab, useTheme } from '..';
import { KeyboardArrowDownIcon } from '../../icons';
import { StyledTypography } from './DropDownTab.styles';

export type DropDownTabProps = {
  items: { displayName: string; routeTo: string; value: string }[];
  title: string;
  value: string;
  disabled?: boolean;
};

const DropDownTab: React.FC<DropDownTabProps> = ({ items, title, value, disabled }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabSelected = items.some(i => i.value === value);

  return (
    <PopupState popupId={title} variant="popover">
      {popupState => {
        return (
          <>
            <Tab
              disabled={disabled}
              label={
                <div>
                  <StyledTypography data-testid={`${textSpacesToDashFormat(title)}-tab`} selected={tabSelected} variant="h6">
                    {title}
                    <KeyboardArrowDownIcon fontSize="small" style={{ verticalAlign: 'text-bottom', marginLeft: 5 }} />
                  </StyledTypography>
                </div>
              }
              {...bindTrigger(popupState)}
              onClick={handleClick}
              style={
                tabSelected
                  ? { fontSize: theme.typography.body2.fontSize, maxWidth: 'none', textTransform: 'none', opacity: 1 }
                  : {
                      fontSize: theme.typography.body2.fontSize,
                      maxWidth: 'none',
                      textTransform: 'none',
                    }
              }
              value={value}
            />
            <Menu
              {...bindMenu(popupState)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              {items.map(item => (
                <MenuItem
                  key={item.displayName}
                  component={RouterLink}
                  data-testid={`${textSpacesToDashFormat(item.displayName)}-menu-item`}
                  onClick={handleClose}
                  sx={{ justifyContent: 'flex-end' }}
                  to={item.routeTo}
                >
                  <StyledTypography selected={item.value === value} variant="subtitle2">
                    {item.displayName}
                  </StyledTypography>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      }}
    </PopupState>
  );
};

export default DropDownTab;
