import React, { Fragment, useCallback, useState } from 'react';

import { ColumnIcon, SortDirection, Sorting, Column, CustomCell } from 'common/model';
import { SimslSvgViewer, Spinner, SimslDisabledCheckbox } from 'components';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';
import TableCellIcon from 'components/Table/TableCellIcon';
import TableHeader from 'components/Table/TableHeader';
import SimslTableCellTooltip from 'components/Table/SimslTableCellTooltip';
import SimslTableCell from 'components/Table/SimslTableCell';
import { useFontSize } from 'utilities/localStorage';
import CustomTableCell from './CustomTableCell';
import { StyledRootBox, StyledTableContainer, StyledTableRow } from './SimslTable.styles';

export type SimslTableTableProps<T> = {
  data: T[];
  columns: Column[];
  maxContainerHeight?: number | string;
  minContainerHeight?: number;
  loading?: boolean;
  dense?: boolean;
  idKeyName?: string;
  disabled?: boolean;
  gridName?: string;
  useRowsSecondaryColor?: boolean;
  innerRef?: ((instance: any) => void) | React.RefObject<any> | null | undefined;
  onSortChanged?: (sorting: Sorting) => void;
  activeSorting?: Sorting;
  onRowClicked?: (item: T) => void;
  rowSelectedId?: number | string | null;
  isSortingActive?: boolean;
  tableFontSize?: number;
  tableFontSizeUnit?: string;
  onTooltipRequest?: (item: T, propertyName: keyof T) => Promise<string>;
};

const DEFAULT_TOOLTIP_DESCRIPTION = '...';

function SimslTable<T>(props: SimslTableTableProps<T>) {
  const {
    data,
    columns,
    maxContainerHeight = 500,
    minContainerHeight = 250,
    useRowsSecondaryColor,
    loading,
    dense = false,
    disabled = false,
    onSortChanged,
    activeSorting,
    innerRef,
    onRowClicked,
    rowSelectedId,
    isSortingActive,
    tableFontSize,
    tableFontSizeUnit,
    onTooltipRequest,
    idKeyName = 'id',
    gridName = 'grid',
  } = props;

  const { getRecalculatedFontSize: getFontSize } = useFontSize();
  const [tooltipDescription, setTooltipDescription] = useState<string>(DEFAULT_TOOLTIP_DESCRIPTION);
  const [tooltipDescriptionError, setTooltipDescriptionError] = useState<string | null>(null);

  const onColumnClicked = useCallback(
    (sortKey: string) => {
      if (!onSortChanged) {
        return;
      }

      let sortDirection = SortDirection.ASCENDING;

      if (activeSorting?.sortKey === sortKey) {
        sortDirection = activeSorting?.sortDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
      }

      onSortChanged({ sortKey, sortDirection });
    },
    [activeSorting, onSortChanged],
  );

  const handleRowClick = useCallback(
    (item: T) => {
      if (disabled) {
        return;
      }

      if (onRowClicked) {
        onRowClicked(item);
      }
    },
    [onRowClicked, disabled],
  );

  const handleOnCloseTooltip = useCallback(() => {
    setTooltipDescription(DEFAULT_TOOLTIP_DESCRIPTION);
    setTooltipDescriptionError(null);
  }, []);

  const handleOnOpenTooltip = useCallback(
    async (item: T, propertyName: keyof T) => {
      if (!onTooltipRequest) return;

      onTooltipRequest(item, propertyName)
        .then((tooltip: string) => setTooltipDescription(tooltip))
        .catch(error => setTooltipDescriptionError(error?.Message || 'There was a problem with receiving tooltip message.'));
    },
    [onTooltipRequest],
  );

  const renderTableColumn = useCallback((item: T, column: Column) => {
    const keys = column.valueKey.split('.');

    const itemValue = item[column.valueKey as keyof T] ?? keys.reduce((acc: any, key: string) => acc[key], item);

    if (itemValue === null || typeof itemValue === 'undefined') {
      return null;
    }

    switch (column.type) {
      case 'icon':
        return <TableCellIcon icon={itemValue as unknown as ColumnIcon} />;
      case 'svg':
        return <SimslSvgViewer svgImage={itemValue as unknown as string} />;
      case 'checkbox':
        return <SimslDisabledCheckbox checked={itemValue as unknown as boolean} disabled noPadding />;
      case 'customStyleCell':
        return <CustomTableCell customCell={itemValue as unknown as CustomCell} />;
      default:
        return itemValue;
    }
  }, []);

  return (
    <StyledRootBox>
      <StyledTableContainer
        ref={innerRef}
        fontSize={`${tableFontSize !== undefined ? getFontSize(tableFontSize) : getFontSize(0.875)}${tableFontSizeUnit !== undefined ? tableFontSizeUnit : 'rem'}`}
        maxContainerHeight={maxContainerHeight}
        minContainerHeight={minContainerHeight}
      >
        <Table stickyHeader>
          <TableHeader
            activeSorting={activeSorting}
            columns={columns}
            dense={dense}
            isSortingActive={isSortingActive}
            onColumnClicked={onColumnClicked}
            tableFontSize={tableFontSize}
            tableFontSizeUnit={tableFontSizeUnit}
          />
          <TableBody>
            {data.map((item, rowId) => (
              <StyledTableRow
                key={`${item[idKeyName as keyof T]}`}
                clicableRow={!!onRowClicked}
                data-testid={`${gridName}-row-${rowId}`}
                hover={!!onRowClicked}
                onClick={() => handleRowClick(item)}
                selected={
                  rowSelectedId
                    ? rowSelectedId === Number(item[idKeyName as keyof T]) || rowSelectedId === String(item[idKeyName as keyof T])
                    : false
                }
                useRowsSecondaryColor={useRowsSecondaryColor}
              >
                {columns.map((column, columnId) => (
                  <Fragment key={`${item[idKeyName as keyof T]}-${column.id}`}>
                    {column.tooltip ? (
                      <SimslTableCellTooltip
                        align={column.align}
                        columnId={columnId}
                        dense={dense}
                        onCloseTooltip={handleOnCloseTooltip}
                        onOpenTooltip={() => handleOnOpenTooltip(item, column.valueKey as keyof T)}
                        style={column.style}
                        tooltipText={
                          tooltipDescriptionError ?? column.tooltipLength
                            ? tooltipDescription.substring(0, column.tooltipLength)
                            : tooltipDescription
                        }
                      >
                        {renderTableColumn(item, column) as React.ReactNode}
                      </SimslTableCellTooltip>
                    ) : (
                      <SimslTableCell align={column.align} columnId={columnId} dense={dense} gridName={gridName} style={column.style}>
                        {renderTableColumn(item, column) as React.ReactNode}
                      </SimslTableCell>
                    )}
                  </Fragment>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {loading && <Spinner />}
    </StyledRootBox>
  );
}

export default SimslTable;
