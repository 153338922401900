import { LinkProps } from 'react-router-dom';
import { styled, AppBar, Tabs, Link } from 'components';
import { Tab, TabPanel } from 'components/Tabs';

export const StyledNavAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-root': {
    backgroundColor: 'white',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '1rem',
    },
  },
  '& .MuiTabs-scrollButtonsDesktop': {
    display: 'flex',
  },
}));

export const StyledTabs = styled(Tabs)(() => ({}));

export const StyledTab = styled(Tab, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'uppercase',
  width: '40%',
  ...(isSelected && {
    fontWeight: Number(theme.typography.fontWeightBold),
  }),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
}));

export const StyledOptionsTab = styled(Tab, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'uppercase',
  width: '20%',
  minWidth: 0,
  ...(isSelected && {
    fontWeight: Number(theme.typography.fontWeightBold),
  }),
}));

export const StyledTabPanel = styled(TabPanel)(() => ({
  padding: '1rem',
  position: 'relative',
  height: 'calc(100vh - 200px)',
  overflow: 'auto',
}));

export const StyledTasksLink = styled(Link)<{
  component?: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to?: any;
}>(({ theme }) => ({
  marginTop: '5px',
  textAlign: 'right',
  paddingRight: '10px',
}));
