import { DEFAULT_PAGINATION } from 'common/const';
import { useState, useCallback } from 'react';
import { useLocalStorage } from 'utilities/localStorage';

const usePagination = () => {
  const { setStorageItem, getStorageItem } = useLocalStorage();

  const getPageSize = useCallback(() => {
    const storedPageSize = getStorageItem('gridPageSize');

    return storedPageSize ? JSON.parse(storedPageSize) : DEFAULT_PAGINATION.pageSize;
  }, [getStorageItem]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(() => getPageSize());

  const handlePageSizeChanged = useCallback(
    (value: any) => {
      setPage(0);
      setPageSize(value);
      setStorageItem('gridPageSize', JSON.stringify(value));
    },
    [setPage, setStorageItem],
  );

  return {
    page,
    pageSize,
    setPage,
    setPageSize: handlePageSizeChanged,
  };
};

export default usePagination;
