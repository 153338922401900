import React from 'react';
import { Typography } from 'components';

const style = {
  minHeight: 'fit-content',
  py: 1,
  px: 2,
  '&.Mui-focused': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  cursor: 'pointer',
};

type AutocompleteOptionProps = {
  value: string;
  color?: string | null;
};

export const AutocompleteOption: React.FC<AutocompleteOptionProps> = ({ value, color = null, ...props }) => (
  <Typography
    {...props}
    sx={{
      ...style,
      color: color || 'inherit',
    }}
  >
    {value}
  </Typography>
);

export default AutocompleteOption;
