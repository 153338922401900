import { Sorting } from 'common';
import isEqual from 'lodash.isequal';
import { TaskFilters, TaskSearchState } from 'tasks/model';
import { getDefaultFilters, getInitialTaskState } from './helpers';

export type ClauseSearchAction =
  | { type: 'CHANGE_PAGE'; payload: number }
  | { type: 'CHANGE_PAGE_SIZE'; payload: number }
  | { type: 'CHANGE_FILTERS'; payload: TaskFilters | null }
  | { type: 'CHANGE_SORT'; payload: Sorting }
  | { type: 'RESET'; payload: null };

const taskSearchReducer = (
  state: TaskSearchState,
  action: ClauseSearchAction,
  getStorageItem: (key: string) => string,
): TaskSearchState => {
  switch (action.type) {
    case 'CHANGE_PAGE': {
      return {
        ...state,
        pageNumber: action.payload,
      };
    }
    case 'CHANGE_PAGE_SIZE': {
      return {
        ...state,
        pageNumber: 0,
        pageSize: action.payload,
      };
    }
    case 'CHANGE_FILTERS': {
      const filters = action.payload === null ? getDefaultFilters() : action.payload;

      return {
        ...state,
        filters,
        pageNumber: isEqual(state.filters, action.payload) ? state.pageNumber : 0,
      };
    }
    case 'CHANGE_SORT': {
      const { pageSize, filters } = state;
      return {
        sort: { ...action.payload },
        filters,
        pageSize,
        pageNumber: 0,
      };
    }
    case 'RESET': {
      const initialState = getInitialTaskState(getStorageItem);

      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default taskSearchReducer;
