import { styled, TableCell, Tooltip } from '@mui/material';

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'dense',
})<{ dense?: boolean; disabled?: boolean }>(({ theme, dense, disabled }) => ({
  padding: dense ? theme.spacing(1) : theme.spacing(1.5),
  ...(disabled && {
    color: theme.palette.text.disabled,
  }),
}));

export const StyledContentSpan = styled('span')(() => ({
  whiteSpace: 'pre-wrap',
}));

export const StyledFlexContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  marginLeft: theme.spacing(0.375),
}));
