import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { AzureConfig } from '../config/config.service';

export abstract class AuthErrorCodes {
  static userCancelled = 'user_cancelled';
}

export const requiresInteraction = (errorMessage: Array<string>) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

const defaultConfiguration: Configuration = {
  auth: {
    clientId: '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
  system: {
    windowHashTimeout: 60000,
  },
};

export const createMsalApp = (authConfig: AzureConfig) =>
  new PublicClientApplication({
    ...defaultConfiguration,
    auth: {
      ...authConfig,
      authority: authConfig.authority,
      // eslint-disable-next-line no-restricted-globals
      redirectUri: location.origin + authConfig.redirectUri,
      // eslint-disable-next-line no-restricted-globals
      postLogoutRedirectUri: location.origin + authConfig.postLogoutRedirectUri,
      navigateToLoginRequestUrl: false,
    },
  });
