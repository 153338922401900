import React from 'react';
import { ArrowIcon } from 'icons';
import { TaskItem } from 'tasks/model';
import { StyledCloseButton, StyledTaskSidePanel, StyledTitle } from './TaskDrawer.styles';
import { TaskList } from './components';

type TaskDrawerProps = {
  sideBarOpen: boolean;
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  openTasks: TaskItem[];
};

const TaskDrawer: React.FC<TaskDrawerProps> = ({ sideBarOpen, handleClose, handleOpenTaskModal, openTasks }) => {
  return (
    <StyledTaskSidePanel
      anchor="right"
      closeAfterTransition
      ModalProps={{
        BackdropProps: { invisible: true },
      }}
      open={sideBarOpen}
      variant="temporary"
    >
      <StyledCloseButton
        color="textSecondary"
        component="button"
        data-testid="close-button"
        onClick={handleClose}
        type="button"
        underline="hover"
      >
        Close <ArrowIcon fontSize="small" />
      </StyledCloseButton>
      <StyledTitle color="primary" variant="h5">
        My Tasks
      </StyledTitle>
      <TaskList handleClose={handleClose} handleOpenTaskModal={handleOpenTaskModal} openTasks={openTasks} />
    </StyledTaskSidePanel>
  );
};

export default TaskDrawer;
