import { styled, Drawer, Link, Typography, Box } from 'components';

export const StyledTaskSidePanel = styled(Drawer)<{ component?: string }>(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  },

  '& .MuiDrawer-paper': {
    marginTop: '65px',
    height: 'auto',
    maxHeight: 'auto',
    width: '23rem',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: '57px',
      width: '100vw',
    },
  },
  width: 'fit-content',
}));

export const StyledCloseButton = styled(Link)<{ component?: string }>(() => ({
  position: 'absolute',
  top: '0.5rem',
  right: '1rem',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginLeft: '4px',
  },
}));

export const StyledTitle = styled(Typography)<{ component?: string }>(({ theme }) => ({
  marginTop: '0.5rem',
  paddingLeft: '1rem',
  paddingBottom: '0',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0.5rem',
  },
}));

export const StyledTaskContainer = styled(Box)<{ component?: string }>(() => ({
  padding: '0.5rem',
  maxHeight: '274px',
  overflowY: 'auto',
  marginBottom: '-5px',
}));
