import {
  styled,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  useTheme,
  responsiveFontSizes,
  DeprecatedThemeOptions,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { buttonStyles } from 'components/Button/Button.styles';

interface SteamshipThemeOptions extends DeprecatedThemeOptions {}

const getBasicSIMSLThemeOptions = (getRecalculatedFontSize: (baseValue: number) => number) => {
  const basicSIMSLThemeOptions: SteamshipThemeOptions = {
    palette: {
      primary: {
        light: '#5666AB',
        main: '#233c7B',
        dark: '#00174E',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#C8C8C8',
        main: '#979797',
        dark: '#696969',
        contrastText: '#262626',
      },
      mode: 'light',
      text: {
        secondary: 'rgba(0, 0, 0, 0.54)',
      },
    },
    typography: {
      fontFamily: 'frutiger, Calibri, Arial, Tahoma, Helvetica, sans-serif',
      htmlFontSize: getRecalculatedFontSize(16),
      fontSize: getRecalculatedFontSize(14),
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  };

  return basicSIMSLThemeOptions;
};

const getTheme = (getRecalculatedFontSize: any, additionalSettings: boolean = false) => {
  const theme = responsiveFontSizes(
    createTheme({
      ...getBasicSIMSLThemeOptions(getRecalculatedFontSize),
      components: {
        MuiButton: {
          styleOverrides: buttonStyles,
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontSize: `${getRecalculatedFontSize(0.875)}rem`,
              lineHeight: 1.43,
              letterSpacing: '0.01071em',
            },
          },
        },
      },
    }),
    { factor: 5 },
  );

  if (additionalSettings) {
    theme.typography.body1 = {
      ...theme.typography.body1,
      lineHeight: '1.063rem',
    };
    theme.typography.body2 = {
      fontSize: `${getRecalculatedFontSize(0.875)}rem`,
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: `${getRecalculatedFontSize(0.7)}rem`,
      },
    };

    theme.typography.button = {
      fontSize: `${getRecalculatedFontSize(0.925)}rem`,
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: `${getRecalculatedFontSize(0.625)}rem`,
      },
    };

    theme.typography.caption = {
      fontSize: `${getRecalculatedFontSize(0.75)}rem`,
      fontFamily: theme.typography.fontFamily,
      lineHeight: '0.885rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: `${getRecalculatedFontSize(0.625)}rem`,
        lineHeight: '0.75rem',
      },
    };

    theme.typography.h6 = {
      fontSize: `${getRecalculatedFontSize(1.0)}rem`,
      fontWeight: Number(theme.typography.fontWeightRegular),
      fontFamily: theme.typography.fontFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: `${getRecalculatedFontSize(0.82)}rem`,
      },
    };
  }

  return theme;
};

const createSteamshipTheme = (getRecalculatedFontSize?: (baseValue: number) => number) => getTheme(getRecalculatedFontSize, true);

export { styled, createSteamshipTheme, ThemeProvider, useTheme, CssBaseline, useMediaQuery, StyledEngineProvider };
