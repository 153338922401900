import React, { useCallback } from 'react';
import { StyledDrawer } from './SidePanel.styles';

export type SidePanelProps = {
  children: React.ReactNode;
  onCloseAttempt?: () => void;
  width?: string;
  dataTestId?: string;
  anchor?: 'right' | 'bottom' | 'left' | 'top' | undefined;
  open?: boolean;
};

const SidePanel: React.FC<SidePanelProps> = ({ children, onCloseAttempt, width = '577px', dataTestId, anchor = 'right', open = true }) => {
  const tryClose = useCallback(() => {
    if (onCloseAttempt) {
      onCloseAttempt();
    }
  }, [onCloseAttempt]);

  return (
    <StyledDrawer
      anchor={anchor}
      closeAfterTransition
      ModalProps={{
        BackdropProps: { invisible: true },
        onClose: tryClose,
        style: {
          right: '',
          bottom: '',
          top: '',
          left: '',
        },
      }}
      open={open}
      PaperProps={{
        // @ts-ignore
        'data-testid': dataTestId ? `${dataTestId}-side-panel` : undefined,
      }}
      variant="temporary"
      width={width}
    >
      {children}
    </StyledDrawer>
  );
};

export default SidePanel;
