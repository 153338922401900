import { GridAggregationFunction } from '@mui/x-data-grid-premium';

const GridAggregationTotalLabel: GridAggregationFunction<string, string | null> = {
  apply: () => {
    return 'Total';
  },
  label: '',
  columnTypes: ['string'],
};

export default GridAggregationTotalLabel;
