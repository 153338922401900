import { styled, Button, Typography, ListItem, ListItemIcon, ListItemText, Avatar } from 'components';
import { KeyboardArrowDownIcon } from 'icons';

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    width: 23,
    height: 23,
    fontSize: theme.typography.fontSize,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const StyledUserInfo = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(2)}`,
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
}));

export const StyledUserName = styled(Typography)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
}));

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: theme.typography.body2.fontSize,
  },
}));

export const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected: boolean }>(props => ({
  background: props.isSelected ? 'rgba(1,1,1,.1)' : '',
}));
