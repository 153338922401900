import { AuthorisorTypeValue } from 'common';
import { useUser } from 'utilities/hooks';

const useUserPermissions = (authorisorType: AuthorisorTypeValue) => {
  const user = useUser();

  return {
    isEditingAllowed: user?.authorisorTypes.includes(authorisorType) || false,
    isLoading: user === null,
  };
};

export default useUserPermissions;
