import { styled, Box } from 'components';

export const StyledRootBox = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: '1 1 auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
