import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type ConfirmType = {
  message: string;
  title: string;
  cancelButtonName: string;
  confirmButtonName: string;
  isOpen: boolean;
  submit: (value?: boolean) => void;
  cancel: (reason?: string) => void;
};

type SetConfirmType = React.Dispatch<React.SetStateAction<ConfirmType>>;

type ConfirmContextProviderProps = {
  children: React.ReactNode;
};

export const ConfirmContext: React.Context<[ConfirmType, SetConfirmType] | undefined> = createContext<
  [ConfirmType, SetConfirmType] | undefined
>(undefined);

export const ConfirmContextProvider: React.FC<ConfirmContextProviderProps> = ({ children }) => {
  const [confirm, setConfirm] = useState({
    message: '',
    title: '',
    cancelButtonName: 'back',
    confirmButtonName: 'continue',
    isOpen: false,
    submit: () => {},
    cancel: () => {},
  });

  const context = useMemo<[ConfirmType, SetConfirmType] | undefined>(() => [confirm, setConfirm], [confirm, setConfirm]);

  return <ConfirmContext.Provider value={context}>{children}</ConfirmContext.Provider>;
};

const useConfirm = () => {
  const value = useContext<[ConfirmType, SetConfirmType] | undefined>(ConfirmContext);
  if (!value) {
    throw new Error('useConfirm must be used within ConfirmContext.Provider.');
  }
  const [confirm, setConfirm] = value;
  const showConfirmation = useCallback(
    (message: string, title: string = '', confirmButtonName: string = 'continue', cancelButtonName: string = 'back') =>
      new Promise((resolve, reject) => {
        setConfirm({
          message,
          title,
          cancelButtonName,
          confirmButtonName,
          isOpen: true,
          submit: resolve,
          cancel: reject,
        });
      })
        .then(() => {
          setConfirm({ ...confirm, isOpen: false });
          return { isConfirmed: true, reason: '' };
        })
        .catch((reason?: string) => {
          setConfirm({ ...confirm, isOpen: false });
          return { isConfirmed: false, reason };
        }),
    [confirm, setConfirm],
  );

  return {
    ...confirm,
    showConfirmation,
  };
};

export default useConfirm;
