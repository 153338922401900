import React from 'react';
import PropertyEmptyValue from './PropertyEmptyValue';
import PropertyValue from './PropertyValue';

type PropertyBooleanValueProps = {
  value: boolean | null;
  trueValueText: string;
  falseValueText: string;
  nullValueText?: string;
  dataTestId?: string;
};

const PropertyBooleanValue: React.FC<PropertyBooleanValueProps> = ({
  value,
  trueValueText,
  falseValueText,
  dataTestId,
  nullValueText = '',
}) => {
  if (value === null) {
    return <PropertyEmptyValue dataTestId={dataTestId ? `${dataTestId}-value` : undefined}>{nullValueText}</PropertyEmptyValue>;
  }

  return <PropertyValue dataTestId={dataTestId} value={value ? trueValueText : falseValueText} />;
};

export default PropertyBooleanValue;
