import { MenuOptionValue } from 'common';
import { useUser } from 'utilities/hooks';

const useUserMenuOptionPermissions = (menuOption: MenuOptionValue) => {
  const user = useUser();

  return {
    isAllowed: user?.menuOptions.includes(menuOption) || false,
    isLoading: user === null,
  };
};

export default useUserMenuOptionPermissions;
