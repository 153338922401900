import { styled, Input } from '@mui/material';
import Box from '../Box';
import Typography from '../Typography';

export const StyledRootBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
}));

export const StyledDescriptionTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'customDescriptionWidth',
})<{ customDescriptionWidth?: number }>(({ theme, customDescriptionWidth }) => ({
  flex: '1 0 auto',
  marginRight: theme.spacing(2),
  maxWidth: customDescriptionWidth,
  [theme.breakpoints.down('sm')]: {
    minWidth: 70,
  },
}));

export const StyledInput = styled(Input, {
  shouldForwardProp: prop => prop !== 'customInputWidth',
})<{ customInputWidth?: string }>(({ theme, customInputWidth }) => ({
  flexGrow: 2,
  fontSize: theme.typography.caption.fontSize,
  width: customInputWidth,
  maxWidth: customInputWidth,
  '&:before': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
