import { styled, Card, CardActionArea, CardMedia } from '../components';

export const StyledRootCard = styled(Card)(({ theme }) => ({
  minWidth: '98%',
  [theme.breakpoints.up('sm')]: {
    minWidth: 350,
    maxWidth: 360,
  },
  margin: theme.spacing(1),
  flex: '1 1 0',
  [theme.breakpoints.down('sm')]: {
    flex: '1 1 auto',
  },
}));

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%',
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 200,
  [theme.breakpoints.down('sm')]: {
    height: 100,
  },
}));
