import React, { useCallback, useEffect, useState } from 'react';
import axios, { Canceler } from 'axios';
import { Autocomplete, CircularProgress, Spinner, useTheme } from 'components';
import { Category } from '../../businessCommon/model/category';
import { isBestTextColorLight } from '../../utilities/misc/ColorHelper';
import { StyledChip, StyledTextField } from './CategoriesEdit.styles';

type CategoriesEditProps = {
  getAllCategories: () => [Promise<Category[]>, Canceler];
  initiallySelectedCategories: number[];
  onChange: (_: React.ChangeEvent<{}>, value: Category[] | null) => void;
  onError: (errorMessage: string) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CategoriesEdit: React.FC<CategoriesEditProps> = ({
  getAllCategories,
  initiallySelectedCategories,
  onChange,
  onError,
  onMouseDown,
}) => {
  const theme = useTheme();

  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  const fetchAllCategories = useCallback(() => {
    const [categoriesResponse, categoriesCanceler] = getAllCategories();
    categoriesResponse
      .then(allCategories => {
        setCategories(allCategories);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          onError('Categories list is not available.');
        }
      });

    return categoriesCanceler;
  }, [getAllCategories, onError]);

  useEffect(() => {
    setSelectedCategories(categories.filter(cat => initiallySelectedCategories.some((c: number) => c === cat.id)));
  }, [initiallySelectedCategories, categories]);

  useEffect(() => {
    const categoriesCanceler = fetchAllCategories();

    return () => {
      if (categoriesCanceler) {
        categoriesCanceler();
      }
    };
  }, [fetchAllCategories]);

  const handleCategoriesChanged = (e: React.ChangeEvent<{}>, value: Category[] | null) => {
    setSelectedCategories(value || []);
    onChange(e, value);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return categories.length > 0 ? (
    <Autocomplete
      getOptionLabel={(option: Category) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      multiple
      onChange={handleCategoriesChanged}
      options={categories}
      renderInput={params => (
        <StyledTextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!categories && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ ...params.inputProps, 'data-testid': `categories-autocomplete` }}
          onMouseDown={handleMouseDown}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} data-testid={`business-category-option-${option.id}`}>
          {option.name}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledChip
            {...getTagProps({ index })}
            data-testid={`business-category-tag-${index}`}
            isColorLight={isBestTextColorLight(option.hexColorCode || theme.palette.background.paper)}
            label={option.name}
            size="small"
            style={{ backgroundColor: option.hexColorCode }}
            variant="outlined"
          />
        ))
      }
      size="small"
      value={selectedCategories}
    />
  ) : (
    <Spinner overlay />
  );
};

export default CategoriesEdit;
