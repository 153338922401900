import { CircularProgress, FormControl, MenuItem, Select, SelectProps, Theme, Typography } from '@mui/material';
import { styled } from 'components';

type StyleProps = {
  isEmptyValue?: boolean;
  isUnavailableOption?: boolean;
  placeholder?: string;
  isLoading?: boolean;
};

export const StyledLabelTypography = styled(Typography, {
  shouldForwardProp: (prop: any) => prop !== 'customLabelWidth',
})<{
  customLabelWidth: string | number;
}>(({ theme, customLabelWidth }: { theme: Theme; customLabelWidth: string | number }) => ({
  flex: '1 0 auto',
  marginRight: theme.spacing(2),
  textAlign: 'left',
  maxWidth: customLabelWidth,
  [theme.breakpoints.down('sm')]: {
    minWidth: 70,
  },
}));

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop: any) => prop !== 'customInputWidth',
})<{
  customInputWidth: string | number;
}>(({ customInputWidth }: { customInputWidth: string | number }) => ({
  width: customInputWidth,
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: 0,
}));

export const StyledBaseSelect = styled(Select, {
  shouldForwardProp: (prop: any) => prop !== 'isEmptyValue' && prop !== 'isUnavailableOption',
})<{ isEmptyValue?: boolean; isUnavailableOption?: boolean; placeholder?: string }>(({
  theme,
  isEmptyValue,
  isUnavailableOption,
  placeholder,
}: {
  theme: Theme;
  isEmptyValue?: boolean;
  isUnavailableOption?: boolean;
  placeholder?: string;
}) => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: theme.typography.caption.fontSize,
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
    },
    '&:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
    },
    ...(isEmptyValue && {
      color: placeholder ? 'rgba(0, 0, 0, 0.35)' : 'inherit',
    }),
    ...(isUnavailableOption && {
      color: theme.palette.text.disabled,
    }),
  };
});

export const StyledSelect = styled(({ className, isLoading, ...props }: SelectProps & StyleProps) => {
  return <StyledBaseSelect {...props} MenuProps={{ PaperProps: { className } }} />;
})(({ isLoading }: { isLoading: boolean }) => ({
  ...(isLoading && {
    display: 'none',
  }),
}));

export const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop: any) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }: { theme: Theme; disabled?: boolean }) => ({
  [theme.breakpoints.up('xs')]: {
    minHeight: '2.25rem',
    whiteSpace: 'normal',
    ...(disabled && {
      color: theme.palette.text.disabled,
    }),
  },
}));
