import React from 'react';

import { Divider } from 'components';
import { StyledGrid } from './SectionDivider.styled';

const SectionDivider: React.FC = () => {
  return (
    <StyledGrid item xs={12}>
      <Divider />
    </StyledGrid>
  );
};

export default SectionDivider;
