import { Typography } from '@mui/material';
import { styled } from 'components';

export const StyledPropertyValueTypography = styled(Typography)<{ disabled?: boolean | null }>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.text.disabled : '',
  display: 'block',
  overflowWrap: 'break-word',
  width: '100%',
  flexBasis: 'fit-content',
}));
