import React from 'react';

import { ColumnIcon, TableCellIconType } from 'common/model';
import { AccessAlarmIcon, CheckIcon, ErrorIcon, ScheduleIcon, TimelapseIcon, ExploreIcon, CloseIcon } from 'icons';
import { StyledRootBox } from './TableCellIcon.styles';

const icons: Record<TableCellIconType, React.FC> = {
  check: CheckIcon,
  schedule: ScheduleIcon,
  timelapse: TimelapseIcon,
  accessAlarm: AccessAlarmIcon,
  error: ErrorIcon,
  explore: ExploreIcon,
  close: CloseIcon,
};

type TableCellIconProps = {
  icon: ColumnIcon;
};

const TableCellIcon: React.FC<TableCellIconProps> = ({ icon }) => {
  const Icon: React.FC = icons[icon.iconName];

  return Icon ? (
    <StyledRootBox color={icon.iconColor}>
      <Icon />
    </StyledRootBox>
  ) : null;
};

export default React.memo(TableCellIcon);
