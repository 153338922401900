import React from 'react';

import { Typography } from 'components';
import ErrorImg from './error-img.svg';
import { StyledErrorContainer, StyledMessageContentBox, StyledRootBox, StyledImageBox } from './ErrorPanel.styles';

export type ErrorPanelProps = {
  message?: string;
  displayMessageOnly?: boolean;
};

const ErrorPanel: React.FC<ErrorPanelProps> = ({ message, displayMessageOnly }) => {
  return (
    <StyledErrorContainer maxWidth="md">
      <StyledRootBox>
        <StyledImageBox>
          <img alt="error" src={ErrorImg} />
        </StyledImageBox>
        <StyledMessageContentBox>
          {displayMessageOnly !== true && (
            <Typography color="textSecondary" component="h1" variant="h4">
              Something went wrong.
            </Typography>
          )}
          {message && (
            <Typography color="textSecondary" variant={displayMessageOnly ? 'h5' : 'body2'}>
              {message}
            </Typography>
          )}
          {displayMessageOnly !== true && (
            <Typography color="textSecondary" variant="h5">
              Please try again later
            </Typography>
          )}
        </StyledMessageContentBox>
      </StyledRootBox>
    </StyledErrorContainer>
  );
};

export default ErrorPanel;
