import { Canceler, CancelTokenSource } from 'axios';

import { useSnackbar } from 'components';
import { useCallback, useState, useEffect } from 'react';
import { useMountEffect } from 'utilities/hooks';
import { SimslAutocompleteProps } from '../Autocomplete';
import { StyledSimslAutocomplete } from './styles';

export type SimslAutocompleteAsyncProps<T> = Omit<SimslAutocompleteProps<T>, 'isLoading' | 'data'> & {
  onDataFetched?: (data: T[]) => void;
  apiRequest: () => [Promise<T[]>, Canceler | CancelTokenSource];
  fetchOnInit?: boolean;
  errorMessage?: string;
  triggerRefetch?: boolean;
  dataTestId?: string;
};

function AutocompleteAsync<T>(props: SimslAutocompleteAsyncProps<T>) {
  const { className, apiRequest, errorMessage, fetchOnInit = false, onDataFetched = () => {}, triggerRefetch, ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<T[]>([]);

  useMountEffect(() => {
    if (fetchOnInit) {
      fetchOptions();
    }
  });

  const fetchOptions = useCallback(() => {
    setLoading(true);
    const [request] = apiRequest();
    request
      .then(data => {
        setOptions(data);
        onDataFetched(data);
      })
      .catch(fetchError => {
        const defaultMessage = errorMessage || 'Error occurred during autocomplete data fetch';
        enqueueSnackbar(fetchError.Message || defaultMessage, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar, apiRequest, errorMessage, onDataFetched]);

  useEffect(() => {
    if (triggerRefetch) fetchOptions();
  }, [triggerRefetch, fetchOptions]);

  const handleOpen = useCallback(() => {
    if (!options.length) {
      fetchOptions();
    }
  }, [options.length, fetchOptions]);

  return <StyledSimslAutocomplete {...rest} className={className} data={options} isLoading={isLoading} onOpen={handleOpen} />;
}

export default AutocompleteAsync;
