import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AsyncStatus, InfoService, LoggedInUser } from 'common';
import { useMountEffect } from 'utilities/hooks';
import { Spinner, useSnackbar } from 'components';
import BussinessesRoutes from 'businesses/Routes';
import BussinessContactsRoutes from 'businessContacts/Routes';
import VesselSurveyRoutes from 'surveys/Routes';
import ObjectsRoutes from 'objectMaintenance/routes';
import MemberGroupsRoutes from 'memberGroups/routes';
import ClausesRoutes from 'certificateClauses/routes';
import SubmissionsRoutes from 'submissions/routes';
import BrokersRoutes from 'brokers/routes';
import { RoutePath as ModulesRoutes } from 'RoutePath';

export type UserContextType = {
  userDetails: LoggedInUser | null;
  isDataFetched: boolean;
};

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserContext: React.Context<UserContextType> = React.createContext<UserContextType>({
  userDetails: null,
  isDataFetched: false,
});
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserContextType>({ userDetails: null, isDataFetched: false });
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const navigate = useNavigate();
  const url = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const navigateWithErrorState = useCallback(
    (currentPath: string) => {
      return navigate(currentPath, { state: { error: true } });
    },
    [navigate],
  );

  const redirectToErrorPath = useCallback(
    (currentPath: string) => {
      if (currentPath.includes(ModulesRoutes.BusinessesModule)) {
        return navigateWithErrorState(`${ModulesRoutes.BusinessesModule}/${BussinessesRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.BusinessContactsModule)) {
        return navigateWithErrorState(`${ModulesRoutes.BusinessContactsModule}/${BussinessContactsRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.SurveysModule)) {
        return navigateWithErrorState(`${ModulesRoutes.SurveysModule}/${VesselSurveyRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.ObjectsModule)) {
        return navigateWithErrorState(`${ModulesRoutes.ObjectsModule}/${ObjectsRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.MemberGroupsModule)) {
        return navigateWithErrorState(`${ModulesRoutes.MemberGroupsModule}/${MemberGroupsRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.CertificateClausesModule)) {
        return navigateWithErrorState(`${ModulesRoutes.CertificateClausesModule}/${ClausesRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.SubmissionsModule)) {
        return navigateWithErrorState(`${ModulesRoutes.SubmissionsModule}/${SubmissionsRoutes.ApiNotAvailable.path}`);
      }

      if (currentPath.includes(ModulesRoutes.BrokersModule)) {
        return navigateWithErrorState(`${ModulesRoutes.BrokersModule}/${BrokersRoutes.ApiNotAvailable.path}`);
      }

      return null;
    },
    [navigateWithErrorState],
  );

  const initializeUser = useCallback(async () => {
    setStatus('fetching');
    const [userRequest] = InfoService.getLoggedUserInfo();
    userRequest
      .then(data => {
        setUser({ userDetails: data, isDataFetched: true });
        setStatus('resolved');
      })
      .catch(error => {
        setUser({ userDetails: null, isDataFetched: true });
        setStatus('rejected');
        if (error.response.status === 404) {
          enqueueSnackbar(error.response.data?.Message || 'User not found.', 'error');
          redirectToErrorPath(url.pathname);
        }
      });
  }, [setUser, url, redirectToErrorPath, enqueueSnackbar]);

  useMountEffect(() => {
    initializeUser();
  });

  return status === 'idle' || status === 'fetching' ? <Spinner /> : <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
