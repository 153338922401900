import React from 'react';
import { GridSize } from '@mui/material';
import { StyledRootGrid, StyledTitleTypography } from './SectionTitle.styles';

type SectionTitleProps = {
  title: string;
  gridSize?: GridSize;
  dataTestId?: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ title, gridSize = 12, dataTestId }) => {
  return (
    <StyledRootGrid data-testid={dataTestId ? `${dataTestId}-section` : undefined} item xs={gridSize}>
      <StyledTitleTypography color="primary" variant="caption">
        {title}
      </StyledTitleTypography>
    </StyledRootGrid>
  );
};

export default SectionTitle;
