import React from 'react';
import { ChipProps } from '@mui/material';
import { StyledChip } from './SimslChip.styles';

export type SimslChipProps = ChipProps;

const SimslChip: React.FC<SimslChipProps> = ({ ...rest }) => {
  return <StyledChip {...rest} />;
};

export default SimslChip;
