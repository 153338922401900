import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SimslModule } from 'components';
import { RoutePath as ModulesRoutes } from 'RoutePath';
import RoutePath from './routes';
import TaskGrid from './components/TaskGrid';
import { TaskModal } from './components/TaskModal';
import { TaskItem } from './model';

const determineActiveRootRoute = (pathname: string): { currentScreenName: string } => {
  if (pathname.includes(ModulesRoutes.TasksModule)) {
    return { currentScreenName: RoutePath.Tasks.name };
  }

  return { currentScreenName: '' };
};

const Tasks: React.FC = () => {
  const { pathname } = useLocation();
  const { currentScreenName } = determineActiveRootRoute(pathname);
  const [selectedTask, setSelectedTask] = useState<TaskItem>();
  const [openTaskModal, setOpenTaskModal] = useState(false);

  const handleOpenTaskModal = (task: TaskItem) => {
    setOpenTaskModal(true);
    setSelectedTask(task);
  };

  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
  };

  return (
    <SimslModule metaTitle="Tasks" title={currentScreenName}>
      <TaskGrid handleOpenTaskModal={handleOpenTaskModal} />
      {selectedTask && <TaskModal handleCloseTaskModal={handleCloseTaskModal} openTaskModal={openTaskModal} task={selectedTask} />}
    </SimslModule>
  );
};

export default Tasks;
