import React, { useCallback } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Button } from '../Button';
import Typography from '../Typography';
import { StyledDialog } from './GenericDialog.styles';

export type ConfirmationDialogProps = {
  children: React.ReactNode;
  title?: string;
  isSubmitDisabled?: boolean;
  isSubmitting?: boolean;
  open: boolean;
  onClose: (result: boolean, reason?: string) => void;
  confirmButtonName?: string;
  cancelButtonName?: string;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  onClose,
  open,
  title,
  isSubmitDisabled = false,
  isSubmitting = false,
  children,
  confirmButtonName = 'continue',
  cancelButtonName = 'back',
}) => {
  const handleClose = useCallback(
    (result: boolean, reason?: string) => {
      onClose(result, reason);
    },
    [onClose],
  );
  return (
    <StyledDialog data-testid="confirmation-dialog" onClose={(event: any, reason: any) => handleClose(false, reason)} open={open}>
      <DialogTitle>
        <Typography color="primary" component="span" variant="h5">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography color="textPrimary" component="span" variant="body1">
          {children}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" dataTestId="cancel" disabled={isSubmitting} onClick={() => handleClose(false)} variant="outlined">
          {cancelButtonName}
        </Button>
        <Button
          autoFocus
          color="primary"
          dataTestId="confirm"
          disabled={isSubmitDisabled}
          isLoading={isSubmitting}
          onClick={() => handleClose(true)}
          variant="contained"
        >
          {confirmButtonName}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfirmationDialog;
