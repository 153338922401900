import { format, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import isNil from 'lodash.isnil';

export const date2BritishString = (date: Date) => {
  return format(date, 'dd/MM/yyyy', { locale: enGB });
};

export const dateToIso8601 = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const dateToIso8601WithZeroTime = (date: Date): string => {
  return dateToIso8601(setZeroTime(date));
};

export const setZeroTime = (date: Date): Date => {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result;
};

export const daysBetweenTwoDates = (date1: Date, date2: Date): number => {
  const oneDayMillis = 86400000;
  return Math.floor((date1.getTime() - date2.getTime()) / oneDayMillis);
};

export const isDateEqual = (date1: Date | null, date2: Date | null): boolean => {
  return date1?.getTime() === date2?.getTime();
};

export const isDateGreaterThan = (date1: Date, date2: Date): boolean => {
  if (date1 && date2) return date1.getTime() > date2.getTime();
  return true;
};

export const isDateGreaterThanOrEqual = (date1: Date, date2: Date): boolean => {
  return isDateGreaterThan(date1, date2) || isDateEqual(date1, date2);
};

export const isDateInTheFuture = (date1: Date): boolean => {
  return setZeroTime(date1) > setZeroTime(new Date());
};

export const toDate = (value: Date | string | number | null | undefined): Date | null => {
  if (isNil(value)) {
    return null;
  }

  const result = new Date(value);
  if (!isValid(result)) {
    return null;
  }

  return result;
};
