import React from 'react';
import { FormControlLabel, Typography } from '@mui/material';
import SimslSwitch from './SimslSwitch';

interface SimslLabeledSwitchProps {
  checked: boolean;
  className?: string;
  inputRef?: ((instance: any) => void) | React.RefObject<any> | null | undefined;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
  onMouseDown?: () => void;
  label: string;
  name?: string;
}

const SimslLabeledSwitch: React.FC<SimslLabeledSwitchProps> = ({ className, checked, inputRef, label, name, onChange, onMouseDown }) => {
  return (
    <FormControlLabel
      className={className}
      control={<SimslSwitch checked={checked || false} inputRef={inputRef} name={name} onChange={onChange} />}
      label={
        <Typography color="textSecondary" sx={{ display: 'block' }} variant="caption">
          {label}
        </Typography>
      }
      onMouseDown={onMouseDown}
    />
  );
};

export default SimslLabeledSwitch;
