import React from 'react';

import { Sorting, SortDirection, Column } from 'common';
import { mapSortDirection } from 'utilities/misc/TableHelper';
import { Box } from 'components';
import TableHead from './TableHead';
import TableCell from './TableCell';
import { StyledTableRow, StyledTableSortLabel } from './TableHeader.styles';

const getSortDirection = (sortKey: string, activeSorting?: Sorting) => {
  if (sortKey !== activeSorting?.sortKey || activeSorting?.sortDirection === SortDirection.UNSPECIFIED) {
    return false;
  }

  return activeSorting.sortDirection === SortDirection.ASCENDING ? 'asc' : 'desc';
};

export type TableHeaderProps = {
  columns: Column[];
  dense?: boolean;
  onColumnClicked?: (sortKey: string) => void;
  className?: string;
  activeSorting?: Sorting;
  isSortingActive?: boolean;
  tableFontSize?: number;
  tableFontSizeUnit?: string;
};

const TableHeader: React.FC<TableHeaderProps> = ({
  columns,
  dense,
  onColumnClicked,
  className,
  isSortingActive = true,
  activeSorting,
  tableFontSize,
  tableFontSizeUnit,
}) => {
  const handleColumnClick = (id: string) => {
    if (onColumnClicked) {
      onColumnClicked(id);
    }
  };

  return (
    <TableHead className={className}>
      <StyledTableRow dense={dense} tableFontSize={tableFontSize}>
        {columns.map(c => (
          <TableCell
            key={c.id}
            align={c.align}
            colSpan={c.colSpan ? c.colSpan : 1}
            data-testid={`grid-header-${c.id}`}
            sortDirection={getSortDirection(c.sortKey, activeSorting)}
            style={{ minWidth: c.minWidth, maxWidth: c.maxWidth }}
          >
            <Box>
              {c.label && (
                <StyledTableSortLabel
                  active={c.sortKey === activeSorting?.sortKey && activeSorting.sortDirection !== SortDirection.UNSPECIFIED}
                  direction={mapSortDirection(activeSorting?.sortDirection || SortDirection.UNSPECIFIED)}
                  hideSortIcon
                  isSortingActive={isSortingActive}
                  onClick={() => handleColumnClick(c.sortKey)}
                >
                  {c.label}
                </StyledTableSortLabel>
              )}
            </Box>
          </TableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

export default TableHeader;
