import React from 'react';
import { TableCellProps } from '@mui/material';
import { Tooltip } from 'components';
import TooltipTitle from './TooltipTitle';
import { StyledTableCell } from './SimslTableCellTooltip.styles';

type SimslTableCellTooltipProps = TableCellProps & {
  dense: boolean;
  onCloseTooltip: () => void;
  onOpenTooltip: ((event: React.ChangeEvent<{}>) => void) | undefined;
  tooltipText: string;
  children: React.ReactNode;
  columnId: number;
};

const SimslTableCellTooltip: React.FC<SimslTableCellTooltipProps> = ({
  dense,
  onCloseTooltip,
  onOpenTooltip,
  tooltipText,
  children,
  columnId,
  ...props
}) => {
  return (
    <StyledTableCell {...props} data-testid={`grid-column-${columnId + 1}`} dense={dense}>
      <Tooltip
        enterDelay={500}
        enterNextDelay={500}
        onClose={onCloseTooltip}
        onOpen={onOpenTooltip}
        title={<TooltipTitle tooltipText={tooltipText} />}
      >
        <div>{children}</div>
      </Tooltip>
    </StyledTableCell>
  );
};

export default SimslTableCellTooltip;
