import { useCallback } from 'react';
import { InteractionRequiredAuthError, AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';

import { useConfig } from 'utilities/config';

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0]);
  const config = useConfig();

  const acquireToken = useCallback(async () => {
    const scopes = [...config.AzureAD.apiScopes];
    try {
      if (!account) throw new Error('login_required');
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await instance.acquireTokenSilent({
        scopes,
        account,
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes\
      if (err instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect({
          scopes,
          account: instance.getActiveAccount() as AccountInfo,
        });
      }

      throw err;
    }
  }, [instance, account, config.AzureAD.apiScopes]);

  const login = useCallback(() => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect({ scopes: config.AzureAD.apiScopes });
    }
  }, [instance, config, inProgress]);

  const logout = useCallback(() => {
    instance.logout();
  }, [instance]);

  return {
    logout,
    login,
    account,
    acquireToken,
  };
};

export default useAuth;
