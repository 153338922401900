import React from 'react';

import { Box, Typography } from 'components';
import { bigNumberToBritishFormat } from 'utilities/misc/NumberHelper';
import PropertyEmptyValue from './PropertyEmptyValue';

type PropertyNumberValueProps = {
  value: number | null;
  britishFormat?: boolean;
  hideZero?: boolean;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  decimalPlaces?: number;
  emptyValue?: string | null;
};

const formatNumber = (
  value: number | null,
  britishFormat: boolean,
  decimalPlaces: number | undefined,
  emptyValue: string | null | undefined,
) => {
  if (value == null) {
    return emptyValue || '';
  }

  if (britishFormat) {
    return bigNumberToBritishFormat(value.toString(), decimalPlaces);
  }

  if (decimalPlaces) {
    if (typeof value === 'number') {
      return value.toFixed(decimalPlaces);
    }

    return Number(value).toFixed(decimalPlaces);
  }

  return value;
};

const PropertyNumberValue: React.FC<PropertyNumberValueProps> = ({
  value,
  className,
  disabled = false,
  britishFormat = false,
  hideZero = false,
  decimalPlaces,
  emptyValue,
  dataTestId,
}) => {
  if (value === null || (hideZero && value === 0)) {
    return <PropertyEmptyValue dataTestId={dataTestId ? `${dataTestId}-number-value` : undefined} />;
  }

  return (
    <Box alignItems="center" display="flex">
      <Typography
        className={className}
        color="textPrimary"
        data-testid={dataTestId ? `${dataTestId}-number-value` : undefined}
        sx={{ color: theme => (disabled ? theme.palette.text.disabled : '') }}
        variant="caption"
      >
        {formatNumber(value, britishFormat, decimalPlaces, emptyValue)}
      </Typography>
    </Box>
  );
};

export default PropertyNumberValue;
