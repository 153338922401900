import { SnackbarProvider } from 'notistack';

import { styled, Box } from 'components';
import Breadcrumbs from './Breadcrumbs';

export const StyledRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const StyledContent = styled('main')(() => ({
  marginTop: '65px',
  height: 'calc(100vh - 65px)',
  overflow: 'auto',
  position: 'relative',
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: `${theme.spacing(3)} ${theme.spacing(8)}`,
  [theme.breakpoints.down('md')]: {
    margin: `${theme.spacing(2)} 0`,
  },
}));

export const StyledSnackbarProvider = styled(SnackbarProvider)(() => ({
  '& > *': {
    flexGrow: 1,
  },
}));
