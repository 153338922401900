import React, { useCallback } from 'react';
import { Box, ConfirmationDialog } from 'components';
import { useConfirm } from 'utilities/hooks';

const ConfirmationDialogAsync: React.FC = () => {
  const { isOpen, submit, cancel, message, title, cancelButtonName, confirmButtonName } = useConfirm();

  const handleClose = useCallback(
    (isConfirm: boolean, reason?: string) => {
      if (isConfirm) return submit(isConfirm);
      return cancel(reason);
    },
    [cancel, submit],
  );

  return isOpen ? (
    <ConfirmationDialog
      cancelButtonName={cancelButtonName}
      confirmButtonName={confirmButtonName}
      onClose={handleClose}
      open={isOpen}
      title={title}
    >
      <Box whiteSpace="break-spaces">{message}</Box>
    </ConfirmationDialog>
  ) : null;
};

export default ConfirmationDialogAsync;
