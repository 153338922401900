import { styled, Box, Collapse, Icon, Typography } from '@mui/material';

export const StyledRootBox = styled(Box, { shouldForwardProp: prop => prop !== 'withBorder' })<{
  withBorder?: boolean;
  disabled?: boolean;
}>(({ theme, withBorder, disabled }) => ({
  boxShadow: withBorder ? '1px 1px 4px rgba(0, 0, 0, 0.2)' : 'none',
  ...(disabled && {
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
    color: theme.palette.text.disabled,
    '& > div:not(first-of-type) > span > svg': {
      color: theme.palette.text.disabled,
    },
    '& > div:first-of-type': {
      pointerEvents: 'none',
    },
  }),
}));

export const StyledExpandIcon = styled(Icon, { shouldForwardProp: prop => prop !== 'expanded' })<{
  expanded?: boolean;
}>(({ theme, expanded }) => ({
  ...(expanded && {
    transform: 'rotate(180deg)',
  }),
  ...(!expanded && {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

export const StyledHeadingBox = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '1rem',
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: Number(theme.typography.fontWeightBold),
  letterSpacing: '0.08rem',
  textTransform: 'uppercase',
}));

export const StyledIconTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: Number(theme.typography.fontWeightBold),
  letterSpacing: '0.08rem',
  textTransform: 'uppercase',
  marginRight: '0.5rem',
}));

export const StyledCollapse = styled(Collapse, { shouldForwardProp: prop => prop !== 'expanded' })<{
  expanded?: boolean;
}>(({ expanded }) => ({
  padding: expanded ? '0 1rem 1rem' : '0 1rem',
}));
