export type AuthorisorTypeName = 'Screening' | 'ApproveBroker';
export type AuthorisorTypeValue = 'SS' | 'AO';

export type MenuOptionName =
  | 'SubmissionManagement'
  | 'SubmissionReadOnly'
  | 'BrokerManagement'
  | 'BrokerReadOnly'
  | 'BrokerGroupManagement'
  | 'BrokerGroupReadOnly'
  | 'StatementReadOnly'
  | 'MemberReports'
  | 'MemberGroupManagement'
  | 'MemberGroupReadOnly'
  | 'ObjectMaintenanceManagement'
  | 'ObjectMaintenanceReadOnly'
  | 'VesselSurveyManagement'
  | 'VesselSurveyReadOnly';

export type MenuOptionValue =
  | 'SUBMAN'
  | 'SUBRO'
  | 'BROKMAN'
  | 'BROKRO'
  | 'BROKGRPMAN'
  | 'BROKGRPRO'
  | 'MEMREPS'
  | 'STATRO'
  | 'MEMBGRPMAN'
  | 'MEMBGRPRO'
  | 'OMMAN'
  | 'OMRO'
  | 'VSURVEYMAN'
  | 'VSURVEYRO';

export type LoggedInUser = {
  userCode: number;
  userEmail: string;
  userInitials: string;
  userName: string;
  authorisorTypes: AuthorisorTypeValue[];
  menuOptions: MenuOptionValue[];
};

type MockedUserType = { userDetails: LoggedInUser; isDataFetched: boolean };

export const mockedUser: MockedUserType = {
  userDetails: {
    userCode: 1,
    userEmail: 'john.doe@simsl.com',
    userInitials: 'JS',
    userName: 'Doe, John',
    authorisorTypes: ['SS'],
    menuOptions: [
      'SUBMAN',
      'SUBRO',
      'BROKMAN',
      'BROKRO',
      'BROKGRPMAN',
      'BROKGRPRO',
      'STATRO',
      'MEMBGRPMAN',
      'MEMBGRPRO',
      'OMMAN',
      'OMRO',
      'VSURVEYMAN',
      'VSURVEYRO',
    ],
  },
  isDataFetched: true,
};

export const mockedBusinessEdit = {
  isEditModeActive: false,
  setEditMode: () => () => {},
};
