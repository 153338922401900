import { styled } from 'components';

export const StyledOverlayDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'overlay',
})<{ overlay?: boolean }>(({ theme, overlay }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.modal,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(overlay && {
    backgroundColor: 'rgba(0,0,0,0.35)',
  }),
  ...(!overlay && {
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  }),
}));

export const StyledSpinnerDiv = styled('div')(({ theme }) => ({
  animation: 'pulsate 1s infinite ease',
  position: 'relative',
  width: '80px',
  height: '80px',
  '& div': {
    '@keyframes spinnerAnimation': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
    boxSizing: 'border-box',
    position: 'absolute',
    width: '64px',
    height: '64px',
    border: '8px solid #fff',
    borderRadius: '50%',
    animation: 'spinnerAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
    borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
    '&:nth-of-type(1)': {
      animationDelay: '-0.45s',
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.3s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '-0.15s',
    },
  },
}));
