import { SimslRoute } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  statements: 'statements',
};

export default abstract class RoutePath {
  static Statements: SimslRoute = {
    name: 'Statements',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.statements,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
