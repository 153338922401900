import isNil from 'lodash.isnil';

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const textSpacesToDashFormat = (text: string) => text.replace(/\W+/g, '-').toLowerCase();

export const containsCaseInsensitive = (str: string, subString: string) => str?.toLowerCase().indexOf(subString?.toLowerCase()) !== -1;

export const textCapitalLetterToDashFormat = (text: string) =>
  text
    ?.split(/(?=[A-Z])/)
    .join('-')
    .replaceAll(' ', '')
    .toLowerCase();

export const getNullableLength = (text: string | undefined | null) => {
  if (isNil(text)) {
    return 0;
  }

  return text.length;
};

export const textWithoutCommas = (text: string) => text.replaceAll(',', '');

export const sliceEnd = (text: string, endText: string) => {
  if (text.endsWith(endText)) {
    return text.slice(0, -endText.length);
  }
  return text;
};

export const sliceStart = (text: string, startText: string) => {
  if (text.startsWith(startText)) {
    return text.slice(startText.length, text.length);
  }
  return text;
};
