import React, { Suspense, lazy, ComponentType } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ModuleRoutingInfo } from 'common';
import { UserProvider } from 'utilities/user';
import NotFound from 'NotFound';
import useModuleErrorInterceptor from 'useModuleErrorInterceptor.hook';
import { ModuleNames } from './ModuleNames';
import { RoutePath } from './RoutePath';
import { Spinner } from './components';
import Layout from './utilities/layout/Layout';
import BussinessesRoutes from './businesses/Routes';
import BrokersRoutes from './brokers/routes';
import TasksRoutes from './tasks/routes';
import BussinessContactsRoutes from './businessContacts/Routes';
import VesselSurveyRoutes from './surveys/Routes';
import ObjectsRoutes from './objectMaintenance/routes';
import MemberGroupsRoutes from './memberGroups/routes';
// import ClausesRoutes from './certificateClauses/routes';
import SubmissionsRoutes from './submissions/routes';
import StatementsRoutes from './statements/routes';
import HomePage from './home';
import useModuleInfo from './useModuleInfo.hook';

const BusinessesModule = lazy(
  () => import(/* webpackChunkName: "businesses.module" */ './businesses') as Promise<{ default: ComponentType<any> }>,
);

const BusinessContactsModule = lazy(
  () => import(/* webpackChunkName: "businessContacts.module" */ './businessContacts') as Promise<{ default: ComponentType<any> }>,
);

const SurveysModule = lazy(() => import(/* webpackChunkName: "surveys.module" */ './surveys') as Promise<{ default: ComponentType<any> }>);

const ObjectMaintenanceModule = lazy(
  () => import(/* webpackChunkName: "objectMaintenance.module" */ './objectMaintenance') as Promise<{ default: ComponentType<any> }>,
);

const MemberGroupsModule = lazy(
  () => import(/* webpackChunkName: "members.module" */ './memberGroups') as Promise<{ default: ComponentType<any> }>,
);

// const CertificateClausesModule = lazy(
//   () => import(/* webpackChunkName: "certificateClauses.module" */ './certificateClauses') as Promise<{ default: ComponentType<any> }>,
// );

const SubmissionsModule = lazy(
  () => import(/* webpackChunkName: "submissions.module" */ './submissions') as Promise<{ default: ComponentType<any> }>,
);

const BrokersModule = lazy(() => import(/* webpackChunkName: "brokers.module" */ './brokers') as Promise<{ default: ComponentType<any> }>);

const TasksModule = lazy(() => import(/* webpackChunkName: "tasks.module" */ './tasks') as Promise<{ default: ComponentType<any> }>);

const StatementsModule = lazy(
  () => import(/* webpackChunkName: "statements.module" */ './statements') as Promise<{ default: ComponentType<any> }>,
);

const BusinessesModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(`${RoutePath.BusinessesModule}/${BussinessesRoutes.ApiNotAvailable.path}`);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <BusinessesModule />
    </Suspense>
  );
};

const BusinessContactsModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(BussinessContactsRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <BusinessContactsModule />
    </Suspense>
  );
};

const SurveysModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(VesselSurveyRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <SurveysModule />
    </Suspense>
  );
};

const ObjectMaintenanceModuleInterceptor: React.FC = () => {
  const location = useLocation();
  useModuleErrorInterceptor(ObjectsRoutes.ApiNotAvailable.url(location?.pathname || ''));
  return (
    <Suspense fallback={<Spinner overlay />}>
      <ObjectMaintenanceModule />
    </Suspense>
  );
};

const MemberGroupsModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(MemberGroupsRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <MemberGroupsModule />
    </Suspense>
  );
};

// const CertificateClausesModuleInterceptor: React.FC = () => {
//   useModuleErrorInterceptor(ClausesRoutes.ApiNotAvailable.path);
//   return (
//     <Suspense fallback={<Spinner overlay />}>
//       <CertificateClausesModule />
//     </Suspense>
//   );
// };

const SubmissionsModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(SubmissionsRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <SubmissionsModule />
    </Suspense>
  );
};

const BrokersModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(BrokersRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <BrokersModule />
    </Suspense>
  );
};

const TasksModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(TasksRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <TasksModule />
    </Suspense>
  );
};

const StatementsModuleInterceptor: React.FC = () => {
  useModuleErrorInterceptor(StatementsRoutes.ApiNotAvailable.path);
  return (
    <Suspense fallback={<Spinner overlay />}>
      <StatementsModule />
    </Suspense>
  );
};

const modules: ModuleRoutingInfo[] = [
  {
    name: ModuleNames.BusinessesModule,
    mainPath: RoutePath.BusinessesModule,
    routes: [...Object.values(BussinessesRoutes)],
  },
  {
    name: ModuleNames.BusinessContactsModule,
    mainPath: RoutePath.BusinessContactsModule,
    routes: [...Object.values(BussinessContactsRoutes)],
  },
  {
    name: ModuleNames.SurveysModule,
    mainPath: RoutePath.SurveysModule,
    routes: [...Object.values(VesselSurveyRoutes)],
  },
  {
    name: ModuleNames.MemberGroupsModule,
    mainPath: RoutePath.MemberGroupsModule,
    routes: [...Object.values(MemberGroupsRoutes)],
  },
  {
    name: ModuleNames.ObjectsModule,
    mainPath: RoutePath.ObjectsModule,
    routes: [...Object.values(ObjectsRoutes)],
  },
  // {
  //   name: ModuleNames.CertificateClausesModule,
  //   mainPath: RoutePath.CertificateClausesModule,
  //   routes: [...Object.values(ClausesRoutes)],
  // },
  {
    name: ModuleNames.SubmissionsModule,
    mainPath: `${RoutePath.SubmissionsModule}`,
    routes: [...Object.values(SubmissionsRoutes)],
  },
  {
    name: ModuleNames.BrokersModule,
    mainPath: `${RoutePath.BrokersModule}`,
    routes: [...Object.values(BrokersRoutes)],
  },
  {
    name: ModuleNames.TasksModule,
    mainPath: `${RoutePath.TasksModule}`,
    routes: [...Object.values(TasksRoutes)],
  },
  {
    name: ModuleNames.StatementsModule,
    mainPath: `${RoutePath.StatementsModule}`,
    routes: [...Object.values(StatementsRoutes)],
  },
];

const AppProtectedRoutes: React.FC = () => {
  const location = useLocation();
  const { module } = useModuleInfo(modules, location.pathname);

  return (
    <Layout activeRoute={module?.activeRoute} mainPath={module?.mainPath || null} moduleName={module?.name || null}>
      <UserProvider>
        <Routes>
          <Route
            element={
              <Suspense fallback={<Spinner overlay />}>
                <HomePage />
              </Suspense>
            }
            path={RoutePath.Home}
          />
          <Route element={<BusinessesModuleInterceptor />} path={`${RoutePath.BusinessesModule}/*`} />
          <Route element={<BusinessContactsModuleInterceptor />} path={`${RoutePath.BusinessContactsModule}/*`} />
          <Route element={<SurveysModuleInterceptor />} path={`${RoutePath.SurveysModule}/*`} />
          <Route element={<ObjectMaintenanceModuleInterceptor />} path={`${RoutePath.ObjectsModule}/*`} />
          <Route element={<MemberGroupsModuleInterceptor />} path={`${RoutePath.MemberGroupsModule}/*`} />
          <Route element={<SubmissionsModuleInterceptor />} path={`${RoutePath.SubmissionsModule}/*`} />
          {/* <Route element={<CertificateClausesModuleInterceptor />} path={`${RoutePath.CertificateClausesModule}/*`} /> */}
          <Route element={<BrokersModuleInterceptor />} path={`${RoutePath.BrokersModule}/*`} />
          <Route element={<TasksModuleInterceptor />} path={`${RoutePath.TasksModule}/*`} />
          <Route element={<StatementsModuleInterceptor />} path={`${RoutePath.StatementsModule}/*`} />
          <Route element={<NotFound />} path="*" />
        </Routes>
      </UserProvider>
    </Layout>
  );
};

export default AppProtectedRoutes;
