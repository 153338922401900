import isNil from 'lodash.isnil';
import { textWithoutCommas } from './StringHelper';

export const number2BritishString = (number: number | null) => {
  if (isNil(number)) {
    return '';
  }

  return number.toLocaleString('en-GB'); // with separator every 3 digits
};

export const britishNumberFormatSeparator = ',';

export const bigNumberToBritishFormat = (number: string | null, decimalPlaces: number | undefined = undefined) => {
  if (number === null) {
    return '';
  }
  const numberParts = number.toString().split('.');

  if (numberParts[0].length < 14 && (!numberParts[1] || numberParts[1].length === 0)) {
    return Number(numberParts[0]).toLocaleString('en-GB', { minimumFractionDigits: decimalPlaces });
  }

  const reversedBritishNumber = numberParts[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    ?.join(britishNumberFormatSeparator);

  const britishNumber = reversedBritishNumber?.split('').reverse().join('');

  if (decimalPlaces && numberParts[1]?.length > 0) {
    const decimalPartRounded = Number(`0.${numberParts[1]}`).toFixed(decimalPlaces);
    return [britishNumber, decimalPartRounded.substring(2)].join('.');
  }

  if (decimalPlaces) {
    return [britishNumber, '0'.repeat(decimalPlaces)].join('.');
  }

  if (numberParts[1]?.length > 0) {
    return [britishNumber, numberParts[1]].join('.');
  }

  return britishNumber;
};

export const isValidPrecision = (number: number, precision: number): boolean => {
  return typeof number === 'number' && !Number.isNaN(number) && Number(number.toFixed(precision)) === number;
};

export const britishFormatToBigNumber = (string: string | null) => {
  return Number(textWithoutCommas(string ?? ''));
};
