import { styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'hideArrows',
})<{ hideArrows?: boolean }>(({ theme, hideArrows }) => ({
  '& input': {
    fontSize: theme.typography.caption.fontSize,
    ...(hideArrows && {
      appearance: 'textfield',
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    }),
  },
}));
