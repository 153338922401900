import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CardContent, Link, Typography } from '../components';
import { StyledCardActionArea, StyledCardMedia, StyledRootCard } from './WorkbenchModuleCard.styles';

export type WorkbenchModuleCardProps = {
  name: string;
  description: string;
  imagePath: string;
  link: string;
};

const WorkbenchModuleCard: React.FC<WorkbenchModuleCardProps> = ({ name, description, imagePath, link }) => {
  return (
    <StyledRootCard>
      <StyledCardActionArea>
        <Link component={RouterLink} to={link} underline="hover">
          <StyledCardMedia image={imagePath} title={name} />
          <CardContent>
            <Typography color="primary" component="h2" gutterBottom variant="h5">
              {name}
            </Typography>
            <Typography color="textSecondary" component="p" variant="body2">
              {description}
            </Typography>
          </CardContent>
        </Link>
      </StyledCardActionArea>
    </StyledRootCard>
  );
};

export default WorkbenchModuleCard;
