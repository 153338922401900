import React, { useEffect, useState } from 'react';
import { Box } from 'components';

interface TabPanelProps {
  className?: string;
  activeTabIndex: number;
  index: number;
  children?: React.ReactNode;
}

const TabPanel: React.FC<TabPanelProps> = ({ className, activeTabIndex, index, children }) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (activeTabIndex === index) {
      setVisited(true);
    }
  }, [index, activeTabIndex]);

  return (
    <Box className={className} hidden={index !== activeTabIndex}>
      {visited && children}
    </Box>
  );
};

export default TabPanel;
