/* eslint-disable scanjs-rules/identifier_sessionStorage */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsalAuthentication } from '@azure/msal-react';

import { InteractionType } from '@azure/msal-browser';
import { Box, Spinner, Typography, Container } from '../../components';
import { useAuth } from './useAuth';
import { BACK_REDIRECT_KEY } from './AuthProvider';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { account } = useAuth();
  const { error: authError } = useMsalAuthentication(InteractionType.Silent);

  useEffect(() => {
    if (account) {
      const redirectUrl = sessionStorage.getItem(BACK_REDIRECT_KEY);

      if (redirectUrl) {
        sessionStorage.removeItem(BACK_REDIRECT_KEY);
      }

      navigate({ pathname: redirectUrl || '/' });
    }
  }, [account, navigate]);

  return (
    <Container maxWidth="lg">
      <Box>
        {!account && !authError && <Spinner />}
        {account && (
          <Box style={{ marginTop: 55 }}>
            <Typography align="center" variant="h5">
              Welcome, {account?.name}. You will be redirected in few seconds.
            </Typography>
            <Typography align="center" variant="body2">
              If you are not redirected <a href="/">click here to go to home page</a>
            </Typography>
          </Box>
        )}
        {authError && (
          <Box style={{ marginTop: 55 }}>
            <Typography align="center" variant="body2">
              An error occurred during login process. Please close all your browser windows and try again. If this keeps happening contact
              support team.
            </Typography>
            <Typography align="center" variant="caption">
              {authError.message}
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Login;
