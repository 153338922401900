import { styled, Grid } from 'components';

export const StyledGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== 'withBorder',
})<{ withBorder: boolean }>(({ withBorder }) => ({
  ...(withBorder && {
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.2)',
    padding: '1rem',
  }),
}));
