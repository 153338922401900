import React from 'react';

import { Typography, Link, Container } from 'components';

const Footer: React.FC = () => (
  <Container maxWidth={false} sx={{ position: 'relative', bottom: 0, marginBottom: 2.5, marginTop: 0.5 }}>
    <Typography align="center" color="textSecondary" variant="body2">
      {'Copyright © '}
      <Link color="inherit" href="https://www.steamshipmutual.com/" underline="hover">
        Steamship Mutual
      </Link>{' '}
      {`${new Date().getFullYear()}`}
    </Typography>
  </Container>
);

export default Footer;
