import axios, { AxiosResponse, CancelTokenSource } from 'axios';

const FileService = {
  downloadPdf: (fileName: string): [Promise<AxiosResponse>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const url = `/v2/sharepoint-online/files/${fileName}`;
    const getRequest = axios
      .get(url, {
        responseType: 'blob',
        cancelToken: cancel.token,
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then(response => response);

    return [getRequest, cancel];
  },
  generateSubmissionDocumentPdf: (submissionId: number, quoteVersionId: number): [Promise<AxiosResponse>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const url = `/v2/submission-documents?submissionId=${submissionId}&quoteVersionId=${quoteVersionId}`;
    const getRequest = axios
      .get(url, {
        responseType: 'blob',
        cancelToken: cancel.token,
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then(response => response);

    return [getRequest, cancel];
  },
  generateSubmissionYachtDocumentPdf: (
    submissionId: number,
    quoteVersionId: number,
    quoteObjectId: number,
  ): [Promise<AxiosResponse>, CancelTokenSource] => {
    const cancel = axios.CancelToken.source();
    const url = `/v2/submission-documents?submissionId=${submissionId}&quoteVersionId=${quoteVersionId}&quoteObjectId=${quoteObjectId}`;
    const getRequest = axios
      .get(url, {
        responseType: 'blob',
        cancelToken: cancel.token,
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then(response => response);

    return [getRequest, cancel];
  },
};

export default FileService;
