import { styled, Box } from 'components';
import TableRow from 'components/Table/TableRow';

export const StyledRootBox = styled(Box)(() => ({
  position: 'relative',
}));

export const StyledTableContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'minContainerHeight' && prop !== 'maxContainerHeight' && prop !== 'fontSize',
})<{ minContainerHeight?: string | number; maxContainerHeight?: string | number; fontSize?: string }>(
  ({ theme, minContainerHeight, maxContainerHeight, fontSize }) => ({
    minHeight: minContainerHeight,
    maxHeight: maxContainerHeight,
    overflowY: 'auto',
    '& td, & th': {
      [theme.breakpoints.up('sm')]: {
        fontSize,
      },
    },
  }),
);

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'useRowsSecondaryColor' && prop !== 'clicableRow',
})<{ useRowsSecondaryColor?: boolean; clicableRow?: boolean }>(({ theme, useRowsSecondaryColor, clicableRow, selected }) => ({
  ...(useRowsSecondaryColor && {
    '& > td': {
      color: theme.palette.text.secondary,
    },
  }),
  ...(clicableRow && {
    cursor: 'pointer',
  }),
  ...(selected && {
    '&.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
      backgroundColor: '#E0E0E0',
    },
  }),
}));
