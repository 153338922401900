import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ArrowIcon } from 'icons';
import { TaskItem } from 'tasks/model';
import { StyledCloseButton, StyledTaskContainer, StyledTaskSidePanel, StyledTitle } from './TaskAlert.styles';
import { TaskCardSlim } from './components';

type TaskAlertProps = {
  sideBarOpen: boolean;
  handleClose: () => void;
  handleOpenTaskModal: (task: TaskItem) => void;
  tasks: TaskItem[] | undefined;
};

const TaskAlert: React.FC<TaskAlertProps> = ({ sideBarOpen, handleClose, handleOpenTaskModal, tasks }) => {
  return (
    <StyledTaskSidePanel
      anchor="right"
      closeAfterTransition
      disableEnforceFocus
      ModalProps={{
        BackdropProps: { invisible: true },
      }}
      open={sideBarOpen}
      transitionDuration={500}
      variant="temporary"
    >
      <StyledCloseButton
        color="textSecondary"
        component="button"
        data-testid="close-button"
        onClick={handleClose}
        type="button"
        underline="hover"
      >
        Close <ArrowIcon fontSize="small" />
      </StyledCloseButton>
      <StyledTitle color="primary" variant="h6">
        New Task Assigned
      </StyledTitle>
      <StyledTaskContainer>
        {tasks?.map(task => (
          <TaskCardSlim key={uuidv4()} handleClose={handleClose} handleOpenTaskModal={handleOpenTaskModal} task={task} />
        ))}
      </StyledTaskContainer>
    </StyledTaskSidePanel>
  );
};

export default TaskAlert;
