import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledDatePicker = styled(DatePicker<any>, {
  shouldForwardProp: prop => prop !== 'isErrorActive' && prop !== 'textFieldBaseMinWidth',
})<{
  isErrorActive?: boolean;
  textFieldBaseMinWidth: string;
}>(({ theme, isErrorActive, textFieldBaseMinWidth }) => ({
  fontSize: theme.typography.caption.fontSize,
  borderBottom: `1px solid ${isErrorActive ? 'transparent' : theme.palette.divider}`,
  minWidth: '100px',
  [theme.breakpoints.up('xl')]: {
    minWidth: textFieldBaseMinWidth,
  },
  width: '100%',
  '& div': {
    margin: 0,
  },
  '& div::before': {
    borderWidth: '0px',
  },
  '& input': {
    fontSize: theme.typography.caption.fontSize,
  },
  '& button': {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    '& > svg': {
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
}));
