import Masonry from 'react-masonry-css';
import { styled } from 'components/Theme';

export const StyledMasonry = styled(Masonry)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(-4),
  width: 'inherit',
  '& > *': {
    paddingLeft: theme.spacing(4),
    backgroundClip: 'padding-box',
  },
}));
