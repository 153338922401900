/* eslint-disable scanjs-rules/identifier_sessionStorage */
import { useState, useCallback } from 'react';

import { Sorting, SortDirection, Column } from 'common/model';
import { getDefaultSorting } from 'utilities/misc/TableHelper';

const initializeSorting = (defaultColumns: Column[], storageKey: string, storeSorting: boolean = true) => {
  const storedSorting = storeSorting ? JSON.parse(sessionStorage.getItem(storageKey)!) : null;
  const sorting = storedSorting || getDefaultSorting(defaultColumns);

  return sorting;
};

const useSorting = (defaultColumns: Column[], gridName: string, storeSorting: boolean = true) => {
  const storageKey = `${gridName}Sorting`;
  const [sorting, setSorting] = useState<Sorting>(() => initializeSorting(defaultColumns, storageKey, storeSorting));

  const changeSort = useCallback(
    (updatedSorting: Sorting | null) => {
      const sort =
        updatedSorting === null || updatedSorting.sortDirection === SortDirection.UNSPECIFIED
          ? getDefaultSorting(defaultColumns)
          : updatedSorting;

      if (storeSorting) {
        sessionStorage.setItem(storageKey, JSON.stringify(sort));
      }

      setSorting(sort);
    },
    [defaultColumns, storageKey, storeSorting],
  );

  return {
    sorting,
    changeSort,
  };
};

export default useSorting;
