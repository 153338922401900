import { styled, AppBar, Tab, Tabs } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'uppercase',
  minWidth: '60px',
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'uppercase',
  minWidth: '60px',
  '& .Mui-selected': {
    fontWeight: Number(theme.typography.fontWeightBold),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.primary.main,
  },
}));
