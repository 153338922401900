import { useCallback } from 'react';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useComponentWillMount } from 'utilities/hooks';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from './useAuth';

// TODO when adding next interceptor: move this file from 'auth' as it is responsible not only for authorisation. Exclude interceptors to separate files. Rename this file.

export function useAxiosInterceptors() {
  const { acquireToken } = useAuth();

  const bearerTokenInterceptor = useCallback(
    async (reqConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
      const newConfig = reqConfig;
      const authToken = await acquireToken();

      if (authToken) {
        newConfig.headers.Authorization = `Bearer ${authToken}`;
      }

      return newConfig;
    },
    [acquireToken],
  );

  const xRequestIdInterceptor = useCallback(async (reqConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const newConfig = reqConfig;

    newConfig.headers.set('X-Request-ID', crypto.randomUUID());

    return newConfig;
  }, []);

  const doesResponseLookLikeWafForbiddenPage = (bodyContent: string) => {
    const waf403HtmlHead = '<head><title>403 Forbidden</title></head>';
    const waf403HtmlFooter = '<center>Microsoft-Azure-Application-Gateway/v2</center>';
    return bodyContent?.includes(waf403HtmlHead) && bodyContent?.includes(waf403HtmlFooter);
  };

  const waf403Interceptor = useCallback(async (error: AxiosError): Promise<AxiosError> => {
    const response = error?.response;
    if (!axios.isCancel(error) && response?.status === 403) {
      const isWaf403Error = typeof response.data === 'string' && doesResponseLookLikeWafForbiddenPage(response.data);
      if (isWaf403Error) {
        enqueueSnackbar(
          'The data you are trying to set has been recognised by cloud security mechanisms as suspicious or potentially dangerous.\n' +
            '\n' +
            'Please review the data, remove special characters, or their combinations of, and try again.',
          { variant: 'waf403Error', persist: true, hideIconVariant: true, title: 'Data Error Occurred' },
        );
      }
    }

    return Promise.reject(error);
  }, []);

  useComponentWillMount(() => {
    axios.interceptors.request.use(bearerTokenInterceptor);
    axios.interceptors.request.use(xRequestIdInterceptor);
    axios.interceptors.response.use(undefined, waf403Interceptor);
  });
}

export default useAxiosInterceptors;
