import React, { useCallback } from 'react';

import { SelectProps, MenuItem, CircularProgress, FormHelperText } from '@mui/material';
import { SelectOption } from './model';
import { StyledFormControl, StyledInputLabel, StyledListItemIcon, StyledMenuItem, StyledSelect } from './SimslSelect.styles';

export type SimslSelectProps<T> = {
  options: T[];
  isLoading?: boolean;
  helperText?: string;
  placeholder?: string;
  blankOptionLabel?: string;
  customMenuItemsHeight?: string;
  withBlankOption?: boolean;
  unavailableOption?: SelectOption | null;
  dataTestId?: string;
} & Omit<SelectProps, 'variant'>;

type SimslSelectI<T = SelectOption> = React.FC<SimslSelectProps<T>>;

const SimslSelect: SimslSelectI = ({
  options,
  isLoading = false,
  helperText,
  placeholder,
  value,
  blankOptionLabel = '',
  customMenuItemsHeight = '',
  withBlankOption = false,
  unavailableOption,
  dataTestId,
  disabled = false,
  ...rest
}) => {
  const isPlaceholderVisible = !!value || value === 0;

  const isOptionDisabled = useCallback(
    (option: SelectOption | null | undefined) => (option ? option?.id === unavailableOption?.id : false),
    [unavailableOption],
  );

  return (
    <StyledFormControl variant="standard">
      {isLoading && (
        <StyledInputLabel aria-label="loading">
          <CircularProgress color="inherit" size={20} />
        </StyledInputLabel>
      )}
      <StyledSelect
        {...rest}
        className={rest.className}
        customMenuItemsHeight={customMenuItemsHeight}
        disabled={isLoading || disabled}
        displayEmpty
        inputProps={{
          ...rest.inputProps,
          'data-testid': dataTestId ? `${dataTestId}-select` : undefined,
        }}
        isPlaceholderVisible={isPlaceholderVisible}
        isUnavailableOption={unavailableOption?.id === value}
        MenuProps={{
          ...rest.MenuProps,
          PaperProps: {
            ...rest.MenuProps?.PaperProps,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        renderValue={isPlaceholderVisible ? undefined : () => placeholder}
        SelectDisplayProps={{
          ...rest.SelectDisplayProps,
          // @ts-ignore
          'data-testid': `${dataTestId}-select-menu`,
        }}
        value={value}
        variant="standard"
      >
        {options.length > 0 && withBlankOption && <StyledMenuItem value="">{blankOptionLabel}</StyledMenuItem>}
        {options.length === 0 && <MenuItem disabled>No options</MenuItem>}
        {options.map((option, index) => (
          <StyledMenuItem
            key={option.id}
            data-testid={dataTestId ? `${dataTestId}-menu-item-${index}` : undefined}
            disabled={isOptionDisabled(option)}
            value={option.id}
          >
            {option.name}
            {option.icon && (
              <StyledListItemIcon data-testid={dataTestId ? `${dataTestId}-menu-item-icon-${index}` : undefined}>
                {option.icon}
              </StyledListItemIcon>
            )}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {helperText && (
        <FormHelperText data-testid={dataTestId ? `${dataTestId}-helper-text` : undefined} error>
          {helperText}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default SimslSelect;
