import axios, { Canceler } from 'axios';

import { Country } from '../model';

const CountriesService = {
  getCountries: (): [Promise<Country[]>, Canceler] => {
    const cancel = axios.CancelToken.source();

    const getRequest = axios
      .get<Country[]>('/v2/countries', {
        cancelToken: cancel.token,
      })
      .then(response => response.data);

    return [getRequest, cancel.cancel];
  },
};

export default CountriesService;
