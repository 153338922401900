import { SimslRoute, SimslRouteWithUrl } from 'common/model';
import { RoutePath as ModulesRoutes } from 'RoutePath';

const helpSectionIdentifiers = {
  brokers: 'brokers',
  brokerDetails: 'brokerDetails',
  newBroker: 'newBroker',
  editBroker: 'editBroker',
  brokerGroupMaintenance: 'brokerGroupMaintenance',
};

export default abstract class Routes {
  static Brokers: SimslRoute = {
    name: 'Brokers',
    path: ModulesRoutes.MainPath,
    helpSection: helpSectionIdentifiers.brokers,
  };

  static NewBroker: SimslRoute = {
    name: 'New',
    path: `new`,
    helpSection: helpSectionIdentifiers.newBroker,
  };

  static EditBroker: SimslRouteWithUrl = {
    name: 'Edit',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.BrokersModule}/${id}`,
    helpSection: helpSectionIdentifiers.editBroker,
  };

  static BrokerDetails: SimslRouteWithUrl = {
    name: 'Details',
    path: `:id`,
    url: (id: string | number) => `${ModulesRoutes.BrokersModule}/${id}`,
    helpSection: helpSectionIdentifiers.brokerDetails,
  };

  static BrokerGroups: SimslRoute = {
    name: 'BrokerGroups',
    path: `brokerGroups`,
    helpSection: helpSectionIdentifiers.brokerGroupMaintenance,
  };

  static ApiNotAvailable: SimslRoute = {
    name: '',
    path: `error`,
  };
}
