import React from 'react';
import { SxProps, Theme } from '@mui/material';

import { InfoOutlined } from 'icons';
import { Box, Link, Tooltip } from 'components';
import PropertyDeletedValue from './PropertyDeletedValue';
import PropertyEmptyValue from './PropertyEmptyValue';
import { StyledPropertyValueTypography } from './PropertyValue.styles';

type PropertyValueProps = {
  value: React.ReactNode;
  valueLink?: string;
  tooltipInfo?: string;
  disabled?: boolean | null;
  openLinkInNewTab?: boolean;
  dataTestId?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  tooltip?: string | null;
};

const PropertyValue: React.FC<PropertyValueProps> = ({
  value,
  valueLink,
  tooltipInfo,
  disabled = false,
  openLinkInNewTab = false,
  dataTestId,
  sx,
  onClick,
  tooltip = null,
}) => {
  const renderPropertyValue = () => {
    return (
      <Box>
        {valueLink || onClick ? (
          <Link
            href={valueLink}
            onClick={onClick}
            rel="noopener"
            sx={{
              ...(onClick && { cursor: 'pointer' }),
            }}
            target={openLinkInNewTab ? '_blank' : '_self'}
            underline="hover"
          >
            {value}
          </Link>
        ) : (
          value
        )}
      </Box>
    );
  };

  if (value === null) {
    return <PropertyEmptyValue dataTestId={dataTestId ? `${dataTestId}-value` : undefined} tooltipInfo={tooltipInfo} />;
  }

  if (value && disabled === null) {
    return <PropertyDeletedValue dataTestId={dataTestId ? `${dataTestId}-value` : undefined} tooltipInfo={value.toString()} />;
  }

  return (
    <Box alignItems="center" display="flex">
      <StyledPropertyValueTypography
        color="textPrimary"
        data-testid={dataTestId ? `${dataTestId}-value` : undefined}
        disabled={disabled}
        sx={sx}
        variant="caption"
      >
        {tooltip ? <Tooltip title={tooltip}>{renderPropertyValue()}</Tooltip> : <Box>{renderPropertyValue()}</Box>}
      </StyledPropertyValueTypography>
      {tooltipInfo && (
        <Box display="flex" marginLeft={1}>
          <Tooltip title={tooltipInfo}>
            <InfoOutlined fontSize="small" />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default PropertyValue;
